import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavLightComponent } from '../../components/nav-light/nav-light.component';
import { KeyFeaturesComponent } from '../../components/key-features/key-features.component';
import { FooterOneComponent } from '../../components/footer/footer-one/footer-one.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { StateService } from '../../services/state.service';
import { RecaptchaService } from '../../services/recaptcha.service';
import { LandingService } from '../../services/landing.service';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
  selector: 'cricteams-app-landing',
  standalone: true,
  imports: [
    CommonModule,
    NavLightComponent,
    KeyFeaturesComponent,
    FooterOneComponent,
    ReactiveFormsModule,
    LoadingSpinnerComponent
],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  isActive = false;
  canShare = false;
  myForm: FormGroup;
  isLoading = false;
  formData = {
    name: '',
    email: '',
    state: '',
    playerType: '',
    feature: '',
    league: '',
    customMessage: '',
    type: 'landingPage'
  };
  states: string[] = [];
  slider: any;
  displayShareNotSupported = false;
  errorMessage = '';
  constructor(
    private landingService: LandingService,
    private recaptchaService: RecaptchaService,
    private stateService: StateService,
    private fb: FormBuilder
  ) {
    this.myForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      state: ['Select One', Validators.required],
      playerType: ['Select One', Validators.required],
      feature: ['Select One', Validators.required],
      league: [''],
      customMessage: [''],
      type:['landingPage']
    });
    this.canShare = !!navigator.share;
  }

  ngOnInit(): void {
    this.states = this.stateService.getStates();
  }

  onSubmit() {
    if (this.myForm.valid) {
      this.isLoading = true;
      this.formData = this.myForm.value;
      this.errorMessage = '';
      this.recaptchaService
        .executeRecaptcha('submit')
        .then(async (token: string) => {
          console.log('reCAPTCHA token:', token);
          const isValid = await this.verifyToken(token);
          if (isValid) {
           await this.submitContactForm();
           this.isLoading = false;
          } else {
            console.error('reCAPTCHA error : invalid captcha token');
          }

          // Send the token to your backend for verification
        })
        .catch((error) => {
          console.error('reCAPTCHA error:', error);
          this.isLoading = false;
        });
    } else {
      this.errorMessage = 'Name, Email and State are required fields';
      console.log('Invalid Form');
      this.isLoading = false;
    }
  }

  closeModal(): void {
    this.isActive = false;
    // Refresh the page
    window.location.reload();
  }

  share(): void {
    if (this.canShare) {
      this.displayShareNotSupported = false;
      navigator
        .share({
          title: 'Check out this!',
          text: 'Here is all-in-one cricket team management platform i found.',
          url: 'https://qa.crickteams.com',
        })
        .then(() => {
          console.log('Content shared successfully!');
        })
        .catch((error) => {
          console.error('Error sharing content:', error);
        });
    } else {
      this.displayShareNotSupported = true;
      console.error('share not supported');
    }
  }
  async verifyToken(token: string): Promise<boolean> {
    try {
      const response = await this.recaptchaService.verifyCaptcha(token);
      if (response) {
        this.errorMessage = '';
        console.log('reCAPTCHA validation successful');
        // Proceed with your logic
      } else {
        this.errorMessage = 'reCAPTCHA validation failed';
        console.log('reCAPTCHA validation failed');
        // Handle validation failure
      }
      return response;
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
      // Handle HTTP error
      return false;
    }
  }

  async submitContactForm() {
    const submitResponse = await this.landingService.submitContactForm(this.formData)
    if (submitResponse.success) {
      this.isActive = true;
      console.log('Form submitted successfully:', submitResponse);
    } else {
      this.errorMessage = 'Error submitting form, please try again';
    }
  }
}
