import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  private restInstance;
  private skipInterceptor = false;

  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    private router: Router
  ) {
    this.restInstance = axios.create({
      baseURL: environment.baseUrl,
    });
    this.initializeInterceptor();
  }

  private initializeInterceptor() {
    this.restInstance.interceptors.request.use(
      (config) => {
        if (!this.skipInterceptor) {
          this.loadingService.showSection();
        }
        return config;
      },
      (error) => {
        this.loadingService.hideSection();
        return Promise.reject(error);
      }
    );

    this.restInstance.interceptors.response.use(
      (response) => {
        if (!this.skipInterceptor) {
          this.loadingService.hideSection();
        }
        return response;
      },
      (error) => {
        this.loadingService.hideSection();
        if (error.response && error.response.status === 401) {
          this.authService.logout();
          localStorage.removeItem('access_token');
          localStorage.clear();
          this.router.navigateByUrl('home');
        }
        return Promise.reject(error);
      }
    );
  }

  public skipRequestInterceptor(shouldSkip: boolean) {
    this.skipInterceptor = shouldSkip;
  }

  public getRestInstance() {
    return this.restInstance;
  }
}
