import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cricteams-dy-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dy-button.component.html',
  styleUrl: './dy-button.component.scss',
})
export class DyButtonComponent {
  @Input() type = 'button';
  @Input() class = '';
  @Input() disabled = false;
  @Input() text = '';
  @Input() conditionalClass!: Record<string, boolean>;
}
