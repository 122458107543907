import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'cricteams-top-header',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
})
export class TopHeaderComponent {
  constructor(private authService: AuthService) {}
  @Output() toggleClass = new EventEmitter<void>();

  emitToggleClassEvent() {
    this.toggleClass.emit();
  }

  isNotification = true;

  notificationManu() {
    this.isNotification = !this.isNotification;
  }

  isUser = true;
  userDropdown() {
    this.isUser = !this.isUser;
  }

  logout() {
    this.authService.logout();
    localStorage.removeItem('access_token');
    window.location.href = '/';
  }
}
