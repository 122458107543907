export const routeUrls = {
  ROOT: '/',
  HOME: 'home',
  HANDLE_INVITE: 'handle-invite',
  AUTH_CALLBACK: 'auth-callback',
  TERMS: 'terms',
  PRIVACY_POLICY: 'privacy-policy',
  COOKIE_POLICY: 'cookie-policy',
  CONFIRMATION: 'confirmation',
  INVALID_LINK: 'invalid-link',
  DASHBOARD: 'dashboard',
  TOURNAMENTS: 'tournaments',
  ADD_TOURNAMENTS: 'tournaments/add',
  LIST_TOURNAMENTS: 'tournaments/list',
  LEAGUES: 'leagues',
  GROUNDS: 'grounds',
  PRACTICES: 'practices',
  ADD_PRACTICES: 'practices/add',
  LIST_PRACTICES: 'practices/list',
  MATCHES: 'matches',
  ADD_MATCHES: 'matches/add',
  LIST_MATCHES: 'matches/list',
  PLAYERS: 'players',
  ADD_PLAYERS: 'players/add',
  LIST_PlAYERS: 'players/list',
  NOTICEBOARD: 'noticeboard',
  NB_INDEX: 'nb-index',
  GALLERY: 'gallery',
  SETTINGS: 'settings',
  USER: 'user',
  PROFILE: 'profile',
};
