<footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">    
    <div class="py-[30px] px-0 border-t border-slate-800">
        <div class="container relative text-center logo-text-container">
            <a class="logo" routerLink="/">
                <img src="assets/images/logo-dark.png" class="inline-block" alt="">
            </a>
            <p>© {{date}} CrickTeams. Design & Develop by <a href="https://nextgnsolutions.com" class="text-reset" target="_blank" style="text-decoration: underline;">NextGen Solutions & Services</a>.</p>
        </div><!--end container-->
    </div>
</footer>
