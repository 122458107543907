<div class="relative z-[9999] font-montserrat" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>

  <div class="p-4 fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center">
    <div class="bg-white p-4 xl:p-5 rounded w-full font-montserrat max-w-[800px] h-fit">
      <h4 class="font-semibold text-black text-sm md:text-base">
        {{ emergencyDetails ? 'Edit' : 'Add' }} Emergency Contact
      </h4>
      <div class="mt-4 xl:mt-6 emergency-contact-form">
        <form (ngSubmit)="onSubmit()" [formGroup]="dyForm" #form="ngForm">
          <div class="grid lg:grid-cols-2 lg:gap-5">
            <div class="md:col-span-1 mb-5 lg:mb-6 relative">
              <cricteams-dy-input [labelClass]="labelClass" [inputClass]="inputClass" [form]="form" [field]="name"
                [group]="dyForm"></cricteams-dy-input>
            </div>
            <div class="md:col-span-1 mb-5 lg:mb-6 relative">
              <cricteams-dy-input [labelClass]="labelClass" [inputClass]="inputClass" [form]="form"
                [field]="phoneNumber" [group]="dyForm"></cricteams-dy-input>
            </div>
          </div>

          <div class="grid lg:grid-cols-2 lg:gap-5 items-end">
            <div class="md:col-span-1 mb-5 lg:mb-6 relative">
              <cricteams-dy-input [labelClass]="labelClass" [inputClass]="inputClass" [form]="form" [field]="email"
                [group]="dyForm"></cricteams-dy-input>
            </div>
            <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            </div>
          </div>

          <div class="text-right flex justify-end gap-2">
            <cricteams-dy-button text="Cancel" [class]="cancelBtnClass" (click)="handleClose()"></cricteams-dy-button>
            <cricteams-dy-button type="submit" [text]="emergencyDetails ? 'Update' : 'Save'"
              [disabled]="dyForm.invalid" [class]="saveBtnClass" [conditionalClass]="{
            'bg-light-blue': dyForm.invalid,
            'bg-dark-blue cursor-pointer': dyForm.valid
              }"></cricteams-dy-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
