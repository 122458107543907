import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as feather from 'feather-icons';
import { ClubRegistrationComponent } from "../../pages/onboarding/club-registration/club-registration.component";
import { environment } from 'apps/cricteams/src/environments/environment';

@Component({
  selector: 'cricteams-app-nav-light',
  standalone: true,
  imports: [CommonModule, RouterLink, ClubRegistrationComponent],
  templateUrl: './nav-light.component.html',
  styleUrls: ['./nav-light.component.scss']
})

export class NavLightComponent implements
  OnInit, AfterViewInit {
    constructor(private router : Router, public auth:AuthService) { }
    activeManu = '';
    isProduction = false;
    @ViewChild('modal') modal: ElementRef | undefined;
    public showRegistrationPopup: boolean = false;

    ngOnInit(): void {
        this.activeManu = this.router.url;
        this.isProduction = environment.production;
        console.log(this.router.url);
        window.scrollTo(0, 0);
    }
    ngAfterViewInit() {
      feather.replace();
    }


    @HostListener('window:click', ['$event'])
    onClick(event: MouseEvent): void {
      const modalElement = event.target as HTMLElement;
      modalElement.classList;
      if ( this.modal && modalElement.classList.contains('layout-overlay')) {
        this.closeModal();
      }
    }
 
    openModal(event: MouseEvent): void {
      event.stopPropagation(); 
      this.showRegistrationPopup = true; 
    }
  
    closeModal(): void {
      this.showRegistrationPopup = false; 
    }
  
    status = false;
    toggleMenu(){
        this.status = !this.status;       
    }
    manu = true;


    manuOpen=''
    subManu(item: string){
      this.manu = !this.manu;  
      this.manuOpen = item
    }
  
    windowScroll() {
      const navbar = document.getElementById('topnav') as HTMLInputElement;
      if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
        navbar.classList.add('nav-sticky');
      } else {
        navbar.classList.remove('nav-sticky');
      }
  
      const mybutton = document.getElementById("back-to-top");
      if (mybutton != null) {
        if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
          mybutton.classList.add("block");
          mybutton.classList.remove("hidden");
        } else {
          mybutton.classList.add("hidden");
          mybutton.classList.remove("block");
        }
      }
  
    }

    scrollToLandingSectionId(sectionId: string): void {

      this.status = false
      // If you are already on the landing page
      if (this.router.url === '/') {
        const contactSection = document.getElementById(sectionId);
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // Navigate to the landing page first and then scroll
        this.router.navigate(['/']).then(() => {
          const contactSection = document.getElementById(sectionId);
          if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    }

    openAuth0SignUp(): void {
      this.auth.loginWithRedirect({
        appState: { target: '/auth-callback'},
      });
    }

}
