import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AuthService } from '@auth0/auth0-angular';
import { ApiResponse } from '../models/api-response';
import { firstValueFrom } from 'rxjs';
import { Ground } from '../models/ground/ground.model';

@Injectable({
  providedIn: 'root',
})
export class GroundService {
  constructor(private restService: RestService, private auth: AuthService) {}

  public async getGrounds(): Promise<ApiResponse<Ground[]> | unknown> {
    try {
      const apiUrl = '/grounds';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Ground[]>>(
        `${apiUrl}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
}
