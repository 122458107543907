import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ApiResponse } from '../models/api-response';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { AddPlayerRequest } from '../models/player/add-player-request.model';
import { ClubPlayer } from '../models/club/club-player.model';
import { Club, RemoveMember } from '../models/club/club-info.model';
import { ClubFee } from '../models/club/club-fees';
import { PlayerInfo } from '../models/player/player-request.model';
import { Dashboard } from '../models/dashboard/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class ClubService {
  playerList: BehaviorSubject<ClubPlayer[]> = new BehaviorSubject<ClubPlayer[]>([]);
  feesList: BehaviorSubject<ClubFee[]> = new BehaviorSubject<ClubFee[]>([]);
  public isClubUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isClubSwitch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private restService: RestService, private auth: AuthService) {}

  public async completeClubRegistrationAsync(
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    invitationId: string
  ): Promise<boolean> {
    try {
      const apiUrl = '/clubs/complete-registration';

      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.post<ApiResponse<boolean>>(apiUrl, {
        email,
        firstName,
        lastName,
        phoneNumber,
        invitationId,
      });
      console.log(response.data);
      return response.data.isSuccess ? response.data.data ?? false : false;
    } catch (error) {
      console.error('Error submitting contact form:', error);
      return false;
    }
  }

  public async invitePlayerToClub(data: AddPlayerRequest): Promise<ApiResponse<unknown> | null> {
    try {
      const apiUrl = '/clubs/members';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.post<ApiResponse<unknown>>(
        apiUrl,
        data
      );
      return response.data;
    } catch (error) {
      console.error('Error inviting player to club:', error);
      return null;
    }
  }

  private refreshPlayers = new Subject<void>();

  refreshPlayers$ = this.refreshPlayers.asObservable();

  public refreshPlayerList() {
    this.refreshPlayers.next();
  }


  public async getClubPlayers(clubId: string, includeMe?: boolean): Promise<Array<ClubPlayer> | null> {
    try {
      let apiUrl = `/clubs/members/${clubId}`;
      if (includeMe) {
        apiUrl += '?includeMe=true';
      }
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Array<ClubPlayer>>>(apiUrl);
      console.log(response.data);
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      console.error('Error fetching club players:', error);
      return null;
    }
  }

    public async removePlayerFromClub(payload: RemoveMember):Promise<unknown>{
      try {
        const apiUrl  = `/clubs/removeMember`;
        const restInstance = this.restService.getRestInstance();
        const token = await firstValueFrom(
          this.auth.getAccessTokenSilently()
        );
        restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await restInstance.delete<ApiResponse<unknown>>(apiUrl, {
          data: payload
        });
        return response.data

      } catch (error) {
        return error;
      }
  }

  public async getClubs(): Promise<Array<Club> | null> {
    try {
      const apiUrl = '/clubs';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Array<Club>>>(apiUrl);
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      console.error('Error fetching clubs:', error);
      return null;
    }
  }

  public async updateClubBasicDetails(clubId: string,data: FormData): Promise<ApiResponse<string> | null> {
    try {
      const apiUrl = `/clubs/${clubId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<string>>(apiUrl,data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      return response.data.isSuccess ? response.data : null;
    } catch (error) {
      console.error('Error updating club details', error);
      return null;
    }
  }

  public async getClubFees(clubId: string): Promise<Array<ClubFee> | null> {
    try {
      const apiUrl = `/clubs/fees/${clubId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Array<ClubFee>>>(apiUrl);
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      console.error('Error fetching club fees:', error);
      return null;
    }
  }

  public async createClubFees(data: ClubFee, clubId: string):Promise<ApiResponse<string> | null> {
    try {
      const apiUrl = `/clubs/fees/${clubId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.post<ApiResponse<string>>(apiUrl, {
        clubFeeId: data.id,
        tournamentId: data.clubTournamentId,
        feeName: data.name,
        feeType: data.feeType,
        amount: data.amountPerMember,
        dueDate: data.dueDate,
        isActive: data.isActive
      });
      return response.data.isSuccess ? response.data : null;
    } catch (error) {
      console.error('Error creating fees.', error);
      return null;
    }
  }

  public async getClubPlayerById(clubId: string, userId: string): Promise<PlayerInfo | null> {
    try {
      const apiUrl = `/clubs/${clubId}/players/${userId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<PlayerInfo>>(apiUrl);
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      return null;
    }
  }

  public async updatePlayerRole(clubId: string, data: { userId: string, role: number }): Promise<ApiResponse<string> | null> {
    try {
      const apiUrl = `/clubs/roles/${clubId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const response = await restInstance.put<ApiResponse<string>>(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      return response.data.isSuccess ? response.data : null;
    } catch (error) {
      console.error('Error updating club role', error);
      return null;
    }
  }

  public async getExtendedPlayerById(clubId: string): Promise<PlayerInfo | null> {
    try {
      const apiUrl = `/clubs/members/extended/${clubId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<PlayerInfo>>(apiUrl);
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      return null;
    }
  }

  public async getDashboardDetails(clubId: string): Promise<Dashboard | null> {
      try {
        const apiUrl = '/clubs/dashboard';
        const restInstance = this.restService.getRestInstance();
        const token = await firstValueFrom(
          this.auth.getAccessTokenSilently()
        );
        restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await restInstance.get<ApiResponse<Dashboard>>(
          `${apiUrl}/${clubId}`
        );
        return response.data.isSuccess ? response.data.data ?? null : null;
      } catch (error) {
        return null;
      }
    }
}
