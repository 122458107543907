import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DyInputComponent } from '../../../components/form-fields/dy-input/dy-input.component';
import { DySelectComponent } from '../../../components/form-fields/dy-select/dy-select.component';
import { ComponentTypes, eValidators, FieldConfig, KeyValuePair } from '../../../interfaces/dynamic.interface';
import { DynamicService } from '../../../services/dynamic.service';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'cricteams-club-registration',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule, FormsModule, DyInputComponent, DySelectComponent, DyButtonComponent],
  templateUrl: './club-registration.component.html',
  styleUrls: ['./club-registration.component.scss']
})
export class ClubRegistrationComponent implements OnInit {
  public clubRegistrationForm: FormGroup = new FormGroup({});
  public labelClass = 'block mb-1 lg:mb-2.5 text-xs md:text-sm text-light-grey font-medium';
  public inputClass ='bg-white rounded border-[1px] border-light-blue w-full py-1 px-3 h-[35px] lg:h-[42px] text-xs md:text-sm text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public btnClass = 'text-white text-sm font-medium p-1 lg:p-3 h-[36px] md:h-[42px] w-[100px] md:w-[142px]';
  public stateList: KeyValuePair[] = [];

  public dyForm!: FormGroup;
  public clubName: FieldConfig = { label: 'Club Name', name: 'clubName', componentType: ComponentTypes.Text, required: true, placeholder: "Enter club name" };
  public fullName: FieldConfig = { label: 'Full Name', name: 'fullName', componentType: ComponentTypes.Text, required: true, placeholder: "Enter full name" };
  public city: FieldConfig = { label: 'City', name: 'city', componentType: ComponentTypes.Text, required: true, placeholder: "Enter city", validations: [
    { validationType: eValidators.Pattern, validationPattern: /^[A-Za-z\s]+$/, message: 'Please enter a valid city name' }
  ] };
  public email: FieldConfig = { label: 'Email', name: 'email', componentType: ComponentTypes.Text, required: true, validations: [{ validationType: eValidators.Email }, { validationType: eValidators.Pattern, validationPattern: /^(?![.-])([A-Za-z0-9._%+-]+(?<![.-])@[A-Za-z0-9.-]+\.[A-Za-z]{2,})$/, message: 'Please enter a valid email address' }], placeholder: "Enter email" };
  public state: FieldConfig = { label: 'State', name: 'state', componentType: ComponentTypes.Select, required: true , value:'Select One', options: [] };
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dynamicService: DynamicService, private stateService: StateService) {
    this.dyForm = this.dynamicService.createFormControl([
      this.clubName,
      this.fullName,
      this.city,
      this.email,
      this.state
    ]);
  }

  ngOnInit(): void {
    this.stateList = this.stateService.getStates().map(state => ({key: state, value: state}));
    this.state.options = this.stateList;
  }

  public onSubmit(){
    if(this.dyForm.valid){
      console.log('Form submitted successfully', this.dyForm.value);
      this.dyForm.reset({
        state: 'Select One'
      });
    }
  }

  onClosePopup(){
    this.onClose.emit();
  }
}
