import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { ClubRole } from '../models/club/club-role.model';
import { UserBaseInfo } from '../models/user/user-base-info.model';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public isManagerOrCaptain: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public profileURIChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private restService: RestService,
    private authService: AuthService
  ) {}

  public async getUserInfo(): Promise<UserBaseInfo | null> {
    try {
      const apiUrl = '/users';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(
        this.authService.getAccessTokenSilently()
      );
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<UserBaseInfo>>(
        `${apiUrl}`
      );
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      console.error('Error fetching user info:', error);
      return null;
    }
  }

  public async getUserInfoFromLocalStorage(): Promise<UserBaseInfo | null> {
    try {
      const userInfo = localStorage.getItem('userBaseInfo');
      if (userInfo) {
        const userBaseInfo: UserBaseInfo = JSON.parse(userInfo);
        return userBaseInfo;
      }
      return null;
    } catch (error) {
      console.error('Error fetching user info from local storage:', error);
      return null;
    }
  }

  public async isUserManagerOrCaptain(): Promise<boolean> {
    try {
      const user = await this.getUserInfoFromLocalStorage();
      if (user) {
        const currentClubId = localStorage.getItem('currentClubId');
        const club = user.clubs.find((c) => c.id === currentClubId);
        const isManagerOrCaptain = club?.currentUserRole === ClubRole.Captain || club?.currentUserRole === ClubRole.Manager;
        console.log("user is manager" + isManagerOrCaptain);
        this.isManagerOrCaptain.next(isManagerOrCaptain);
        return isManagerOrCaptain;
      }else{
        this.isManagerOrCaptain.next(false);
        return false;
      }
    } catch (error) {
      console.error('Error fetching user info from local storage:', error);
      return false;
    }
  }

  public async getNotificationDetails(id:string): Promise<UserBaseInfo | null> {
    try {
      const apiUrl = `users/notification-preferences/${id}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(
        this.authService.getAccessTokenSilently()
      );
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<UserBaseInfo>>(
        `${apiUrl}`
      );
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      console.error('Error fetching user info:', error);
      return null;
    }
  }

  public async updateNotificationDetails(payload: { notificationPreferences: { id: string; notificationType: number; notificationChannelType: number; isEnabled: boolean; }[]; }) : Promise<ApiResponse<string> | null> {
    try {
      const apiUrl = `/users/notification-preferences`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.authService.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<string>>(apiUrl, payload);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  public async postNotificationDetails(payload: Record<string, string | number | boolean | unknown>) : Promise<ApiResponse<string> | null> {
    try {
      const apiUrl = `/users/notification-preferences`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.authService.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.post<ApiResponse<string>>(apiUrl, {
        notificationType: payload['notificationType'],
        notificationChannelType: payload['notificationChannelType'],
        isEnabled: payload['isEnabled'],
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }
}
