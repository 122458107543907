<div class="relative z-[9999] font-montserrat account-wrap" aria-labelledby="modal-title" role="dialog"
  aria-modal="true">
  <div class="fixed inset-0 bg-gray-500/75 transition-opacity flex items-center justify-center account-content p-4">
    <div
      class="bg-white rounded-md border-[1px] border-light-grey p-4 md:p-5 font-montserrat w-full h-fit max-w-[400px] sm:max-w-[800px]">
      <div class="w-full flex items-center justify-between border-b border-light-blue pb-3">
        <h4 class="text-base text-black font-semibold">My Account</h4>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
          class="size-4 cursor-pointer" (click)="handleClose()">
          <path
            d="M7.46683 22.1666L5.8335 20.5333L12.3668 14L5.8335 7.46665L7.46683 5.83331L14.0002 12.3666L20.5335 5.83331L22.1668 7.46665L15.6335 14L22.1668 20.5333L20.5335 22.1666L14.0002 15.6333L7.46683 22.1666Z"
            fill="#1D1B20"></path>
        </svg>
      </div>
      <div class="grid font-montserrat grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 relative min-h-[432px] pt-0 lg:pt-5">
        <div class="hidden lg:block col-span-1">
          <div class="flex items-center justify-between">
          </div>
        </div>
        <div class="col-span-4">
          <tabset>
            <tab heading="About You">
              <form [formGroup]="aboutForm" #form="ngForm" (ngSubmit)="onSubmit()">
                <div
                  class="grid grid-cols-1 sm:grid-cols-3 py-3 sm:py-4 lg:pl-8 pl-4 overflow-auto">
                  <div class="flex items-center justify-start gap-4 h-fit col-span-3">
                    @if (aboutForm.get('profilePicture')?.value) {
                    <div
                      class="relative flex items-center justify-center max-w-20 max-h-20 min-h-20 min-w-20 rounded-full bg-light-cyan cursor-pointer">
                      <input type="file" (change)="onFileSelected($event)" accept=".png, .jpg, .jpeg"
                        class="flex items-center justify-center max-w-20 max-h-20 min-h-20 min-w-20 rounded-full opacity-0 absolute z-10 cursor-pointer" />
                      <img [src]="aboutForm.get('profilePicture')?.value ?? playerInfo.sasUri" alt="Profile Image"
                        class="max-w-20 max-h-20 min-h-20 min-w-20 object-cover rounded-full">
                    </div>
                    }@else {
                    <div
                      class="relative flex items-center justify-center max-w-20 max-h-20 min-h-20 min-w-20 rounded-full bg-light-cyan">
                      <input type="file" (change)="onFileSelected($event)" accept=".png, .jpg, .jpeg"
                        class="absolute top-0 right-0 z-10 opacity-0 max-w-20 max-h-20 min-h-20 min-w-20 rounded-full cursor-pointer" />
                      <svg width="20" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M2.05721 23.6673C1.41554 23.6673 0.866238 23.4388 0.409294 22.9819C-0.0476508 22.525 -0.276123 21.9757 -0.276123 21.334V7.33398C-0.276123 6.69232 -0.0476508 6.14301 0.409294 5.68607C0.866238 5.22912 1.41554 5.00065 2.05721 5.00065H5.73221L7.89054 2.66732H14.8905V5.00065H8.91138L6.78221 7.33398H2.05721V21.334H20.7239V10.834H23.0572V21.334C23.0572 21.9757 22.8287 22.525 22.3718 22.9819C21.9148 23.4388 21.3655 23.6673 20.7239 23.6673H2.05721ZM20.7239 7.33398V5.00065H18.3905V2.66732H20.7239V0.333984H23.0572V2.66732H25.3905V5.00065H23.0572V7.33398H20.7239ZM11.3905 19.584C12.8489 19.584 14.0885 19.0736 15.1093 18.0527C16.1301 17.0319 16.6405 15.7923 16.6405 14.334C16.6405 12.8757 16.1301 11.6361 15.1093 10.6152C14.0885 9.5944 12.8489 9.08398 11.3905 9.08398C9.93221 9.08398 8.69263 9.5944 7.67179 10.6152C6.65096 11.6361 6.14054 12.8757 6.14054 14.334C6.14054 15.7923 6.65096 17.0319 7.67179 18.0527C8.69263 19.0736 9.93221 19.584 11.3905 19.584ZM11.3905 17.2507C10.5739 17.2507 9.8836 16.9687 9.31971 16.4048C8.75582 15.8409 8.47388 15.1507 8.47388 14.334C8.47388 13.5173 8.75582 12.827 9.31971 12.2632C9.8836 11.6993 10.5739 11.4173 11.3905 11.4173C12.2072 11.4173 12.8975 11.6993 13.4614 12.2632C14.0253 12.827 14.3072 13.5173 14.3072 14.334C14.3072 15.1507 14.0253 15.8409 13.4614 16.4048C12.8975 16.9687 12.2072 17.2507 11.3905 17.2507Z"
                          fill="#4F46E5" />
                      </svg>
                    </div>
                    }
                    <div>
                      <p class="text-xs text-light-grey font-bold m-0 whitespace-nowrap overflow-hidden text-ellipsis">
                        @if (isEdit) {
                        <cricteams-dy-input [labelClass]="labelClass" [inputClass]="inputClass" [form]="form"
                          [field]="firstName" [group]="aboutForm"
                          (input)="handleChange('aboutForm')"></cricteams-dy-input>
                        }@else {
                        {{ playerInfo.user.firstName }}
                        }
                      </p>
                      <p class="text-xs text-light-grey font-bold m-0 whitespace-nowrap overflow-hidden text-ellipsis">
                        {{ playerInfo.user.email }}
                      </p>
                    </div>
                  </div>
                  <div class="col-span-3">
                    <div class="flex items-center gap-3 my-4 error-message">
                      <label for="" class="text-xs text-black font-medium min-w-[120px] m-0">First Name</label>
                      <cricteams-dy-input class="common-input w-full" [labelClass]="labelClass"
                        [inputClass]="inputAccountClass" [form]="form" [field]="firstName" [group]="aboutForm"
                        (input)="handleChange('aboutForm')"></cricteams-dy-input>
                    </div>
                    <div class="flex items-center gap-3 my-4 error-message">
                      <label for="" class="text-xs text-black font-medium min-w-[120px] m-0">Last Name</label>
                      <cricteams-dy-input class="common-input w-full" [labelClass]="labelClass"
                        [inputClass]="inputAccountClass" [form]="form" [field]="lastName" [group]="aboutForm"
                        (input)="handleChange('aboutForm')"></cricteams-dy-input>
                    </div>
                    <div class="flex items-center gap-3 my-4 error-message">
                      <label for="" class="text-xs text-black font-medium min-w-[120px] m-0">Gender</label>
                      <cricteams-dy-select class="common-input w-full" [labelClass]="labelClass"
                        [selectClass]="inputAccountClass" [form]="form" [field]="gender" [group]="aboutForm"
                        (input)="handleChange('aboutForm')"></cricteams-dy-select>
                    </div>
                    <div class="flex items-center gap-3 my-4 error-message">
                      <label for="" class="text-xs text-black font-medium min-w-[120px] m-0">DOB</label>
                      <cricteams-dy-date-picker class="common-input w-full" [labelClass]="labelClass"
                        [inputClass]="inputAccountClass" [isToolTip]="false" [form]="form" [field]="dateOfBirth"
                        [group]="aboutForm" (onDateChange)="handleDateChange()"></cricteams-dy-date-picker>
                    </div>
                    <div class="flex items-center gap-3 my-4 error-message">
                      <label for="" class="text-xs text-black font-medium min-w-[120px] m-0">Mobile No.</label>
                      <cricteams-dy-input class="common-input w-full" [labelClass]="labelClass"
                        [inputClass]="inputAccountClass" [form]="form" [field]="phoneNumber" [group]="aboutForm"
                        (input)="handleChange('aboutForm')"></cricteams-dy-input>
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-end gap-4">
                  <cricteams-dy-button text="Save & Close" [disabled]="aboutForm.invalid" type="submit"
                    [conditionalClass]="{
                    'bg-light-blue': aboutForm.invalid,
                    'bg-dark-blue cursor-pointer': aboutForm.valid
                      }" [class]="saveAndCloseBtnClass"></cricteams-dy-button>
                </div>
              </form>
            </tab>

            <tab heading="Cricket Profile">
              <form [formGroup]="playerProfileInfo" #profileForm="ngForm" (ngSubmit)="onPlayerProfileSubmit()">
                <div class="py-3 sm:py-4 lg:pl-8 pl-4 lg:border-l lg:border-light-blue overflow-auto">
                  <div class="flex item-center my-4 common-input">
                    <p class="flex items-center min-w-44 sm:min-w-60 text-xs text-light-grey m-0">
                      Playing Role
                    </p>
                    <div class="flex items-center">
                      <cricteams-dy-select [labelClass]="selectlLabelClass" [selectClass]="inputClass"
                        [field]="playingRole" [form]="profileForm" [group]="playerProfileInfo"
                        (change)="handleChange('playerProfileInfo')"></cricteams-dy-select>
                    </div>
                  </div>
                  <div class="flex item-center my-4 common-input">
                    <p class="flex items-center min-w-44 sm:min-w-60 text-xs text-light-grey m-0">
                      Batting Style
                    </p>
                    <div class="flex items-center">
                      <cricteams-dy-select [labelClass]="selectlLabelClass" [selectClass]="inputClass"
                        [field]="battingStyle" [form]="profileForm" [group]="playerProfileInfo"
                        (change)="handleChange('playerProfileInfo')"></cricteams-dy-select>
                    </div>
                  </div>
                  <div class="flex item-center my-4 common-input">
                    <p class="flex items-center min-w-44 sm:min-w-60 text-xs text-light-grey m-0">
                      Bowling Style
                    </p>
                    <div class="flex items-center">
                      <cricteams-dy-select [labelClass]="selectlLabelClass" [selectClass]="inputClass"
                        [field]="bowlingStyle" [form]="profileForm" [group]="playerProfileInfo"
                        (change)="handleChange('playerProfileInfo')"></cricteams-dy-select>
                    </div>
                  </div>
                  <div class="flex item-center my-4 common-input">
                    <p class="flex items-center min-w-44 sm:min-w-60 text-xs text-light-grey m-0">
                      Wicket Keeper
                    </p>
                    <div class="flex items-center">
                      <div class="inline-flex items-center">
                        <label class="flex items-center cursor-pointer relative">
                          <input type="checkbox" formControlName="wicketKeeper"
                            (click)="handleChange('playerProfileInfo')"
                            class="peer h-5 min-w-5 w-full cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-slate-800 checked:border-slate-800"
                            id="check" />
                          <span
                            class="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20"
                              fill="currentColor" stroke="currentColor" stroke-width="1">
                              <path fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"></path>
                            </svg>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-end gap-4">
                  <cricteams-dy-button text="Save" type="submit" [class]="saveAndCloseBtnClass"
                    [disabled]="(playerProfileInfo.invalid || !playerProfileInfoEdited)" [conditionalClass]="{
                  'bg-light-blue': playerProfileInfo.invalid || !playerProfileInfoEdited,
                  'bg-dark-blue cursor-pointer': (playerProfileInfo.valid || playerProfileInfoEdited)
                }">
                  </cricteams-dy-button>
                </div>
              </form>
            </tab>

            <tab heading="Notification">
              <form [formGroup]="notificationForm">
                <div class="py-3 sm:py-4 lg:pl-8 pl-4 lg:border-l lg:border-light-blue overflow-auto h-auto sm:h-[240px]">
                  <div class="flex item-center my-4">
                    <p class="min-w-44 sm:min-w-60 text-xs text-light-grey m-0">
                      Practice Reminder
                    </p>
                    <div class="flex items-center gap-5">
                      <div class="flex items-center gap-3">
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center" formGroupName="practiceEmail">
                            <label class="relative flex items-center cursor-pointer" for="practice-reminder-1">
                              <input type="checkbox" formControlName="isChecked"
                                (click)="handleNotificationChange('practiceEmail')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="practice-reminder-1" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="practice-reminder-1">Email</label>
                          </div>
                        </div>
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center" formGroupName="practiceMobile">
                            <label class="relative flex items-center cursor-pointer" for="practice-reminder-2">
                              <input type="checkbox" formControlName="isChecked"
                                (click)="handleNotificationChange('practiceMobile')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="practice-reminder-2" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="practice-reminder-2">Mobile</label>
                          </div>
                        </div>
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center">
                            <label class="relative flex items-center cursor-pointer" for="practice-reminder-4">
                              <input type="checkbox" [checked]="isPracticeBoth" (click)="handleBoth('practice')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="practice-reminder-4" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="practice-reminder-4">Both</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex item-center my-4">
                    <p class="min-w-44 sm:min-w-60 text-xs text-light-grey m-0">
                      Match Reminder
                    </p>
                    <div class="flex items-center gap-5">
                      <div class="flex items-center gap-3">
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center" formGroupName="matchEmail">
                            <label class="relative flex items-center cursor-pointer" for="match-reminder-1">
                              <input type="checkbox" formControlName="isChecked"
                                (click)="handleNotificationChange('matchEmail')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="match-reminder-1" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="match-reminder-1">Email</label>
                          </div>
                        </div>
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center" formGroupName="matchMobile">
                            <label class="relative flex items-center cursor-pointer" for="match-reminder-2">
                              <input type="checkbox" formControlName="isChecked"
                                (click)="handleNotificationChange('matchMobile')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="match-reminder-2" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="match-reminder-2">Mobile</label>
                          </div>
                        </div>
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center">
                            <label class="relative flex items-center cursor-pointer" for="match-reminder-4">
                              <input type="checkbox" [checked]="isMatchBoth" (click)="handleBoth('match')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="match-reminder-4" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="match-reminder-4">Both</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex item-center my-4">
                    <p class="min-w-44 sm:min-w-60 text-xs text-light-grey m-0">
                      Roster Selected Reminders
                    </p>
                    <div class="flex items-center gap-5">
                      <div class="flex items-center gap-3">
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center" formGroupName="rosterEmail">
                            <label class="relative flex items-center cursor-pointer" for="roster-reminder-1">
                              <input type="checkbox" formControlName="isChecked"
                                (click)="handleNotificationChange('rosterEmail')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="roster-reminder-1" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="roster-reminder-1">Email</label>
                          </div>
                        </div>
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center" formGroupName="rosterMobile">
                            <label class="relative flex items-center cursor-pointer" for="roster-reminder-3">
                              <input type="checkbox" formControlName="isChecked"
                                (click)="handleNotificationChange('rosterMobile')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="roster-reminder-3" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="roster-reminder-3">Mobile</label>
                          </div>
                        </div>
                        <div class="flex gap-10">
                          <div class="notification-content inline-flex items-center">
                            <label class="relative flex items-center cursor-pointer" for="roster-reminder-4">
                              <input type="checkbox" [checked]="isRosterBoth" (click)="handleBoth('roster')"
                                class="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
                                id="roster-reminder-4" />
                              <span
                                class="absolute bg-slate-800 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              </span>
                            </label>
                            <label class="ml-2 text-slate-600 cursor-pointer text-xs"
                              for="roster-reminder-4">Both</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </tab>
            <tab heading="Emergency Contacts">
              <div class="py-3 sm:py-4 lg:pl-8 pl-4 h-auto overflow-hidden">
                <div class="flex items-center mb-5">
                </div>
                @if(playerInfo.emergencyContacts && playerInfo.emergencyContacts.length > 0){
                <div class="mx-auto overflow-auto">
                  <div class="inline-block min-w-full overflow-auto border-light-blue border-[1px] custom-scroll">
                    <table class="min-w-full leading-normal">
                      <thead>
                        <tr class="whitespace-nowrap">
                          <th
                            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Emergency Person Name
                          </th>
                          <th
                            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Emergency No.
                          </th>
                          <th
                            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        @for (contact of playerInfo.emergencyContacts; track $index) {
                        <tr class="whitespace-nowrap">
                          <td class="p-2 px-3 border-b border-gray-200 bg-white text-xs">
                            <p class="text-gray-900 whitespace-no-wrap">{{ contact.name }}</p>
                          </td>
                          <td class="p-2 px-3 border-b border-gray-200 bg-white text-xs">
                            <p class="text-gray-900 whitespace-no-wrap">{{ contact.phoneNumber }}</p>
                          </td>
                          <td class="p-2 px-3 border-b border-gray-200 bg-white text-xs">
                            <a (click)="openEmergencyPopup(emergencyContactModal, contact)"
                              class="text-xs text-dark-blue underline">
                              Edit</a>
                          </td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                }
                <a (click)="openEmergencyPopup(emergencyContactModal)" class="text-xs text-dark-blue underline">+
                  Add Emergency Contact</a>
              </div>
            </tab>
            <tab heading="Fees & Payments">
              @if(!playerInfo.playerClubPayments ||
              playerInfo.playerClubPayments.length <= 0){
                <div class="py-3 sm:py-4 lg:pl-8 pl-4 lg:border-l lg:border-light-blue min-h-[165px]">
                  <div
                  class=" flex items-center justify-center text-xs rounded-md text-black border border-light-blue p-3 h-20 w-full sm:w-[75%]">
                  This player has no active fees assigned at the moment.
                  </div>
                </div>
                }@else {
                <div class="py-3 sm:py-4 lg:pl-8 pl-4 overflow-auto max-h-[350px] w-full lg:w-fit lg:border-l lg:border-light-blue h-auto sm:h-[240px]">
                  <table class="w-full">
                    <thead>
                      <tr class="whitespace-nowrap border-t border-b border-light-blue">
                        <th class="text-xs text-left font-normal text-light-grey p-3">
                          Name
                        </th>
                        <th class="text-xs text-left font-normal text-light-grey p-3">
                          Type
                        </th>
                        <th class="text-xs text-left font-normal text-light-grey p-3">
                          Amount Due
                        </th>
                        <th class="text-xs text-left font-normal text-light-grey p-3">
                          Paid Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      @for (item of playerInfo.playerClubPayments; track $index) {
                      <tr class="whitespace-nowrap">
                        <td class="text-xs text-black font-medium p-3">
                          {{ item.clubFee.name }}
                        </td>
                        <td class="text-xs font-medium text-light-grey p-3">
                          {{ mapFeeType(item.clubFee.feeType) }}
                        </td>
                        <td [ngClass]="{
                                    'text-dark-green': commonService.calculateDeuAmount(item.clubFee.amountPerMember, item.amountPaid) === 0,
                                    'text-red-500': commonService.calculateDeuAmount(item.clubFee.amountPerMember, item.amountPaid) > 0
                                  }" class="text-xs font-bold p-3">
                          ${{ commonService.calculateDeuAmount(item.clubFee.amountPerMember, item.amountPaid).toFixed(2) }}
                        </td>
                        <td [ngClass]="{
                                  'text-light-grey': !item.paymentNotes,
                                  'text-dark-blue underline': item.paymentNotes
                                }" class="relative text-xs font-medium p-3 group">
                          <span (click)="openModal(paymentNotesModal, item.paymentNotes)">
                            {{ item.paymentDate || '' | date : 'MM-dd-YYYY' }}
                          </span>
                          <div *ngIf="item.paymentNotes && item.paymentDate" id="tooltip-click"
                            class="absolute z-10 px-3 py-2 text-xs font-medium text-white bg-light-blue rounded-lg shadow-xs left-[-15px] top-[-20px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 dark:bg-gray-700">
                            Click to view payment notes
                          </div>
                        </td>
                      </tr>
                      }
                    </tbody>
                  </table>
                </div>
                }
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #emergencyContactModal>
  <cricteams-emergency-contact-form [playerInfo]="playerInfo" [emergencyDetails]="emergencyDetails"
    (onCloseModal)="handleContactClose($event)"></cricteams-emergency-contact-form>
</ng-template>

<ng-template #paymentNotesModal>
  <div class="relative z-[9999] font-montserrat" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
    <div class="p-4 fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center">
      <div class="bg-white h-auto min-w-96 p-4 xl:p-5 rounded w-auto">
        <div class="flex items-center justify-between">
          <h4 class="font-semibold text-black text-sm md:text-base">Payment Notes</h4>
        </div>
          <div class="mt-5">
            <textarea [class]="inputClass" [readOnly]="true" [value]="paymentNotes"></textarea>
          </div>
          <div class="flex itesm-center justify-center mt-5">
            <cricteams-dy-button (click)="handleModalClose()" [class]="okayBtnClass" text="Okay"></cricteams-dy-button>
          </div>
      </div>
    </div>
  </div>
</ng-template>
