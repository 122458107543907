<section class="relative bg-indigo-600/5">
    <div class="container-fluid relative">
        <div class="grid grid-cols-1 min-h-screen">
            <div class="flex flex-col justify-center items-center py-10 px-4">
                <div class="title-heading text-center my-auto">
                    <div class="text-center header-logo mb-2"> <!-- Reduced margin-bottom -->
                        <a routerLink="/"><img src="assets/images/logo-light.png" class="mx-auto" alt=""></a>
                    </div>
                    <img src="assets/images/error.png" class="mx-auto mb-2 mt-2" alt=""> <!-- Reduced margin-bottom and added margin-top -->
                    <h1 class="mt-2 mb-2 md:text-5xl text-3xl font-bold">{{title}}</h1>
                    <p class="text-slate-400 mb-4">{{message}}</p>
                    
                    <div class="mt-4">
                        <a routerLink="/" class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Back to Home</a>
                    </div>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>
<cricteams-app-back-to-home/>