export class FieldConfig {
  id?: string;
  name!: string;
  label!: string;
  componentType!: string;
  validations?: Validator[];
  options?: KeyValuePair[];
  value?: string | number | boolean | unknown;
  required?: boolean = false;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
  placeholder?: string;
}

export class Validator {
  validationType!: string;
  validationPattern?: RegExp;
  validator?: any;
  message?: string;
  value?: string | number = '';
}

export class KeyValuePair {
  key!: string;
  value!: string;
}

export enum ComponentTypes {
  Text = 'text',
  Select = 'select',
  Number = 'number',
  Email = 'email',
}

export enum eValidators {
  Required = 'required',
  Email = 'email',
  Pattern = 'pattern',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Min = 'min',
  Max = 'max',
}
