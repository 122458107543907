<div class="relative z-[9999] font-montserrat" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>

  <div class="p-4 fixed inset-0 z-10 overflow-y-auto flex items-center justify-center ">
    <div class="bg-white w-full max-w-screen-xl h-auto p-5 rounded">
      <div class="flex items-center justify-between pb-3.5 border-b border-light-grey">
        <h2 class="text-black font-semibold text-base">Select Ground</h2>
        <button type="button" (click)="handleClose()">
          <svg class="size-5" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.46683 22.1666L5.8335 20.5333L12.3668 14L5.8335 7.46665L7.46683 5.83331L14.0002 12.3666L20.5335 5.83331L22.1668 7.46665L15.6335 14L22.1668 20.5333L20.5335 22.1666L14.0002 15.6333L7.46683 22.1666Z"
              fill="#1D1B20" />
          </svg>
        </button>
      </div>
      <div class="flex items-start sm:items-center justify-between flex-col sm:flex-row flex-wrap py-3.5 border-b gap-3 sm:gap-0 border-light-grey">
        <cricteams-state-filter (onSelectState)="filterGrounds($event)" (onResetState)="resetStateSelection()"></cricteams-state-filter>
        <div class="relative w-full sm:w-fit">
          <input type="text"
            class="text-xs text-black bg-light-cyan p-2.5 w-full sm:w-96 pl-10 border-none outline-none rounded"
            placeholder="Search..."  [formControl]="groundSearchControl" >
          <svg class="absolute top-2 left-3.5" width="16" height="17" viewBox="0 0 16 17" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path
                d="M7.33333 13.0002C10.2789 13.0002 12.6667 10.6123 12.6667 7.66683C12.6667 4.72131 10.2789 2.3335 7.33333 2.3335C4.38781 2.3335 2 4.72131 2 7.66683C2 10.6123 4.38781 13.0002 7.33333 13.0002Z"
                stroke="#161C2D" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13.9995 14.3335L11.1328 11.4668" stroke="#161C2D" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
            </g>
          </svg>
        </div>
      </div>
      <div class="max-h-[620px] overflow-auto">
        <div class="" *ngIf="myGrounds.length > 0">
          <h4 class="mt-6 mb-3.5 text-base text-black font-semibold">My Ground</h4>
          <div *ngFor="let ground of myGrounds"
            class="mt-6 bg-light-slate p-3 border-[1px] border-light-grey rounded overflow-auto tab-content">
            <div class="flex items-center justify-between gap-6 whitespace-nowrap">
              <div class="bg-white min-w-8 min-h-8 flex items-center justify-center">
                <span class="text-dark-blue text-xs font-semibold">{{ commonService.generateBadge(ground.name) }}</span>
              </div>
              <div class="gap-4 flex items-center justify-between w-full ">
                <h3 class="text-sm lg:text-base font-semibold text-black w-[25%] min-w-[150px] md:min-w-[200px] text-ellipsis overflow-hidden">{{ground.name}}</h3>
                <div class="w-[40%] sm:w-fit">
                  <label for="" class="block text-xs text-light-grey mb-2">City/Area</label>
                  <span class="text-xs text-black">
                    {{ (ground.addressLine1 ? ground.addressLine1 + ' ' : '') +
                       (ground.addressLine2 ? ground.addressLine2 + ', ' : '') +
                       ground.city }}
                </span>
                </div>
                <div class="w-[3%]">
                  <svg class="size-4" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.4751 12.4521L7.12511 5.10215C6.87511 4.85215 6.75428 4.55632 6.76261 4.21465C6.77094 3.87298 6.90011 3.57715 7.15011 3.32715C7.40011 3.07715 7.69594 2.95215 8.03761 2.95215C8.37928 2.95215 8.67511 3.07715 8.92511 3.32715L16.6001 11.0271C16.8001 11.2271 16.9501 11.4521 17.0501 11.7021C17.1501 11.9521 17.2001 12.2021 17.2001 12.4521C17.2001 12.7021 17.1501 12.9521 17.0501 13.2021C16.9501 13.4521 16.8001 13.6771 16.6001 13.8771L8.90011 21.5771C8.65011 21.8271 8.35844 21.948 8.02511 21.9396C7.69178 21.9313 7.40011 21.8021 7.15011 21.5521C6.90011 21.3021 6.77511 21.0063 6.77511 20.6646C6.77511 20.323 6.90011 20.0271 7.15011 19.7771L14.4751 12.4521Z"
                      fill="#4F46E5" />
                  </svg>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <h4 class="mt-6 mb-3.5 text-base text-black font-semibold" *ngIf="allGrounds.length > 0">All</h4>
          <div *ngFor="let ground of allGrounds"  (click)="onSelectGround(ground)" class="mt-6 bg-light-slate p-3 border-[1px] border-light-grey rounded overflow-auto tab-content">
            <div class="flex items-center justify-between gap-4 whitespace-nowrap cursor-pointer">
              <div class="bg-white min-w-8 min-h-8 flex items-center justify-center">
                <span class="text-dark-blue text-xs font-semibold">{{commonService.generateBadge(ground.name)}}</span>
              </div>
              <div class="gap-4 flex items-center justify-between w-full ">
                <h3 class="text-sm lg:text-base font-semibold text-black w-[25%] min-w-[150px] md:min-w-[200px] text-ellipsis overflow-hidden">{{ground.name}}</h3>
                <div class="w-[80%] lg:w-[50%] whitespace-nowrap">
                  <label for="" class="block text-xs text-light-grey mb-2">City/Area</label>
                  <span class="text-xs text-black">
                    {{ (ground.addressLine1 ? ground.addressLine1 + ' ' : '') +
                       (ground.addressLine2 ? ground.addressLine2 + ', ' : '') +
                       ground.city }}
                  </span>
                </div>
                <div class="w-[3%]">
                  <svg class="size-4" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.4751 12.4521L7.12511 5.10215C6.87511 4.85215 6.75428 4.55632 6.76261 4.21465C6.77094 3.87298 6.90011 3.57715 7.15011 3.32715C7.40011 3.07715 7.69594 2.95215 8.03761 2.95215C8.37928 2.95215 8.67511 3.07715 8.92511 3.32715L16.6001 11.0271C16.8001 11.2271 16.9501 11.4521 17.0501 11.7021C17.1501 11.9521 17.2001 12.2021 17.2001 12.4521C17.2001 12.7021 17.1501 12.9521 17.0501 13.2021C16.9501 13.4521 16.8001 13.6771 16.6001 13.8771L8.90011 21.5771C8.65011 21.8271 8.35844 21.948 8.02511 21.9396C7.69178 21.9313 7.40011 21.8021 7.15011 21.5521C6.90011 21.3021 6.77511 21.0063 6.77511 20.6646C6.77511 20.323 6.90011 20.0271 7.15011 19.7771L14.4751 12.4521Z"
                      fill="#4F46E5" />
                  </svg>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 font-semibold text-black text-base" *ngIf="allGrounds.length <= 0">
          <p class="">Search not found <span class="font-normal">{{ groundSearchControl.value }}</span></p>
          <div class="py-12 text-xs text-center max-w-[668px] mx-auto font-normal">
            <p class="py-4">The searched ground is not available.</p>
            <p>
              Please contact our customer care team at <a href="#"
                class="text-blue font-semibold">support&#64;crickteams.com</a> for assistance.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
