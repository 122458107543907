import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as feather from 'feather-icons';
import { environment } from '../environments/environment';
import { ToggleComponent } from './components/toggle/toggle.component';
import { routeUrls } from './constants/constants';
import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';
import { AlertService } from './services/alert.service';
import { LoadingService } from './services/loading.service';


@Component({
  standalone: true,
  imports: [CommonModule, RouterModule,ToggleComponent,AlertBannerComponent],
  selector: 'cricteams-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public isFullScreenLoading = false;

  constructor(public authService: AuthService, private router: Router, private alertService: AlertService, private loadingService: LoadingService) {}
  public message!: string;
  public isSuccess!: boolean;
  public isError!: boolean;

  title = 'crickteams';
  ngAfterViewInit() {
    feather.replace();
  }
  ngOnInit() {
    this.loadRecaptchaScript();
    this.authService.handleRedirectCallback().subscribe({
      // Wait for Auth0 to handle the callback and then navigate to the home page
      next: (result) => {
          const targetRoute = result?.appState?.target || `/${routeUrls.DASHBOARD}`;
          this.router.navigate([targetRoute]); // Navigate to the intended route or default to '/'
      }
    });

    this.alertService.message.subscribe((message: string) => {
      this.message = message;
    })
    this.alertService.isSuccess.subscribe((isSuccess: boolean) => {
      this.isSuccess = isSuccess;
    })
    this.alertService.isError.subscribe((isError: boolean) => {
      this.isError = isError;
    })
  }
  private loadRecaptchaScript() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaSiteKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
}
