import { Route } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { AuthGuard } from './utilities/auth.gaurd';

export const appRoutes: Route[] = [
  {path:'', component:LandingComponent},
  {path:'handle-invite', loadComponent: ()=> import('./pages/onboarding/handle-invite/handle-invite.component').then(m => m.HandleInviteComponent)},
  {path:'auth-callback', loadComponent: ()=> import('./auth/auth-callback/auth-callback.component').then(m => m.AuthCallbackComponent)},
  {path:'dashboard', loadComponent: ()=> import('./pages/dashboard/index/index.component').then(m => m.IndexComponent), canActivate:[AuthGuard]},
  {path:'leagues/index', loadComponent: ()=> import('./pages/leagues/league-index.component').then(m => m.LeagueIndexComponent), canActivate:[AuthGuard]},
  {path:'grounds/index', loadComponent: ()=> import('./pages/grounds/ground-index.component').then(m => m.GroundIndexComponent), canActivate:[AuthGuard]},
  {path:'series/index', loadComponent: ()=> import('./pages/series/series-index.component').then(m => m.SeriesIndexComponent), canActivate:[AuthGuard]},
  {path:'matches/index', loadComponent: ()=> import('./pages/matches/match-index.component').then(m => m.MatchIndexComponent), canActivate:[AuthGuard]},
  {path:'players/index', loadComponent: ()=> import('./pages/players/player-index.component').then(m => m.PlayerIndexComponent), canActivate:[AuthGuard]},
  {path:'noticeboard/nb-index', loadComponent: ()=> import('./pages/noticeboard/nb-index/nb-index.component').then(m => m.NbIndexComponent), canActivate:[AuthGuard]},
  {path:'noticeboard/gallery', loadComponent: ()=> import('./pages/noticeboard/gallery/gallery.component').then(m => m.GalleryComponent), canActivate:[AuthGuard]},
  {path:'settings/index', loadComponent: ()=> import('./pages/settings/settings-index.component').then(m => m.SettingsIndexComponent), canActivate:[AuthGuard]},
  {path:'user/profile', loadComponent: ()=> import('./pages/user/user-profile.component').then(m => m.UserProfileComponent), canActivate:[AuthGuard]},
  { path: 'terms', loadComponent: ()=> import('./pages/miscellaneous/terms-of-use/terms-of-use.component').then(m => m.TermsOfUseComponent) },
  { path: 'privacy-policy', loadComponent: ()=> import('./pages/miscellaneous/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent) },
  { path: 'cookie-policy', loadComponent: ()=> import('./pages/miscellaneous/cookie-policy/cookie-policy.component').then(m => m.CookiePolicyComponent) }
];
