import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, output, TemplateRef } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';
import { DyDatePickerComponent } from '../../../components/form-fields/dy-date-picker/dy-date-picker.component';
import { DyInputComponent } from '../../../components/form-fields/dy-input/dy-input.component';
import { DySelectComponent } from "../../../components/form-fields/dy-select/dy-select.component";
import { routeUrls } from '../../../constants/constants';
import {
  ComponentTypes,
  eValidatorRegEx,
  eValidators,
  FieldConfig,
  KeyValuePair,
} from '../../../interfaces/dynamic.interface';
import { League } from '../../../models/league/league-info.model';
import { UserBaseInfo } from '../../../models/user/user-base-info.model';
import { ClubService } from '../../../services/club.service';
import { CommonService } from '../../../services/common.service';
import { DynamicService } from '../../../services/dynamic.service';
import { UserService } from '../../../services/user.service';
import { LeagueModalComponent } from '../../leagues/league-modal/league-modal.component';
import { TournamentService } from './../../../services/tournament.service';
import { ClubPlayer } from '../../../models/club/club-player.model';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'cricteams-add-tournament',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DyButtonComponent,
    RouterModule,
    DyInputComponent,
    DyDatePickerComponent,
    BsDatepickerModule,
    TooltipModule,
    LeagueModalComponent,
    DySelectComponent,
  ],
  templateUrl: './add-tournament.component.html',
  styleUrl: './add-tournament.component.scss',
  providers: [BsModalService],
})
export class AddTournamentComponent implements OnInit {
  public cancelBtnClass =
    'text-dark-blue border border-dark-blue text-xs font-semibold p-2 xl:py-3 sm:px-5 h-[36px] sm:h-[40px] w-fit hover:text-white hover:bg-darkBlue transition duration-700 ease-in-out';
  public saveBtnClass =
    'text-white text-xs bg-dark-blue font-semibold px-5 sm:p-2 md:p-3 h-[36px] sm:h-[40px] md:w-[100px] transition duration-700 ease-in-out';
  public labelClass = 'flex items-center text-xs text-light-grey font-medium mb-2';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full p-2 md:h-[40px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public constants = routeUrls;
  public isTournametDataAvailable = false;
  public dyForm!: FormGroup;
  public userBaseInfo!: UserBaseInfo;
  public cUserId!: string;
  public vcUserId!: string;
  public leagueData!: League;
  public players: ClubPlayer[] = [];
  public clubId!: string;
  playerOptions: KeyValuePair[] = [];
  public currentDate = new Date();

  @Input() isModal!: boolean;
  onCloseModal = output<boolean>();
  modalRef!: BsModalRef;

  constructor(
    private dynamicService: DynamicService,
    private router: Router,
    private modalService: BsModalService,
    private userService: UserService,
    private tournamentService: TournamentService,
    private commonService: CommonService,
    private clubService: ClubService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.currentDate.setHours(0, 0, 0, 0);
    this.createForm();

    if (this.dynamicService.dyForm) {
      this.setValue(this.dynamicService.dyForm);
    }

    if (this.isTournametDataAvailable) {
      this.router.navigateByUrl(this.constants.LIST_TOURNAMENTS);
    }

    this.clubId = localStorage.getItem('currentClubId') ?? '';
    this.getPlayers(this.clubId);
  }

  createForm() {
    this.dyForm = this.dynamicService.createFormControl([
      this.tournamentName,
      this.leagueName,
      this.startDate,
      this.endDate,
      this.captainSelection,
      this.viceCaptainSelection,
    ]);
  }

  public tournamentName: FieldConfig = {
    label: 'Tournament Name',
    name: 'tournamentName',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter tournament name',
    validations: [
      { validationType: eValidators.Text },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.AlphaNumericWithSpaces,
        message: 'Please enter a valid tournament name',
      },
    ],
  };
  public leagueName: FieldConfig = {
    label: 'League Name',
    name: 'leagueName',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Select league',
  };
  public startDate: FieldConfig = {
    label: 'Start Date',
    name: 'startDate',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'MM/DD/YYYY',
  };
  public endDate: FieldConfig = {
    label: 'End Date',
    name: 'endDate',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'MM/DD/YYYY',
  };
  public captainSelection: FieldConfig = {
    label: 'Captain',
    name: 'captainUserId',
    componentType: ComponentTypes.Select,
    value: '',
    placeholder: 'Select Captain',
    options: [],
  };
  public viceCaptainSelection: FieldConfig = {
    label: 'Vice Captain',
    name: 'vcUserId',
    componentType: ComponentTypes.Select,
    value: '',
    placeholder: 'Select Vice-Captain',
    options: [],
  };

  public onSubmit() {
    if (this.dyForm.valid) {
      this.saveTournament();
    }
  }

  async saveTournament() {
    const clubId = localStorage.getItem('currentClubId') || '';
    if (clubId) {
      const user = await this.userService.getUserInfoFromLocalStorage();
      const payload = {
        ...this.dyForm.value,
        startDate: this.commonService.getFormattedDate(this.dyForm.value['startDate']),
        endDate: this.commonService.getFormattedDate(this.dyForm.value['endDate']),
        clubId: clubId,
        leagueId: this.leagueData.id
      };

       this.tournamentService.saveTournament(payload)
      .then((response: any) => {
        if (response?.isSuccess) {
          this.alertService.showSuccess(response.message);
          this.dyForm.setErrors(null);
          this.dyForm.markAsPristine();
          this.dyForm.markAsUntouched();
          this.updateTournamentList(clubId);
          if (!this.isModal) {
            this.router.navigateByUrl(this.constants.LIST_TOURNAMENTS);
          }
          this.onCloseModal.emit(true);
        } else {
          if (response.error.code === 400) {
            this.alertService.showError(response.error.description);
            this.dyForm.setErrors(null);
            this.dyForm.markAsPristine();
            this.dyForm.markAsUntouched();
            this.dyForm.reset({
              tournamentName: this.dyForm.get('tournamentName')?.value,
              leagueName: this.dyForm.get('leagueName')?.value,
              captainUserId: this.dyForm.get('captainUserId')?.value,
              vcUserId: this.dyForm.get('vcUserId')?.value
            });
          } else {
            this.alertService.showError(response.message);
            this.dyForm.setErrors(null);
          }
        }
      });
    }
  }

  async updateTournamentList(clubId: string) {
    this.tournamentService.getClubTournaments(clubId ?? '').then((response: any) => {
      if (response?.isSuccess) {
        const tournaments = response.data;

        if(tournaments){
          this.tournamentService.tournamentList.next(tournaments)
        }
      } else {
        if (response.error.code === 400) {
          this.alertService.showError(response.error.description);
        } else {
          this.alertService.showError(response.message);
        }
      }
    })
  }

  public setValue(dyForm: FormGroup) {
    this.dyForm.setValue({
      tournamentName: dyForm.value['tournamentName'],
      leagueName: dyForm.value['leagueName'],
      startDate: dyForm.value['startDate'],
      endDate: dyForm.value['endDate'],
    });
  }

  public setLeagueValue(league: League) {
    this.leagueData = league;
    if (league) {
      this.dyForm.patchValue({
        leagueName: league?.name,
      });
    }
  }

  public handleCancel() {
    if (this.isModal) {
      this.onCloseModal.emit(true);
    } else {
      this.dyForm.reset();
      this.router.navigateByUrl(this.constants.TOURNAMENTS);
    }
  }

  public openLeagueSelectionModal(template: TemplateRef<HTMLElement>) {
    this.modalRef = this.modalService.show(template);
  }

  public handleClose(event: boolean) {
    if (event) {
      this.modalRef.hide();
    }
  }

  updatePlayerOptions() {
    const playerOptions = [] as KeyValuePair[];
    this.players.forEach((p) => {
      const firstName = p.user.firstName || '';
      const lastName = p.user.lastName || '';

      playerOptions.push({
        key: p.user.id,
        value: (firstName && lastName) ? `${firstName} ${lastName}` : firstName || lastName,
      });
    });
    this.playerOptions = playerOptions;
    this.captainSelection.options = playerOptions;
    this.viceCaptainSelection.options = playerOptions;
  }

  getPlayers(clubId: string) {
    this.clubService
      .getClubPlayers(clubId)
      .then((response: ClubPlayer[] | unknown) => {
        const apiResponse = response as ClubPlayer[];
        if (apiResponse.length) {
          this.players = apiResponse.filter((p) => p.isActive);
          this.updatePlayerOptions();
        }
      });
  }

  handleCaptainChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const captainUserId = target.value;
    this.viceCaptainSelection.options = this.playerOptions;

    this.viceCaptainSelection.options = this.viceCaptainSelection.options?.filter((p) => p.key !== captainUserId);
  }

  handleVcChange(event: Event){
    const target = event.target as HTMLInputElement;
    const vcUserId = target.value;
    this.captainSelection.options = this.playerOptions;
    this.captainSelection.options = this.captainSelection.options?.filter((p) => p.key !== vcUserId);
  }
}
