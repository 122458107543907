import { CommonModule } from '@angular/common';
import {
  Component,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';
import { DyInputComponent } from '../../../components/form-fields/dy-input/dy-input.component';
import { DySelectComponent } from '../../../components/form-fields/dy-select/dy-select.component';
import {
  ComponentTypes,
  eValidatorRegEx,
  eValidators,
  FieldConfig,
  KeyValuePair,
} from '../../../interfaces/dynamic.interface';
import { DynamicService } from '../../../services/dynamic.service';
import { OnboardingService } from '../../../services/onboarding.service';
import { StateService } from '../../../services/state.service';
import { MailConfirmationComponent } from '../mail-confirmation/mail-confirmation.component';

@Component({
  selector: 'cricteams-club-registration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DyInputComponent,
    DySelectComponent,
    DyButtonComponent,
    MailConfirmationComponent,
  ],
  templateUrl: './club-registration.component.html',
  styleUrls: ['./club-registration.component.scss'],
})
export class ClubRegistrationComponent implements OnInit {
  public clubRegistrationForm: FormGroup = new FormGroup({});
  public labelClass = 'block mb-2.5 text-sm text-light-grey font-medium';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full p-3 h-[42px] text-sm text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public btnClass = 'text-white text-sm font-medium p-3 h-[42px] w-[142px]';
  public stateList: KeyValuePair[] = [];
  public errorMessage!: string;

  public dyForm!: FormGroup;
  public clubName: FieldConfig = {
    label: 'Club Name',
    name: 'clubName',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter club name',
    validations: [
      { validationType: eValidators.Text },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.AlphaNumericWithSpaces,
        message: 'Please enter a valid club name',
      },
    ],
  };
  public websiteUrl: FieldConfig = {
    label: 'Website Url',
    name: 'websiteUrl',
    componentType: ComponentTypes.Text,
    required: false,
    placeholder: 'Enter website url',
    validations: [
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Url,
        message: 'Please enter a valid url',
      },
    ],
  };
  public city: FieldConfig = {
    label: 'City',
    name: 'city',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter city',
    validations: [
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Text,
        message: 'Please enter a valid city name',
      },
    ],
  };
  public email: FieldConfig = {
    label: 'Email',
    name: 'email',
    componentType: ComponentTypes.Text,
    required: true,
    validations: [
      { validationType: eValidators.Email },
      {
        validationType: eValidators.Pattern,
        validationPattern:eValidatorRegEx.Email,
        message: 'Please enter a valid email address',
      },
    ],
    placeholder: 'Enter email',
  };
  public state: FieldConfig = {
    label: 'State',
    name: 'state',
    componentType: ComponentTypes.Select,
    required: true,
    value: '',
    options: [],
    placeholder:"Select One"
  };
  public showRegistrationPopup = false;

  @ViewChild('mailConfirmation') mailConfirmation!: MailConfirmationComponent;
  @HostListener('window:click', ['$event'])
    onClick(event: MouseEvent): void {
      const modalElement = event.target as HTMLElement;
      modalElement.classList;
      if (modalElement.classList.contains('layout-overlay')) {
        this.onClosePopup();
      }
    }

  constructor(
    private dynamicService: DynamicService,
    private stateService: StateService,
    private onboardingService: OnboardingService,
  ) {
    this.dyForm = this.dynamicService.createFormControl([
      this.clubName,
      this.websiteUrl,
      this.city,
      this.email,
      this.state,
    ]);
  }

  ngOnInit(): void {
    this.stateList = this.stateService
      .getStates()
      .map((state) => ({ key: state, value: state }));
    this.state.options = this.stateList.filter((key)=>key.value !== 'Select One');

    if (this.dynamicService.dyForm) {
      this.setValue(this.dynamicService.dyForm);
    }
  }

  public showPopup() {
    this.showRegistrationPopup = true;
    document.body.style.overflow = 'hidden';
  }

  public onClosePopup() {
    this.showRegistrationPopup = false;
    this.dyForm.reset({
      state:''
    });
    this.errorMessage =''
    document.body.style.overflow = 'auto';
  }

  public setValue(dyForm: FormGroup) {
    this.dyForm.setValue({
      clubName: dyForm.value['clubName'],
      fullName: dyForm.value['fullName'],
      email: dyForm.value['email'],
      city: dyForm.value['city'],
      state: dyForm.value['state'],
    });
  }

  public onSubmit() {
    if (this.dyForm.valid) {
      this.submitRegistrationForm();
    }
  }

  public submitRegistrationForm() {
    this.dynamicService.setCurrentForm(this.dyForm);
    this.onboardingService
      .registerClub(this.dyForm.value)
      .then((response: any) => {
        if (response?.isSuccess) {
          this.showRegistrationPopup = false;
          this.mailConfirmation.showPopup(this.dyForm.value.email);
          this.dyForm.setErrors(null);
          this.dyForm.markAsPristine();
          this.dyForm.markAsUntouched();
        }
        else {
          console.log(response);
          this.errorMessage = response?.response?.data?.message ?? response?.data?.message ?? response?.message ?? "Something went wrong, please try again later";
          if (response?.response?.data?.error?.code === 409) {
            this.dyForm.get('email')?.setErrors({ isErrorVisible: true });
            this.dyForm.get('clubName')?.setErrors({ isErrorVisible: true });
            this.dyForm.markAllAsTouched();
          }
        }

      });
  }
}
