<nav id="sidebar" class="sidebar-wrapper sidebar-dark">
    <div class="sidebar-content">
        <div class="logo sidebar-brand">
            <!-- TODO: Replace this image with club image , if not show a placeholder image. -->
            <a routerLink="/"><img src="assets/images/logo-light.png" height="12" alt=""></a>
        </div>
        
        <ul class="sidebar-menu border-t border-white/10" simplebar style="height: calc(100% - 70px);">
            <li class="sidebar-dropdown"  [ngClass]="['/dashboard'].includes(activeManu) ? 'active ms-0' : '' ">
                <a routerLink="/dashboard"><i class="uil uil-chart-line me-2"></i>Dashboard</a>
            </li>
            <li class="sidebar-dropdown"  [ngClass]="['/leagues/index'].includes(activeManu) ? 'active ms-0' : '' ">
                <a routerLink="/leagues/index"><i class="uil uil-browser me-2"></i>Leagues</a>
            </li>
            <li class="sidebar-dropdown"  [ngClass]="['/series/index'].includes(activeManu) ? 'active ms-0' : '' ">
                <a routerLink="/series/index"><i class="uil uil-browser me-2"></i>Series/Tournaments</a>
            </li>
            <li class="sidebar-dropdown"  [ngClass]="['/grounds/index'].includes(activeManu) ? 'active ms-0' : '' ">
                <a routerLink="/grounds/index"><i class="uil uil-browser me-2"></i>Grounds</a>
            </li>
            <li class="sidebar-dropdown"  [ngClass]="['/matches/index'].includes(activeManu) ? 'active ms-0' : '' ">
                <a routerLink="/matches/index"><i class="uil uil-layers me-2"></i>Matches</a>
            </li>
            <li class="sidebar-dropdown"  [ngClass]="['/players/index'].includes(activeManu) ? 'active ms-0' : '' ">
                <a routerLink="/players/index"><i class="uil uil-user me-2"></i>Players</a>
            </li>
            <li class="sidebar-dropdown" [ngClass]="['/noticeboard/nb-index','/noticeboard/gallery'].includes(activeManu) ? 'active ms-0' : '' ">
                <a routerLink="/noticeboard/nb-index" (click)="subManu('/noticeboard/nb-index')"><i class="uil uil-chart-line me-2"></i>Notice Board</a>
                <div class="sidebar-submenu" [ngClass]="['/noticeboard/nb-index','noticeboard/gallery'].includes(manuOpen) ? 'block ms-0' : '' ">
                    <ul>
                        <li [ngClass]="activeManu === '/noticeboard/nb-index' ? 'active ms-0' : ''"><a routerLink="/noticeboard/nb-index">Feed</a></li>
                        <li [ngClass]="activeManu === '/noticeboard/gallery' ? 'active ms-0' : ''"><a routerLink="/noticeboard/gallery">Gallery</a></li>
                    </ul>
                </div>
            </li>
            <li class="sidebar-dropdown"  [ngClass]="['/settings/index'].includes(activeManu) ? 'active ms-0' : '' ">
                <a routerLink="/settings/index"><i class="uil uil-user me-2"></i>Settings</a>
            </li>

            <li class="relative lg:m-8 m-6 px-8 py-10 rounded-lg bg-gradient-to-b to-transparent from-slate-800 text-center">
                <span class="relative z-10">
                    <span class="text-xl font-bold h5 text-white">Upgrade to Pro</span>

                    <span class="text-slate-400 mt-3 mb-5 block">Get one month free and subscribe to pro</span>

                    <a href="" target="_blank" class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gray-500/5 hover:bg-gray-500 border-gray-500/10 hover:border-gray-500 text-white rounded-md">Subscribe</a>
                </span>
            </li>
                 
        </ul>
        <!-- sidebar-menu  -->
    </div>
</nav>
