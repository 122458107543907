import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { SwitcherComponent } from './components/switcher/switcher.component';
import * as feather from 'feather-icons';
import { environment } from '../environments/environment';
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, SwitcherComponent],
  selector: 'cricteams-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(public auth: AuthService, private router: Router) {}

  title = 'crickteams';
  ngAfterViewInit() {
    feather.replace();
  }
  ngOnInit() {
    this.loadRecaptchaScript();
    this.auth.handleRedirectCallback().subscribe({
      next: (result) => {
        const targetRoute = result?.appState?.target || '/';
        this.router.navigate([targetRoute]); // Navigate to the intended route or default to '/'
      }
    });
  }
  private loadRecaptchaScript() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaSiteKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
}
