import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { Match, MatchDetails } from '../models/match/match.model';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class MatchService {
  matchList: BehaviorSubject<Match[]> = new BehaviorSubject<Match[]>([]);

  constructor(private restService: RestService, private auth: AuthService) {}

  public async getClubMatches(clubId: string): Promise<Array<Match> | null> {
    try {
      const apiUrl = '/clubs/matches';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(
        this.auth.getAccessTokenSilently()
      );
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Array<Match>>>(
        `${apiUrl}/${clubId}`
      );
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      return null;
    }
  }

   public async getMatchById(matchId: string): Promise<MatchDetails | null> {
      try {
        const apiUrl = `/matches/${matchId}`;
        const restInstance = this.restService.getRestInstance();
        const token = await firstValueFrom(this.auth.getAccessTokenSilently());
        restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await restInstance.get<ApiResponse<MatchDetails>>(apiUrl);
        return response.data.isSuccess ? response.data.data ?? null : null;
      } catch (error) {
        console.error('Error fetching practice series:', error);
        return null;
      }
    }

  public async saveMatches(data: Record<string, string | number | boolean | unknown>): Promise<unknown> {
    try {
        const apiUrl = '/matches';
        const restInstance = this.restService.getRestInstance();
        const token = await firstValueFrom(this.auth.getAccessTokenSilently());
        restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await restInstance.post<ApiResponse<unknown>>(apiUrl, [{
            clubId: data['clubId'],
            tournamentId: data['tournamentId'],
            groundId: data['groundId'],
            opponentName: data['opponentName'],
            scorecardUrl: data['scorecardUrl'],
            startDateTime: data['startDateTime'],
            vcUserId: data['vcUserId'],
            captainUserId: data['captainUserId'],
            wicketKeeperId: data['wicketKeeperId']
        }]);
        return response.data;
    } catch (error) {
        return error;
    }
  }

  public async updateMatch(matchId: string, data: Record<string, string | number | boolean | unknown>): Promise<unknown>{
    try {
      const apiUrl = `/matches/${matchId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<unknown>>(apiUrl, {
          opponentName: data['opponentName'],
          startDateTime: data['startDateTime'],
          groundId: data['groundId'],
          umpire1Contact: data['umpire1Contact'],
          umpire2Contact: data['umpire2Contact'],
          additionalNotes: data['additionalNotes'],
          umpiringNotes:data['umpiringNotes'],
          drivingInstructions:data['drivingInstructions'],
          result: data['result'],
          scorecardUrl: data['scorecardUrl'],
      });
      return response.data;
    } catch (error) {
        return error;
    }
  }

  public async selectPlayer(data: Record<string, string | number | unknown>[]):  Promise<ApiResponse<string> | null> {
    try{
      const apiUrl = `/matches/select-players`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.post<ApiResponse<string>>(apiUrl, data);
      return response.data ?? response;
    }catch(error){
      return null;
    }
  }


  public async updatePlayerRole(matchId: string,data: Record<string, string | number | boolean | unknown>): Promise<unknown>{
    try {
      const apiUrl = `/matches/update-player-role/${matchId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<unknown>>(apiUrl, data);
      return response.data;
    } catch (error) {
        return error;
    }
  }

  public async createReport(data: Record<string, string | number | boolean | unknown>):  Promise<ApiResponse<string> | null> {
    try{
      const apiUrl = `/matches/create-report`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.post<ApiResponse<string>>(apiUrl, data);
      return response.data.isSuccess ? response.data ?? null : null;
    }catch(error){
      return null;
    }
  }
  public async sendEmailToSelectedPlayers(matchId : string):Promise<ApiResponse<string> | null>{ {
    try{
      const apiUrl = `/matches/notifyPlayingTeam?matchId=${matchId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.post<ApiResponse<string>>(apiUrl);
      return response.data.isSuccess ? response.data ?? null : null;
    }catch(error){
      return null;
      }
    }
  }
}
