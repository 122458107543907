import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldConfig } from '../../../interfaces/dynamic.interface';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';

@Component({
  selector: 'cricteams-dy-time-picker',
  standalone: true,
  imports: [CommonModule, TimePickerModule,ReactiveFormsModule],
  templateUrl: './dy-time-picker.component.html',
  styleUrl: './dy-time-picker.component.scss',
})
export class DyTimePickerComponent implements OnChanges {
  @Input() field!: FieldConfig;
  @Input() group!: FormGroup;
  @Input() form!: FormGroupDirective;
  @Input() labelClass!: string;
  @Input() selectedDate!: Date;
  @Input() isReadOnly = false;
  isTimePickerDisabled = false;
  minTime!: Date | undefined;

    ngOnChanges(changes: SimpleChanges): void {
    const currentDate = new Date();


    if (changes['selectedDate'] && changes['selectedDate'].currentValue) {
      if (this.selectedDate) {
        if (this.selectedDate.toDateString() === currentDate.toDateString()) {
          this.setMinTime();
          this.isTimePickerDisabled = false;
        } else if (this.selectedDate < currentDate) {
          this.isTimePickerDisabled = true;
          this.minTime = undefined;
        } else {
          currentDate.setHours(0, 0, 0, 0);
          this.minTime = currentDate;
          this.isTimePickerDisabled = false;
        }
      }
    }
  }

  preventTyping(event: KeyboardEvent): void {
    event.preventDefault();
  }

   setMinTime(): void {
    const now = new Date();
    const minutes = now.getMinutes();
    if (minutes >= 30) {
      now.setMinutes(0);
      now.setHours(now.getHours() + 1);
    } else {
      now.setMinutes(30);
    }
    this.minTime = now;
  }

  disableTimePicker(): void {
    this.minTime = undefined;
  }
}
