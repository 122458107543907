import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, output, TemplateRef } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';
import { DyDatePickerComponent } from '../../../components/form-fields/dy-date-picker/dy-date-picker.component';
import { DyInputComponent } from '../../../components/form-fields/dy-input/dy-input.component';
import { DyTimePickerComponent } from "../../../components/form-fields/dy-time-picker/dy-time-picker.component";
import { routeUrls } from '../../../constants/constants';
import {
  ComponentTypes,
  eValidatorRegEx,
  eValidators,
  FieldConfig
} from '../../../interfaces/dynamic.interface';
import { Tournament } from '../../../models/club/club-tournament.model';
import { Ground } from '../../../models/ground/ground.model';
import { Match } from '../../../models/match/match.model';
import { AlertService } from '../../../services/alert.service';
import { DynamicService } from '../../../services/dynamic.service';
import { MatchService } from '../../../services/match.service';
import { UserService } from '../../../services/user.service';
import { GroundModalComponent } from '../../grounds/ground-modal/ground-modal.component';
import { TournamentModalComponent } from '../../tournaments/tournament-modal/tournament-modal.component';

@Component({
  selector: 'cricteams-add-match',
  standalone: true,
  imports: [
    CommonModule,
    DyButtonComponent,
    DyInputComponent,
    DyDatePickerComponent,
    ReactiveFormsModule,
    FormsModule,
    TournamentModalComponent,
    GroundModalComponent,
    DyTimePickerComponent
],
  templateUrl: './add-match.component.html',
  styleUrl: './add-match.component.scss',
  providers: [BsModalService],
})
export class AddMatchComponent implements OnInit {
  public cancelBtnClass =
    'text-dark-blue border border-dark-blue text-xs font-semibold px-3 h-[34px] sm:h-[40px] w-full sm:w-[100px] sm:w-[100px] hover:text-white hover:bg-darkBlue transition duration-700 ease-in-out';
  public saveBtnClass =
    'text-white text-xs bg-dark-blue font-semibold px-3 h-[34px] sm:h-[40px] w-full sm:w-[100px]  transition duration-700 ease-in-out';
  public labelClass = 'flex mb-0 text-xs text-light-grey font-medium mb-2';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full p-2 lg:h-[40px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none ';
  public constants = routeUrls;
  public dyForm!: FormGroup;
  public GroundData!: Ground;
  public TournamentData!: Tournament;

  @Input() isModal!: boolean;
  @Input() isEdit!: boolean;
  @Input() selectedMatch!: Match;
  onCloseModal = output<boolean>();
  modalRef!: BsModalRef;
  public minDate = new Date();

  constructor(
    private dynamicService: DynamicService,
    private router: Router,
    private modalService: BsModalService,
    private userService: UserService,
    private matchService: MatchService,
    private alertService: AlertService
  ) {
    this.minDate.setHours(0, 0, 0, 0);
  }

  ngOnInit(): void {
    this.createForm();

    if (this.selectedMatch && this.isEdit) {
      this.dyForm.patchValue({
        groundId: this.selectedMatch.ground.name,
        tournamentId: this.selectedMatch.seriesName,
        startDate: new Date(this.selectedMatch.startDateTime),
        opponent: this.selectedMatch.opponentName,
        scorecardUrl: this.selectedMatch.scorecardUrl,
        startTime: this.getStartTimeValue(this.selectedMatch.startDateTime)
      });
    }
  }

  createForm() {
    this.dyForm = this.dynamicService.createFormControl([
      this.groundId,
      this.tournamentId,
      this.startDate,
      this.opponent,
      this.scorecardUrl,
      this.startTime,
    ]);
  }

  public groundId: FieldConfig = {
    label: 'Ground Name',
    name: 'groundId',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Select ground',
  };
  public tournamentId: FieldConfig = {
    label: 'Tournament Name',
    name: 'tournamentId',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Select tournament',
  };
  public opponent: FieldConfig = {
    label: 'Opponent',
    name: 'opponent',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter opponent',
    validations: [
      { validationType: eValidators.Text },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.AllowRegex,
        message: 'Please enter a valid opponent name',
      },
    ],
  };
  public startDate: FieldConfig = {
    label: 'Date',
    name: 'startDate',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'MM/DD/YYYY',
  };
  public startTime: FieldConfig = {
    label: 'Start Time',
    name: 'startTime',
    componentType: ComponentTypes.Select,
    required: true,
    value: '',
    options: [],
    placeholder:"Select Time"
  };
  public scorecardUrl: FieldConfig = {
    label: 'Scorecard Url',
    name: 'scorecardUrl',
    componentType: ComponentTypes.Text,
    placeholder: 'Enter scorecard url',
    validations: [
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Url,
        message: 'Please enter a valid url',
      },
    ],
  };

  onSubmit() {
    if (this.dyForm.valid) {
      if (this.isEdit) {
        this.onCloseModal.emit(true);
      } else {
        this.saveMatch();
      }
    }
  }

  setStartDateTime() {
    const date = this.dyForm.get('startDate')?.value;
    const time = this.dyForm.get('startTime')?.value;

    const padZero = (num: number) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(time.getHours());
    const minutes = padZero(time.getMinutes());
    const seconds = padZero(time.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  async saveMatch() {
    const clubId = localStorage.getItem('currentClubId') || '';

    if (clubId) {
      const formatedDate = this.setStartDateTime();
      const user = await this.userService.getUserInfoFromLocalStorage();
      const payload = {
        clubId: clubId,
        tournamentId: this.TournamentData.id,
        groundId: this.GroundData.id,
        opponentName: this.dyForm.value['opponent'],
        scorecardUrl: this.dyForm.value['scorecardUrl'] ?? '',
        startDateTime: formatedDate,
        vcUserId: this.TournamentData.vcUser.id ?? user?.user.id,
        captainUserId: this.TournamentData.captainUser.id ??  user?.user.id,
        wicketKeeperId: user?.user.id,
      };

      this.matchService.saveMatches(payload).then((response: any) => {
        if (response?.isSuccess) {
          this.dyForm.setErrors(null);
          this.dyForm.markAsPristine();
          this.dyForm.markAsUntouched();
          this.alertService.showSuccess(response.message);
          this.updateMatchList(clubId);
          this.handleCancel();
          this.router.navigateByUrl(this.constants.LIST_MATCHES);
        } else {
          if (response.error.code === 400) {
            this.alertService.showError(response.error.description);
          } else {
            this.alertService.showError(response.message);
          }
        }
      });
    }
  }

  async updateMatchList(clubId: string) {
    const matches = await this.matchService.getClubMatches(clubId ?? '');
    this.matchService.matchList.next(matches || []);
  }

  public handleCancel() {
    if (this.isModal) {
      this.onCloseModal.emit(true);
    } else {
      this.dyForm.reset();
      this.router.navigateByUrl(this.constants.MATCHES);
    }
  }

  public setGroundValue(ground: Ground) {
    this.GroundData = ground;
    if (ground) {
      this.dyForm.patchValue({
        groundId: ground?.name,
      });
    }
  }

  public setTournamentValue(tournament: Tournament) {
    this.TournamentData = tournament;
    if (tournament) {
      this.dyForm.patchValue({
        tournamentId: tournament?.name,
      });
    }
  }

  public openGroundSelectionModal(template: TemplateRef<HTMLElement>) {
    this.modalRef = this.modalService.show(template);
  }

  public openTournamentSelectionModal(template: TemplateRef<HTMLElement>) {
    this.modalRef = this.modalService.show(template);
  }

  public handleClose(event: boolean) {
    if (event) {
      this.modalRef.hide();
    }
  }

  public handleTournamentClose(event: boolean) {
    if (event) {
      this.modalRef.hide();
    }
  }

  public getStartTimeValue(startDateTime: string) {
    const dateTimeArr = startDateTime.split("T");
    const timeArr = dateTimeArr[1].split(":");
    return `${timeArr[0]}:${timeArr[1]}`;
  }
}
