import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import * as feather from 'feather-icons';
import { filter } from 'rxjs';
import SimpleBar from 'simplebar';
import { routeUrls } from '../../constants/constants';
import { UserService } from '../../services/user.service';
import { AuthService } from '@auth0/auth0-angular';
import { LoadingService } from '../../services/loading.service';
import { TournamentService } from '../../services/tournament.service';


@Component({
  selector: 'cricteams-sidebar',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements AfterViewInit, OnInit {
  constants = routeUrls;
  isManagerOrCaptain!: boolean;
  @Output() sidebarToggle = new EventEmitter<void>();
  public isShowTournamentButton = false;

  constructor(private el: ElementRef, private router: Router, private userService:UserService,private authService: AuthService, private loadingService: LoadingService, private tournamentService: TournamentService) {
    this.userService.isManagerOrCaptain.subscribe((isManagerOrCaptain) => {
      this.isManagerOrCaptain = isManagerOrCaptain;
    });
  }

  ngAfterViewInit() {
    const element = this.el.nativeElement.querySelector('[simplebar]');
    if (element) {
      new SimpleBar(element);
    }
    feather.replace();
  }

  activeManu = '';
  manuOpen = '';

  ngOnInit(): void {
    this.activeManu = this.router.url;
    window.scrollTo(0, 0);
    this.manuOpen = this.activeManu;
    this.openMenu();
    this.userService.isUserManagerOrCaptain();
    this.tournamentService.isShowTournamentButton.subscribe((response) => {
      this.isShowTournamentButton = response;
      });
  }

  isActive(route: string): boolean {
    return this.activeManu.includes(route);
  }

  openMenu() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.activeManu = this.router.url;
        window.scrollTo(0, 0);
        this.manuOpen = this.activeManu;
      });
  }

  toggleSubmenu(item: string): void {
    if (this.manuOpen === item) {
      this.manuOpen = '';
    } else {
      this.manuOpen = item;
    }
  }

  async toggleSideBar() {
    this.authService.getAccessTokenSilently().subscribe((token) => {
    const decodedToken = this.decodeToken(token);

    if (!decodedToken || !decodedToken.exp) {
      this.router.navigate(['/home']);
      return;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    if (decodedToken.exp < currentTime) {
      this.router.navigate(['/home']);
      return;
    }
    });

    if (window.innerWidth <= 1000) {
      this.sidebarToggle.emit();
    }
  }

  decodeToken(token: string): any {
    try {
      const payload = token.split('.')[1];
      const decoded = atob(payload);
      return JSON.parse(decoded);
    } catch (error) {
      return null;
    }
  }
}
