import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { tap } from 'rxjs/operators';
import { routeUrls } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const targetUrl = state.url;
    const { pathname } = window.location;

    return this.auth.isAuthenticated$.pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          if(pathname === '/' || pathname === ''){
            this.router.navigateByUrl('home');
          }else{
            this.authService.loginWithRedirect({
              appState: { target: routeUrls.AUTH_CALLBACK},
            });
            sessionStorage.setItem('targetUrl', targetUrl);
          }
        }
      })
    );
  }
}
