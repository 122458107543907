<div class="page-wrapper" [ngClass]="{ 'toggled': activeSidebar }">
    <!-- sidebar-wrapper -->
    <cricteams-dark-sidebar/>
    <!-- sidebar-wrapper  -->

    <!-- Start Page Content -->
    <main class="page-content bg-gray-50 dark:bg-slate-800">
        <!-- Top Header -->
        <cricteams-top-header (toggleClass)="toggleClass()"/>

        <div class="container-fluid relative px-3">
            <div class="layout-specing">
                <p>Manage Grounds</p>
            </div>
        </div><!--end container-->
        <!-- Top Header -->
        <!-- Footer Start -->
       <cricteams-app-footer/>
        <!-- End -->
    </main>
    <!--End page-content" -->
</div>