import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private sectionLoadingCount = 0;

  public sectionLoadingSubject = new BehaviorSubject<boolean>(false);
  public sectionLoading$ = this.sectionLoadingSubject.asObservable();

  showSection() {
    this.sectionLoadingCount++;
    this.sectionLoadingSubject.next(true);
  }

  hideSection() {
    if (this.sectionLoadingCount > 0) {
      this.sectionLoadingCount--;
    }
    this.sectionLoadingSubject.next(this.sectionLoadingCount > 0);
  }
}
