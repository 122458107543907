import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cricteams-app-back-to-home',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './back-to-home.component.html',
  styleUrls: ['./back-to-home.component.scss']
})
export class BackToHomeComponent {

}
