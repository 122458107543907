import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cricteams-app-footer-one',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent {
  date = 0

  ngOnInit() {
    
    this.date = new Date().getFullYear()
  }
}
