import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Tournament } from '../../../models/club/club-tournament.model';
import { SearchPipe } from '../../../pipes/search.pipe';
import { CommonService } from '../../../services/common.service';
import { TournamentService } from '../../../services/tournament.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'cricteams-tournament-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SearchPipe],
  templateUrl: './tournament-modal.component.html',
  styleUrl: './tournament-modal.component.scss',
})
export class TournamentModalComponent implements OnInit {
  public isClubDropdownOpen = false;
  public isAddDropdownOpen = false;
  public allTournaments: Tournament[]= [];
  public allTournamentsBackup: Tournament[]= [];
  public onTournamentClose = output<boolean>();
  public setTournamentValue = output<Tournament>();
  public tournamentSearchKeyword = '';
  @Input() shouldFilter!: boolean;
  public presentDate: Date = new Date();

  constructor(
    private tournamentService: TournamentService,
    protected commonService: CommonService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.getTournaments();
  }

  onSelectTournament(tournament: Tournament) {
    this.setTournamentValue.emit(tournament);
    this.handleClose();
  }

  toggleClubDropdown() {
    this.isClubDropdownOpen = !this.isClubDropdownOpen;
  }

  toggleAddDropdown() {
    this.isAddDropdownOpen = !this.isAddDropdownOpen;
  }

  handleClose() {
    this.onTournamentClose.emit(true);
  }

  async getTournaments() {
    this.tournamentService
      .getClubTournaments(localStorage.getItem('currentClubId') ?? '')
      .then((response: any) => {
        if (response?.isSuccess) {
          const tournaments = response.data;

          if (tournaments && tournaments.length) {
            this.allTournaments = tournaments.filter(
              (tournament: Tournament) => {
                return new Date(tournament.endDate) > this.presentDate;
              }
            );
            this.allTournamentsBackup = tournaments;
          }
          if (this.shouldFilter) {
            this.filterTournamentsByEndDate();
          }
        } else {
          if (response.error.code === 400) {
            this.alertService.showError(response.error.description);
          } else {
            this.alertService.showError(response.message);
          }
        }
      });
  }

  filterTournamentsByEndDate() {
    const currentDate = new Date();
    this.allTournaments = this.allTournaments.filter((tournament) => {
      const endDate = new Date(tournament.endDate);
      return endDate > currentDate;
    });
  }
}
