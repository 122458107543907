import { Component, Input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig } from '../../../interfaces/dynamic.interface';

@Component({
  selector: 'cricteams-dy-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './dy-select.component.html',
  styleUrl: './dy-select.component.scss',
})
export class DySelectComponent {
  @Input() field!: FieldConfig;
  @Input() group!: FormGroup;
  @Input() form!: FormGroupDirective;
  @Input() selectClass!: string;
  @Input() labelClass!: string;
  @Input() isDisabled = false;
  onSelectChange = output<Event>();

  public handleChange(event: Event){
    this.onSelectChange.emit(event);
  }
}
