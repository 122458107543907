import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, output, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';
import { DyDatePickerComponent } from '../../../components/form-fields/dy-date-picker/dy-date-picker.component';
import { DyInputComponent } from '../../../components/form-fields/dy-input/dy-input.component';
import { DySelectComponent } from '../../../components/form-fields/dy-select/dy-select.component';
import { DyTimePickerComponent } from '../../../components/form-fields/dy-time-picker/dy-time-picker.component';
import { routeUrls } from '../../../constants/constants';
import {
  ComponentTypes,
  eValidatorRegEx,
  eValidators,
  FieldConfig,
} from '../../../interfaces/dynamic.interface';
import { Ground } from '../../../models/ground/ground.model';
import { Practice } from '../../../models/practices/practices.model';
import { AlertService } from '../../../services/alert.service';
import { CommonService } from '../../../services/common.service';
import { PracticeService } from '../../../services/practice.service';
import { GroundModalComponent } from '../../grounds/ground-modal/ground-modal.component';
import { DynamicService } from './../../../services/dynamic.service';
import { DyAvailabilityComponent } from "../../../components/form-fields/dy-availability/dy-availability.component";
import { PlayerService } from '../../../services/player.service';
import { PracticeSeriesConfirmationComponent } from "../practice-series-confirmation/practice-series-confirmation.component";
import { Subscription } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'cricteams-add-practice',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DyInputComponent,
    DySelectComponent,
    DyDatePickerComponent,
    GroundModalComponent,
    DyButtonComponent,
    DyTimePickerComponent,
    DyAvailabilityComponent,
    PracticeSeriesConfirmationComponent
],
  templateUrl: './add-practice.component.html',
  styleUrl: './add-practice.component.scss',
  providers: [BsModalService],
})
export class AddPracticeComponent implements OnInit {

  public labelClass = 'font-montserrat flex font-medium items-center mb-2 text-light-grey text-xs';
  public inputClass ='bg-white rounded border-[1px] border-light-blue w-full p-2 md:h-[40px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public cancelBtnClass ='text-dark-blue border border-dark-blue text-xs font-semibold p-2 xl:py-3 sm:px-5 h-[36px] sm:h-[40px] w-fit hover:text-white hover:bg-darkBlue transition duration-700 ease-in-out';
  public saveBtnClass ='text-white text-xs font-semibold px-5 sm:p-2 md:p-3 h-[36px] sm:h-[40px] md:w-[100px] transition duration-700 ease-in-out bg-light-blue';
  public clearButton ='text-xs font-medium px-3 py-2 rounded-xl text-blue border-[1px] border-light-blue';

  isRecurrence!: boolean;
  public groundData!: Ground;
  public dyForm!: FormGroup;
  public minDate = new Date();
  modalRef!: BsModalRef;
  public practiceForm: FormGroup = new FormGroup({});
  onCloseModal = output<boolean>();
  clubId!: string;
  @Input() isModal!: boolean;
  @Input() isEdit!: boolean;
  @Input() selectedPractice!: Practice;
  today: Date = new Date();
  maxDate: Date;
  minEndDate!: Date;
  maxEndDate!: Date;
  selectedDateSubscription!: Subscription;

  recurringOption = [
    { key: 0, value: 'Sunday', isChecked: false, isDisabled: false },
    { key: 1, value: 'Monday', isChecked: false, isDisabled: false },
    { key: 2, value: 'Tuesday', isChecked: false, isDisabled: false },
    { key: 3, value: 'Wednesday', isChecked: false, isDisabled: false },
    { key: 4, value: 'Thursday', isChecked: false, isDisabled: false },
    { key: 5, value: 'Friday', isChecked: false, isDisabled: false },
    { key: 6, value: 'Saturday', isChecked: false, isDisabled: false },
  ];

  public sessionName: FieldConfig = {
    label: 'Session Name',
    name: 'sessionName',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter session name',
    validations: [
      { validationType: eValidators.Text },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.AllowRegex,
        message: 'Please enter a valid session name',
      },
    ],
  };
  public groundId: FieldConfig = {
    label: 'Ground',
    name: 'groundId',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Select ground',
  };
  public startDate: FieldConfig = {
    label: 'Start Date',
    name: 'startDate',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'MM/DD/YYYY',
  };
  public startTime: FieldConfig = {
    label: 'Start Time',
    name: 'startTime',
    componentType: ComponentTypes.Time,
    required: true,
    value: '',
    placeholder: 'Select Time',
  };
  public endTime: FieldConfig = {
    label: 'End Time',
    name: 'endTime',
    componentType: ComponentTypes.Time,
    required: this.isEdit,
    value: '',
    placeholder: 'Select Time',
  };
  public endDate: FieldConfig = {
    label: 'End Date',
    name: 'endDate',
    componentType: ComponentTypes.Text,
    required: this.isEdit || this.isRecurrence,
    placeholder: 'MM/DD/YYYY',
  };
  public timeDuration: FieldConfig = {
    label: 'Time Duration(in hour)',
    name: 'timeDuration',
    componentType: ComponentTypes.Select,
    value: '',
    required: true,
    placeholder: 'Choose',
    options: [
      { key: '1', value: '1' },
      { key: '2', value: '2' },
      { key: '3', value: '3' },
      { key: '4', value: '4' },
      { key: '5', value: '5' },
    ],
  };

  constructor(
    private modalService: BsModalService,
    private dynamicService: DynamicService,
    private router: Router,
    private practiceService: PracticeService,
    private commonService: CommonService,
    private alertService: AlertService,
    private playerService: PlayerService,
     private route: ActivatedRoute,private authService: AuthService
  ) {
    this.minDate.setHours(0, 0, 0, 0);
    this.clubId = localStorage.getItem('currentClubId') || '';

    this.dyForm = this.dynamicService.createFormControl([
      this.sessionName,
      this.groundId,
      this.startDate,
      this.timeDuration,
      this.endDate,
      this.startTime,
      this.endTime
    ]);

    this.dyForm.addControl('isRecurring', new FormControl(false));
    const currentYear = this.today.getFullYear();
    this.maxDate = new Date(currentYear, 11, 31);
  }

  ngOnInit() {
    this.subscribeToDateChanges();
    if(this.isEdit && this.selectedPractice){
       this.dyForm.patchValue({
        sessionName: this.selectedPractice.sessionName,
        groundId: this.selectedPractice.ground.name,
        startDate: new Date(this.selectedPractice.startDateTime),
        startTime: new Date(this.selectedPractice.startDateTime),
        endTime: new Date(this.selectedPractice.endDateTime),
        endDate: new Date(this.selectedPractice.endDateTime ?? ''),
        timeDuration: this.commonService.calculateDuration(new Date(this.selectedPractice.startDateTime), new Date(this.selectedPractice.endDateTime)),
        isRecurring: this.selectedPractice.isRecurring
      });
      this.setRecurringDays(this.selectedPractice.practiceSeries?.recurringDays);
      this.isRecurrence=true;
    }
  }

  setRecurringDays(recurringDays: number[] | undefined) {
    if (recurringDays) {
      this.dyForm.patchValue({
        isRecurring: true,
      });
      this.recurringOption.map((day) => {
        day.isChecked = recurringDays.includes(day.key);
      });
    }
  }

  openGroundSelectionModal(template: TemplateRef<HTMLElement>) {
    this.modalRef = this.modalService.show(template);
  }

  handleClose(event: boolean) {
    if (event) {
      this.modalRef.hide();
    }
  }

  handleCancel() {
    if(this.isModal){
      this.onCloseModal.emit(true);
    }else{
      this.router.navigateByUrl('/' + routeUrls.PRACTICES);
    }
  }

  onSubmit(template?: TemplateRef<HTMLElement>) {
    if (this.dyForm.valid) {
      if(this.isEdit && template){
        this.modalService.show(template);
      }else{
        this.savePractice();
      }
    }
  }

  savePractice() {
    const recurringDaysInWeek = this.recurringOption.filter(
      (recurring) => recurring.isChecked
    );

    const endDate = this.dyForm.value['endDate'];
    const payload: { [key: string]: string | number | boolean | unknown } = {
      clubId: this.clubId,
      groundId: this.groundData.id,
      sessionName: this.dyForm.value['sessionName'],
      timeDuration: this.dyForm.value['timeDuration'],
      startDate: this.commonService.getFormattedDate(
        this.dyForm.value['startDate']
      ),
      startTime: this.commonService.getFormattedDate(
        this.dyForm.value['startTime']
      ),
    };

    if (this.isRecurrence) {
      if (endDate) {
        payload['endDate'] = this.commonService.getFormattedDate(endDate);
        payload['endTime'] = this.commonService.getFormattedDate(endDate);
      }
      payload['isRecurring'] = this.dyForm.value['isRecurring'];
      payload['recurringDaysInWeek'] = recurringDaysInWeek.map(
        (recurring) => recurring.key
      );
    }

    this.practiceService.savePractice(payload).then((response: any) => {
      if (response?.isSuccess) {
        this.dyForm.setErrors(null);
        this.dyForm.markAsPristine();
        this.dyForm.markAsUntouched();
        this.alertService.showSuccess(response.message);
        this.commonService.updatePracticeList(this.clubId);
        this.onCloseModal.emit(true);
        this.router.navigateByUrl(routeUrls.LIST_PRACTICES);
      } else {
        if (response.error.code === 400) {
          this.alertService.showError(response.error.description);
        } else {
          this.alertService.showError(response.message);
        }
      }
    });
  }

  handleRecurrence() {
    const endDateControl = this.dyForm.controls[this.endDate.name];
    endDateControl.setValidators([
      Validators.required,
    ]);
    this.isRecurrence = !this.isRecurrence;
    if (this.isRecurrence) {
      this.dyForm.get(this.endDate.name)?.reset();
    }
    this.recurringOption.map((reccurence) => {
      reccurence.isChecked = false;
    });
  }

  public setGroundValue(ground: Ground) {
    this.groundData = ground;
    if (ground) {
      this.dyForm.patchValue({
        groundId: ground?.name,
      });
    }
  }

  maxSelected() {
    return (
      this.recurringOption.filter((option) => option.isChecked).length >= 2
    );
  }

  handleRecurrenceChange() {
    const checkedItems = this.recurringOption.filter((item) => item.isChecked);

    if (checkedItems.length > 2) {
      const uncheckedItem = this.recurringOption.find(
        (item) => item.isChecked && !checkedItems.includes(item)
      );
      if (uncheckedItem) {
        uncheckedItem.isChecked = false;
      }
    }
  }

  editPractice() {
    let payload = {};
    const formatedStartDate = this.setStartDateTime();
    const formatedEndDate = this.setEndDate();

    if(!this.selectedPractice.isRecurring){
      payload = {
        groundId: this.groundData.id,
        startTime: formatedStartDate,
        endTime: formatedEndDate,
        isCancelled: false,
      };
    } else{
      const recurringDaysInWeek = this.recurringOption.filter(
        (recurring) => recurring.isChecked
      );
      payload = {
        groundId: this.groundData
          ? this.groundData.id
          : this.selectedPractice.ground.id,
        endDate: formatedEndDate,
        startTime: formatedStartDate,
        endTime: formatedEndDate,
        isCancelled: false,
        timeDuration: this.commonService.calculateDuration(
          new Date(formatedStartDate),
          new Date(formatedEndDate)
        ),
        recurringDaysInWeek: recurringDaysInWeek.map(
          (recurring) => recurring.key
        ),
      };
    }
    (this.selectedPractice.isRecurring ? this.practiceService.updatePracticeSeries(this.selectedPractice.practiceSeriesId, payload) :this.practiceService.updatePractice(
      this.selectedPractice.practiceId,payload)).then((res: any) =>{
        if(res.isSuccess){
          this.commonService.updatePracticeList(this.clubId);
          this.dyForm.setErrors(null)
          this.dyForm.markAsPristine();
          this.dyForm.markAsUntouched();
          this.alertService.showSuccess(res.message);
          this.onCloseModal.emit(true);
          this.router.navigateByUrl(routeUrls.LIST_PRACTICES);
        }
      })
  }

  setStartDateTime() {
    const date = this.dyForm.get('startDate')?.value;
    const time = this.dyForm.get('startTime')?.value;
    if(typeof time === 'string'){
      const timeData = time.split(':');
      date.setHours(timeData[0]);
      date.setMinutes(timeData[1]);
    }else{
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());
    }

    if(date){
      return this.commonService.getFormattedDate(date);
    }
    return '';
  }

  setEndDate() {
    const date = this.dyForm.get('endDate')?.value;
    const time = this.dyForm.get('endTime')?.value;

    if(typeof time === 'string'){
      const timeData = time.split(':');
      date.setHours(timeData[0]);
      date.setMinutes(timeData[1]);
    }else{
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());
    }

    if(date){
      return this.commonService.getFormattedDate(date);
    }

    return '';
  }

  handleClearAll() {
    this.isRecurrence = false;
    this.recurringOption.map((reccurence) => {
      reccurence.isChecked = false;
    });
    this.dyForm.patchValue({
      isRecurring: false
    })
  }

  handlePropagation(event: Event) {
    event.stopPropagation();
  }

  async updateAvailablity(selectValue: string) {
    const clubId = localStorage.getItem('currentClubId') || '';
    if (clubId) {
      const playerDetails = await this.playerService.getPlayer();
      if (playerDetails) {
        const availablity =
          selectValue === 'yes'
            ? 1
            : selectValue === 'no'
            ? 2
            : selectValue === 'mayBe'
            ? 3
            : 0;
        const payload = {
          eventId: this.selectedPractice.practiceId,
          response: availablity,
          eventType: 1,
        };
        try {
          const response = await this.playerService.updatePlayerEvent(
            playerDetails.id ?? '',
            payload
          );
          const message = response?.isSuccess
            ? 'Your response has been updated.'
            : 'Something went wrong';
          if (response?.isSuccess) {
            this.alertService.showSuccess(message);
            this.updatePractice(this.clubId);
          }
        } catch (error) {
          this.alertService.showError('Error updating response.');
        }
      }
    }
  }

  async updatePractice(clubId: string) {
    const practices = await this.practiceService.getPractices(clubId ?? '');
    if (practices) {
      this.selectedPractice =
        practices.find(
          (practice) => practice.practiceId === this.selectedPractice.practiceId
        ) || this.selectedPractice;
    }
  }

  isDisabledSaveButton(){
    if(this.dyForm.valid && !this.isRecurrence){
      return false;
    }
    if(this.dyForm.valid && this.isRecurrence && this.recurringOption.filter((reccurence) => reccurence.isChecked).length === 2){
      return false;
    }
    return true;
  }

  handleWarningConfirmation(event: boolean) {
    if(event){
      this.editPractice();
    }
  }

  handleModalClose() {
    this.modalService.hide();
  }

  subscribeToDateChanges() {
    if (this.selectedDateSubscription) {
      this.selectedDateSubscription.unsubscribe();
    }
    this.selectedDateSubscription = this.dyForm.controls[this.startDate.name].valueChanges.subscribe((date) => {
      if (date) {
        this.setEndDateConstraints(date);
      }
    });
  }

  setEndDateConstraints(date: any) {
    const selectedDateObj = new Date(date);
    const nextYearDate = new Date(selectedDateObj);
    nextYearDate.setFullYear(selectedDateObj.getFullYear() + 1);
    nextYearDate.setDate(nextYearDate.getDate() - 1);
    this.minEndDate = selectedDateObj;
    this.maxEndDate = nextYearDate;
    this.updateEndDateValidators();
  }

  updateEndDateValidators() {
    const endDateControl = this.dyForm.controls[this.endDate.name];
    endDateControl.setValidators([
      this.dateRangeValidator(this.minEndDate, this.maxEndDate),
    ]);
    endDateControl.updateValueAndValidity();
  }

  dateRangeValidator(minDate: Date, maxDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedDate = control.value ? new Date(control.value) : null;
      return selectedDate && (selectedDate < minDate || selectedDate > maxDate)
        ? { dateOutOfRange: true }
        : null;
    };
  }

  ngOnDestroy() {
    this.selectedDateSubscription?.unsubscribe();
  }
}
