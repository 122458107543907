<div class="relative z-[9999] font-montserrat" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
  <div class="p-4 fixed inset-0 z-10 overflow-y-auto flex items-center justify-center">
    <div class="bg-white w-full max-w-md h-auto p-5 rounded">
      <div class="flex items-center justify-end pb-3.5">
        <button type="button" (click)="handleClose()">
          <svg class="size-5" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.46683 22.1666L5.8335 20.5333L12.3668 14L5.8335 7.46665L7.46683 5.83331L14.0002 12.3666L20.5335 5.83331L22.1668 7.46665L15.6335 14L22.1668 20.5333L20.5335 22.1666L14.0002 15.6333L7.46683 22.1666Z"
              fill="#1D1B20" />
          </svg>
        </button>
      </div>
      <div class="text-center">
        <p class="text-base font-semibold text-black">
          @if (isCancel) {
            This will modify all Practice Session in the series. Will you like to Proceed ?
          }
          @if(isWarning) {
            The dates or times of the recurring sessions have been updated. All players who previously polled need to confirm their availability again for the affected sessions.
          }
        </p>
        <div class="my-4 md:my-6">
          <div class="flex justify-center gap-2">
            <cricteams-dy-button type="submit" [text]="this.isCancel ? 'Yes' : 'Proceed' " [class]="yesBtnClass"
              (click)="handleConfirmation()"></cricteams-dy-button>
            <cricteams-dy-button text="No, Keep Session" [class]="noBtnClass"
              (click)="handleClose()"></cricteams-dy-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
