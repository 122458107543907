export const environment = {
    production: false,
    envName: 'staging',
    auth0ClientId: "CGl04lCujOJF8mIo3TvmlezCj1WQU3u5",
    auth0Domain: 'qa-auth.crickteams.com',
    baseUrl: "https://api-qa.crickteams.com/api/v1/",
    auth0ApiAudience: 'https://api.qa.crickteams.com',
    recaptchaSiteKey: '6LdnmEYqAAAAABcGTY4NromOnZxES_sJ48emb7m_',
    syncFusionKey: "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5RdEdhWntWc3BVQmdb",
    firebase :{
      apiKey: "AIzaSyAzOaaNBpzPFBDqWzeYalnMzvwBcrQHWSc",
      authDomain: "ctweb-b6a60.firebaseapp.com",
      projectId: "ctweb-b6a60",
      storageBucket: "ctweb-b6a60.firebasestorage.app",
      messagingSenderId: "280960477365",
      appId: "1:280960477365:web:a5732715611ca7541336fc",
      measurementId: "G-REP9VF6FT5"
    }
  };
