import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { ComponentTypes, FieldConfig } from '../../../interfaces/dynamic.interface';

@Component({
  selector: 'cricteams-dy-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './dy-input.component.html',
  styleUrl: './dy-input.component.scss',
})
export class DyInputComponent {
  @Input() field!: FieldConfig;
  @Input() group!: FormGroup;
  @Input() form!: FormGroupDirective;
  @Input() inputClass!: string;
  @Input() labelClass!: string;
  @Input() isDisabled!: boolean;
  @Input() isReadOnly!: boolean;
  @Input() value!: string | unknown;
  componentTypes = ComponentTypes;
}
