import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cricteams-section-loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section-loading.component.html',
  styleUrl: './section-loading.component.scss',
})
export class SectionLoadingComponent {

}
