import { Route } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { HandleInviteComponent } from './pages/onboarding/handle-invite/handle-invite.component';
import { AuthCallbackComponent } from './auth/auth-callback/auth-callback.component';
import { IndexComponent } from './pages/dashboard/index/index.component';
import { AuthGuard } from './utilities/auth.gaurd';
import { LeagueIndexComponent } from './pages/leagues/league-index.component';
import { GroundIndexComponent } from './pages/grounds/ground-index.component';
import { SeriesIndexComponent } from './pages/series/series-index.component';
import { MatchIndexComponent } from './pages/matches/match-index.component';
import { PlayerIndexComponent } from './pages/players/player-index.component';
import { NbIndexComponent } from './pages/noticeboard/nb-index/nb-index.component';
import { GalleryComponent } from './pages/noticeboard/gallery/gallery.component';
import { SettingsIndexComponent } from './pages/settings/settings-index.component';
import { UserProfileComponent } from './pages/user/user-profile.component';

export const appRoutes: Route[] = [
  { path: '', component: LandingComponent },
  { path: 'handle-invite', component: HandleInviteComponent },
  {path: 'auth-callback', component: AuthCallbackComponent},
  {path:'dashboard',component:IndexComponent,canActivate:[AuthGuard]},
  {path:'leagues/index',component:LeagueIndexComponent,canActivate:[AuthGuard]},
  {path:'grounds/index',component:GroundIndexComponent,canActivate:[AuthGuard]},
  {path:'series/index',component:SeriesIndexComponent,canActivate:[AuthGuard]},
  {path:'matches/index',component:MatchIndexComponent,canActivate:[AuthGuard]},
  {path:'players/index',component:PlayerIndexComponent,canActivate:[AuthGuard]},
  {path:'noticeboard/nb-index',component:NbIndexComponent,canActivate:[AuthGuard]},
  {path:'noticeboard/gallery',component:GalleryComponent,canActivate:[AuthGuard]},
  {path:'settings/index',component:SettingsIndexComponent,canActivate:[AuthGuard]},
  {path:'user/profile',component:UserProfileComponent,canActivate:[AuthGuard]},
  // Other routes...
];
