import { Component, Input, OnInit, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DyInputComponent } from '../form-fields/dy-input/dy-input.component';
import { DyButtonComponent } from '../form-fields/dy-button/dy-button.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DynamicService } from '../../services/dynamic.service';
import {
  ComponentTypes,
  eValidatorRegEx,
  eValidators,
  FieldConfig,
} from '../../interfaces/dynamic.interface';
import { AlertService } from '../../services/alert.service';
import { PlayerService } from '../../services/player.service';
import { EmergencyContacts, PlayerInfo } from '../../models/player/player-request.model';

@Component({
  selector: 'cricteams-emergency-contact-form',
  standalone: true,
  imports: [
    CommonModule,
    DyInputComponent,
    DyButtonComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './emergency-contact-form.component.html',
  styleUrl: './emergency-contact-form.component.scss',
})
export class EmergencyContactFormComponent implements OnInit {
  @Input() playerInfo!: PlayerInfo;
  @Input() emergencyDetails!: EmergencyContacts | null;
  public cancelBtnClass =
    'text-dark-blue border border-dark-blue text-xs font-semibold px-3 h-[34px] sm:h-[40px] w-full sm:w-[100px] sm:w-[100px] hover:text-white hover:bg-darkBlue transition duration-700 ease-in-out';
  public saveBtnClass =
    'text-white text-xs bg-dark-blue font-semibold px-3 h-[34px] sm:h-[40px] w-full sm:w-[100px]  transition duration-700 ease-in-out';
  public labelClass = 'flex mb-0 text-xs text-light-grey font-medium mb-2';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full p-2 lg:h-[40px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none ';
  onCloseModal = output<boolean>();
  public dyForm!: FormGroup;

  public name: FieldConfig = {
    label: 'Name',
    name: 'name',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter Name',
    validations: [
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Text,
        message: 'Please enter a valid name',
      },
    ],
  };

  public phoneNumber: FieldConfig = {
    label: 'Phone Number',
    name: 'phoneNumber',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter Phone Number',
    validations: [
      { validationType: eValidators.Phone },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Phone,
        message: 'Please enter a valid phone Number',
      },
    ],
  };

  public email: FieldConfig = {
    label: 'Email',
    name: 'email',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter Email',
    validations: [
      { validationType: eValidators.Email },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Email,
        message: 'Please enter a valid email address',
      },
    ],
  };

  constructor(private dynamicService: DynamicService,private alertService:AlertService, private playerService: PlayerService) {}

  ngOnInit(): void {
    this.createForm();
    if(this.emergencyDetails){
      this.dyForm.patchValue({
        name: this.emergencyDetails.name,
        phoneNumber: this.emergencyDetails.phoneNumber,
        email: this.emergencyDetails.email,
      });
    }
  }

  createForm(){
    this.dyForm = this.dynamicService.createFormControl([
      this.name,
      this.phoneNumber,
      this.email,
    ]);
  }

  public handleClose() {
    this.onCloseModal.emit(true);
  }

  async onSubmit() {
    try {
      const payload = {
        id: this.emergencyDetails ? this.emergencyDetails.id : null,
        name: this.dyForm.value.name,
        phoneNumber: this.dyForm.value.phoneNumber,
        email: this.dyForm.value.email,
      }
      const response = await this.playerService.updatePlayerEmergencyContact(this.playerInfo.id ?? '', payload);
      const message = response?.isSuccess ? 'Emergency contact updated sucessfully!' : 'Something went wrong';
      if (response?.isSuccess) {
        this.dyForm.setErrors(null);
        this.dyForm.markAsPristine();
        this.dyForm.markAsUntouched();
        this.alertService.showSuccess(message);
        this.onCloseModal.emit(true);
      }
    }catch (error) {
      this.alertService.showError('Error updating details.');
    }
  }
 }

