<div class="grid grid-cols-1 pb-8 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
    <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Key Features</h3>

    <p class="text-slate-400 max-w-xxl mx-auto">Eliminate the hassle of managing chats, polls, and reminders across multiple social or messenger platforms. Forget about juggling Excel sheets or similar software for schedules and player availability. Managing payments and budgets has never been easier. Our platform provides a centralied hub for all your communication needs, track all schedules, payments and budgets, so you can focus on cricket and your team success. </p>
</div><!--end grid-->

<div class="grid md:grid-cols-12 grid-cols-1 gap-[30px] mt-8">
    <div *ngFor="let item of features" class="lg:col-span-4 md:col-span-6 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
        <div class="flex duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div class="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                <i [attr.data-feather]="item.icon" class="size-5 rotate-45"></i>
            </div>
            <div class="flex-1">
                <h4 class="mb-0 text-lg font-medium">{{item.title}}</h4>
            </div>
        </div>
    </div>
</div><!--end grid-->
