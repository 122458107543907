import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'cricteams-app-key-features',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './key-features.component.html',
  styleUrls:[ './key-features.component.scss']
})
export class KeyFeaturesComponent {
    features=[
      {
        icon:'smartphone',
        title:'Mobile First Features'
      },
      {
        icon:'rss',
        title:'Instant Team Updates'
      },
      {
        icon:'bell',
        title:'Automated remimders'
      },
      {
        icon:'message-square',
        title:'Stay Connected'
      },
      {
        icon:'bar-chart-2',
        title:'Player Stats'
      },
      {
        icon:'dollar-sign',
        title:'Manage Finances'
      },
      {
        icon:'check-circle',
        title:'Instant & Templated Polls'
      },
      {
        icon:'monitor',
        title:'Personalized Dashboards'
      },
      {
        icon:'user',
        title:'Simplified Player Onboarding'
      }
    ]
}
