@if (isModal) {
  <div
    class="relative z-[9999] font-montserrat"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 bg-gray-500/75 transition-opacity"
      aria-hidden="true"
    ></div>

    <div
      class="p-4 fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center"
    >
      <ng-container [ngTemplateOutlet]="practiceForm"></ng-container>
    </div>
  </div>
  } @else {
  <ng-container [ngTemplateOutlet]="practiceForm"></ng-container>
  }

  <ng-template #practiceForm>
    <div
      class="bg-white rounded-md border-[1px] border-light-grey p-4 md:p-5 font-montserrat"
      [ngClass]="{ 'w-full max-w-[950px] h-fit': isModal }"
    >
    <div class="flex items-center justify-between w-full border-b border-light-blue pb-3">
      <h4 class="font-semibold text-black text-sm md:text-base">
        {{isEdit ? 'Edit' : 'Add'}} Practice Session
      </h4>
      <img src="../../../../assets/svg/close.svg" width="10px" height="10px" alt="close" class="cursor-pointer" (click)="handleCancel()">
    </div>
      <div class="mt-4 xl:mt-6">
        <form [formGroup]="dyForm" #form="ngForm" (ngSubmit)="onSubmit(confirmationPopup)">
          <div class="grid lg:grid-cols-2 sm:gap-x-0 lg:gap-16 gap-y-4 lg:gap-y-4">
            <div class="lg:col-span-1 relative">
              <cricteams-dy-input
                [labelClass]="labelClass"
                [inputClass]="inputClass"
                [form]="form"
                [field]="sessionName"
                [group]="dyForm"
                [isDisabled]="isEdit"
                [isReadOnly]="isEdit"
              ></cricteams-dy-input>
            </div>
            <div class="lg:col-span-1 relative">
              <cricteams-dy-input
                [labelClass]="labelClass"
                [inputClass]="inputClass"
                [form]="form"
                [field]="groundId"
                [group]="dyForm"
                [isReadOnly]="true"
                (click)="openGroundSelectionModal(groundSelectionModal)"
              ></cricteams-dy-input>
            </div>
            <div class="lg:col-span-1 relative">
              <cricteams-dy-date-picker
                [labelClass]="labelClass"
                [inputClass]="inputClass"
                [form]="form"
                [field]="startDate"
                [minDate]="minDate"
                [group]="dyForm"
                [isToolTip]="false"
              >
              </cricteams-dy-date-picker>
            </div>
            <div class="lg:col-span-1 relative">
              <cricteams-dy-time-picker
                [labelClass]="labelClass"
                [form]="form"
                [field]="startTime"
                [group]="dyForm"
                [selectedDate]="this.dyForm.get('startDate')?.value"
              ></cricteams-dy-time-picker>
            </div>
            <div class="xl:col-span-1">
              @if(isEdit){
                <cricteams-dy-input
                  [labelClass]="labelClass"
                  [inputClass]="inputClass"
                  [form]="form"
                  [field]="timeDuration"
                  [group]="dyForm"
                  [isDisabled]="true"
                  [isReadOnly]="true"
                  [value]="timeDuration.value"
                  ></cricteams-dy-input>
              }@else{
                <cricteams-dy-select
                [labelClass]="labelClass"
                [selectClass]="inputClass"
                [form]="form"
                [field]="timeDuration"
                [group]="dyForm"
                ></cricteams-dy-select>
              }
            </div>
            <div class="xl:col-span-1" *ngIf="isRecurrence || isEdit">
              <div class="relative">
                <cricteams-dy-date-picker
                  [labelClass]="labelClass"
                  [inputClass]="inputClass"
                  [form]="form"
                  [field]="endDate"
                  [group]="dyForm"
                  [isToolTip]="false"
                  [minDate]="this.dyForm.get('startDate')?.value || minDate"
                  [maxDate]="maxEndDate"
                >
                </cricteams-dy-date-picker>
              </div>

            </div>
            @if(isEdit){
                <div class="xl:col-span-1">
                  <div class="relative">
                    <cricteams-dy-time-picker
                    [labelClass]="labelClass"
                    [form]="form"
                    [field]="endTime"
                    [group]="dyForm"
                    [selectedDate]="this.dyForm.get('endDate')?.value"
                    ></cricteams-dy-time-picker>
                  </div>
                </div>
                <div class="xl:col-span-1 flex items-end gap-4">
                  <div class="w-[15%] min-w-[200px] mt-1">
                    <cricteams-dy-availability [selectedValue]="selectedPractice.myResponse ?? 0" (click)="handlePropagation($event)"
                      (onSelect)="updateAvailablity($event)" [isDisabled]="!isRecurrence"></cricteams-dy-availability>
                  </div>
                  <div>
                    <label class="text-xs text-light-grey w-24 sm:w-fit block min-w-16 mb-2">Availability</label>
                    <div class="flex items-center gap-3 ml-2">
                      <div class="flex items-center px-2 py-1 rounded gap-2 bg-dark-green">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.40599 7.46993L0.322266 4.3862L0.940225 3.7681L3.40599 6.23386L8.70431 0.935547L9.32227 1.55365L3.40599 7.46993Z"
                            fill="black" fill-opacity="0.8" />
                        </svg>
                        <span class="font-medium text-xs text-black">{{selectedPractice.playersAttending}}</span>
                      </div>
                      <div class="flex items-center px-2 py-1 rounded gap-2 bg-dark-red">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.25586 5.95055L1.65756 9.54903C1.55932 9.64715 1.43585 9.69739 1.28713 9.69976C1.13853 9.702 1.0128 9.65176 0.909956 9.54903C0.807225 9.44618 0.755859 9.32158 0.755859 9.17523C0.755859 9.02887 0.807225 8.90427 0.909956 8.80142L4.50843 5.20312L0.909956 1.60483C0.811835 1.50659 0.761593 1.38311 0.759229 1.23439C0.756982 1.08579 0.807225 0.96007 0.909956 0.857221C1.0128 0.75449 1.13741 0.703125 1.28376 0.703125C1.43011 0.703125 1.55471 0.75449 1.65756 0.857221L5.25586 4.4557L8.85416 0.857221C8.95239 0.759101 9.07587 0.708859 9.22459 0.706494C9.37319 0.704248 9.49891 0.75449 9.60176 0.857221C9.70449 0.96007 9.75586 1.08467 9.75586 1.23102C9.75586 1.37738 9.70449 1.50198 9.60176 1.60483L6.00329 5.20312L9.60176 8.80142C9.69988 8.89966 9.75013 9.02314 9.75249 9.17186C9.75474 9.32045 9.70449 9.44618 9.60176 9.54903C9.49891 9.65176 9.37431 9.70312 9.22796 9.70312C9.08161 9.70312 8.95701 9.65176 8.85416 9.54903L5.25586 5.95055Z"
                            fill="black" fill-opacity="0.5" />
                        </svg>
                        <span class="font-medium text-xs text-black">{{selectedPractice.playersNotAttending}}</span>
                      </div>
                      <div class="flex items-center px-2 py-1 rounded gap-2 bg-dark-orange">
                        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M4.3025 2.77615C4.3025 2.32133 4.15051 1.95602 3.84653 1.68023C3.54246 1.40453 3.14112 1.26668 2.64251 1.26668C2.32687 1.26668 2.04704 1.33108 1.80301 1.45989C1.55899 1.58879 1.34833 1.78283 1.17105 2.042C1.06657 2.18963 0.926931 2.27685 0.752128 2.30366C0.577416 2.33056 0.424693 2.28786 0.293958 2.17558C0.197283 2.09149 0.142061 1.98609 0.128289 1.85939C0.114518 1.73261 0.143989 1.61234 0.216701 1.49859C0.496349 1.07563 0.842238 0.753975 1.25437 0.533635C1.6664 0.313295 2.12912 0.203125 2.64251 0.203125C3.46245 0.203125 4.12985 0.437925 4.64471 0.907525C5.15948 1.37713 5.41687 1.98871 5.41687 2.74227C5.41687 3.14127 5.33144 3.5069 5.16058 3.83915C4.98964 4.1715 4.69833 4.52905 4.28666 4.91179C3.90106 5.2614 3.63799 5.54513 3.49743 5.763C3.35687 5.98086 3.2753 6.22571 3.25271 6.49755C3.23013 6.65436 3.16517 6.78501 3.05785 6.88948C2.95043 6.99405 2.82117 7.04634 2.67005 7.04634C2.51893 7.04634 2.38971 6.99461 2.28239 6.89114C2.17507 6.78767 2.1214 6.66038 2.1214 6.50926C2.1214 6.14065 2.20559 5.80362 2.37397 5.49817C2.54244 5.19273 2.82443 4.86415 3.21994 4.51243C3.64226 4.14171 3.92824 3.83236 4.07789 3.58438C4.22763 3.3365 4.3025 3.06709 4.3025 2.77615ZM2.64251 10.2031C2.41721 10.2031 2.22317 10.1217 2.0604 9.95896C1.89762 9.79618 1.81623 9.60215 1.81623 9.37685C1.81623 9.15155 1.89762 8.95751 2.0604 8.79474C2.22317 8.63196 2.41721 8.55057 2.64251 8.55057C2.86781 8.55057 3.06184 8.63196 3.22462 8.79474C3.3874 8.95751 3.46878 9.15155 3.46878 9.37685C3.46878 9.60215 3.3874 9.79618 3.22462 9.95896C3.06184 10.1217 2.86781 10.2031 2.64251 10.2031Z"
                            fill="black" fill-opacity="0.5" />
                        </svg>
                        <span class="font-medium text-xs text-black">{{selectedPractice.playersMayBe}}</span>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
          <div class="grid grid-cols-2 gap-0 lg:gap-16 items-end">
            <div class="col-span-2 mb-5 lg:mb-7">
              <div class="grid grid-cols-1 gap-0 lg:gap-16 items-end">
                <div class="col-span-1">
                  <div [ngClass]="isRecurrence ? 'flex items-center gap-3 my-3 pb-3 border-b border-light-blue' : 'flex items-center gap-3 my-3 pb-3 border-b border-transparent'">
                    <input
                      type="checkbox"
                      formControlName="isRecurring"
                      (change)="handleRecurrence()"
                      class="min-w-6 min-h-6 cursor-pointer accent-light-slate"
                    />
                    <label for class="block mb-0 text-xs text-black"
                      >Recurrence Days</label
                    >
                  </div>
                </div>
              </div>
              @if(isRecurrence){
                <div class="mb-3">
                  <label for="" class="text-black text-xs">Recurrence Days</label>
                </div>

                <div class="flex items-center gap-2">
                  <img src="../../../../assets/svg/info.svg" width="20px" height="20px" class="cursor-pointer" alt="info">
                  <p class="text-xs whitespace-nowrap text-[rgba(0, 0, 0, 0.5)] mb-0">You can choose up to two recurring days per week. If you want to
                    select another day, please unselect one of the existing days first.</p>
                </div>

                <div class="grid lg:grid-cols-3 gap-0 lg:gap-16">
                  <div class="xl:col-span-2">
                    <div
                      class="grid grid-cols-2 sm:grid-cols-4 gap-3 my-5"
                    >
                      @for (item of recurringOption; track $index) {
                      <div
                        class="flex items-center gap-2 sm:gap-3 flex-wrap whitespace-nowrap"
                      >
                        <input
                          type="checkbox"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="handleRecurrenceChange()"
                          [(ngModel)]="item.isChecked"
                          class="min-w-5 min-h-5 cursor-pointer accent-light-slate"
                          [disabled]="
                            !isRecurrence || (maxSelected() && !item.isChecked)
                          "
                        />
                        <label for class="block mb-0 text-xs text-black">{{
                          item.value
                        }}</label>
                      </div>
                      }
                    </div>
                  </div>
                </div>

                <cricteams-dy-button [class]="clearButton" text="Clear all" (click)="handleClearAll()"></cricteams-dy-button>
              }
            </div>
          </div>
          <div class="text-right flex justify-end gap-2">
            <cricteams-dy-button
              text="Cancel"
              [class]="cancelBtnClass"
              (click)="handleCancel()"
            ></cricteams-dy-button>
            <cricteams-dy-button
              type="submit"
              text="Save"
              [class]="saveBtnClass"
              [disabled]="isDisabledSaveButton()"
              [conditionalClass]="{
                'bg-light-blue': isDisabledSaveButton(),
                'bg-dark-blue cursor-pointer': !isDisabledSaveButton()
              }"
            ></cricteams-dy-button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #groundSelectionModal>
    <cricteams-ground-modal
      (onGroundClose)="handleClose($event)"
      (setGroundModalValue)="setGroundValue($event)"
    >
    </cricteams-ground-modal>
  </ng-template>

  <ng-template #confirmationPopup>
    <cricteams-practice-series-confirmation (onCloseConfirmation)="handleModalClose()" [isCancel]="false"
      [isWarning]="true"
      (onWarningConfimation)="handleWarningConfirmation($event)"></cricteams-practice-series-confirmation>
  </ng-template>
