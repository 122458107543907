import { CommonModule } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';

@Component({
  selector: 'cricteams-practice-series-confirmation',
  standalone: true,
  imports: [CommonModule, DyButtonComponent],
  templateUrl: './practice-series-confirmation.component.html',
  styleUrl: './practice-series-confirmation.component.scss',
})
export class PracticeSeriesConfirmationComponent {
  public yesBtnClass =
    'text-dark-blue border border-dark-blue text-xs font-semibold px-4 h-[34px] sm:h-[40px] md:h-[42px] w-full hover:text-white hover:bg-darkBlue transition duration-700 ease-in-out';
  public noBtnClass =
    'text-white text-xs bg-dark-blue font-semibold px-3 h-[34px] sm:h-[40px] md:h-[42px] transition duration-700 ease-in-out';
  public onCloseConfirmation = output<boolean>();
  public onCancelConfirmation = output<boolean>();
  public onWarningConfimation = output<boolean>();
  @Input() isCancel!: boolean;
  @Input() isWarning!: boolean;

  handleConfirmation() {
    if(this.isCancel == true){
      this.onCancelConfirmation.emit(true);
    }

    if(this.isWarning == true){
      this.onWarningConfimation.emit(true);
    }

    this.handleClose();
  }

  handleClose() {
    this.onCloseConfirmation.emit(true);
  }
}
