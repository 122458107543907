import { CommonModule,  } from '@angular/common';
import { Component } from '@angular/core';
import { DarkSidebarComponent } from '../../../components/sidebar/dark-sidebar/dark-sidebar.component';
import { TopHeaderComponent } from '../../../components/top-header/top-header.component';
import { FooterComponent } from '../../../components/footer/footer.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cricteams-nb-index',
  standalone: true,
  imports: [CommonModule, DarkSidebarComponent, TopHeaderComponent,FooterComponent, RouterLink],
  templateUrl: './nb-index.component.html',
  styleUrls: ['./nb-index.component.scss']
})
export class NbIndexComponent  {
  
  activeSidebar = true
  

  toggleClass() {
    this.activeSidebar = !this.activeSidebar;
  }

}


