<label [class]="labelClass" class="font-montserrat">{{ field.label }} <span *ngIf="field.required" class="text-red-500">*</span></label>
<div [formGroup]="group" class="font-montserrat" [ngClass]="{ 'i-invalid' : group.get(field.name)?.touched && group.get(field.name)?.invalid }">
    <input [type]="field.componentType" [class]="inputClass" [ngClass]="{ '!bg-gray-200' : group.get(field.name)?.touched && group.get(field.name)?.invalid }" [formControlName]="field.name"
    [placeholder]="field.placeholder ? field.placeholder: ''"
    maxlength="{{ field.maxLength ? field.maxLength : 50 }}" minlength="{{ field.minLength }}" min="{{ field.min }}"
    max="{{ field.max }}" />
</div>
<ng-container *ngFor="let validation of field.validations">
    <p *ngIf="(group.get(field.name)?.hasError(validation.validationType) && group.get(field.name)?.touched) || (form.submitted && form.touched)"
    class="text-red-500 text-xs font-montserrat absolute">
        {{ validation.message }}
    </p>
</ng-container>
