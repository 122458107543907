import axios from 'axios';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  private restInstance;

  constructor() {
    // Create a new Axios instance with the base URL
    this.restInstance = axios.create({
      baseURL: environment.baseUrl, // Replace with your actual base URL
    });

    // Optionally, set default headers, interceptors, etc.
    //this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer your-token'; // Example: Adding authorization headers
  }

  // Expose a method to use the Axios instance
  public getRestInstance() {
    return this.restInstance;
  }
}
