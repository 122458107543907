import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, output, TemplateRef } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';
import { DyInputComponent } from '../../../components/form-fields/dy-input/dy-input.component';
import { routeUrls } from '../../../constants/constants';
import {
  ComponentTypes,
  eValidatorRegEx,
  eValidators,
  FieldConfig,
} from '../../../interfaces/dynamic.interface';
import { DynamicService } from '../../../services/dynamic.service';
import { ClubService } from '../../../services/club.service';
import { AddPlayerRequest } from '../../../models/player/add-player-request.model';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'cricteams-add-player',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DyButtonComponent,
    RouterModule,
    DyInputComponent,
    BsDatepickerModule,
    TooltipModule,
  ],
  templateUrl: './add-player.component.html',
  styleUrl: './add-player.component.scss',
  providers: [BsModalService]
})
export class AddPLayerComponent implements OnInit {
  public cancelBtnClass =
    'text-dark-blue border border-dark-blue text-xs font-semibold px-3 h-[34px] sm:h-[40px] md:w-auto hover:text-white hover:bg-darkBlue transition duration-700 ease-in-out';
  public saveBtnClass =
    'text-white text-xs bg-dark-blue font-semibold px-3 h-[34px] sm:h-[40px] md:w-[100px] transition duration-700 ease-in-out';
  public labelClass = 'block font-montserrat font-medium mb-2.5 text-light-grey text-xs';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full p-2 h-[34px] sm:h-[40px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public constants = routeUrls;
  public dyForm!: FormGroup;
  @Input() isModal!: boolean;
  @Input() isEdit!: boolean;
  onCloseModal = output<boolean>();
  modalRef!: BsModalRef;

  constructor(private dynamicService: DynamicService, private router: Router, private modalService: BsModalService, private clubService: ClubService, private alertService: AlertService) {}

  ngOnInit(): void {
    this.dyForm = this.dynamicService.createFormControl([
      this.firstName,
      this.lastName,
      this.email,
      this.phone,
    ]);
    if (this.dynamicService.dyForm) {
      this.setValue(this.dynamicService.dyForm);
    }
  }

  public firstName: FieldConfig = {
    label: 'First Name',
    name: 'firstName',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter first name',
    validations: [
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Text,
        message: 'Please enter a valid first name',
      },
    ],
  };
  public lastName: FieldConfig = {
    label: 'Last Name',
    name: 'lastName',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter last name',
    validations: [
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Text,
        message: 'Please enter a valid last name',
      },
    ],
  };

  public email: FieldConfig = {
    label: 'Email',
    name: 'email',
    componentType: ComponentTypes.Text,
    required: true,
    validations: [
      { validationType: eValidators.Email },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Email,

        message: 'Please enter a valid email address',
      },
    ],
    placeholder: 'Enter email',
  };

  public phone: FieldConfig = {
    label: 'Phone Number',
    name: 'phone',
    componentType: ComponentTypes.Phone,
    required: false,
    validations: [
      { validationType: eValidators.Phone },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Phone,

        message: 'Please enter valid phone number',
      },
    ],
    placeholder: 'Enter phone number',
  };


  public async onSubmit() {
    if (this.dyForm.valid) {
      const addPlayerRequest: AddPlayerRequest = {
        firstName: this.dyForm.value['firstName'],
        lastName: this.dyForm.value['lastName'],
        email: this.dyForm.value['email'],
        phoneNumber: this.dyForm.value['phone'],
        clubId: localStorage.getItem('currentClubId') ?? ''
      }
      await this.clubService.invitePlayerToClub(addPlayerRequest).then((response : any) => {
        if(response?.isSuccess){
          this.alertService.showSuccess('Successfully invited the player');
          this.dyForm.setErrors(null);
          this.dyForm.markAsPristine();
          this.dyForm.markAsUntouched();
          this.updatePlayerList();
          if(this.isModal)
          {
            this.clubService.refreshPlayerList();
            this.onCloseModal.emit(true);
          }
          else
          {
            this.router.navigateByUrl(this.constants.PLAYERS);
          }
        }else{
          this.alertService.showError(response?.error?.description ?? 'Failed to invite the player');
        }
      });
    } else {
      this.alertService.showError('Failed to invite the player');
    }
  }

  public setValue(dyForm: FormGroup) {
    this.dyForm.setValue({
      firstName: dyForm.value['firstName'],
      lastName: dyForm.value['lastName'],
      email: dyForm.value['email'],
      phone: dyForm.value['phone'],
    });
  }

  handleCancel() {
    if (this.isModal) {
      this.onCloseModal.emit(true);
    } else {
      this.dyForm.reset();
      this.router.navigateByUrl(this.constants.PLAYERS);
    }
  }

  openLeagueSelectionModal(template: TemplateRef<HTMLElement>){
    this.modalRef = this.modalService.show(template);
  }

  handleClose(event: boolean) {
    if(event){
      this.modalRef.hide();
    }
  }

  async updatePlayerList(){
    const players = await this.clubService.getClubPlayers(
      localStorage.getItem('currentClubId') ?? ''
    );
    this.clubService.playerList.next(players || []);
  }
}
