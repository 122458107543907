import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';

interface RecaptchaResponse {
  success: boolean;
  challenge_ts?: string;
  hostname?: string;
  score?: number;
  action?: string;
  'error-codes'?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private siteKey = environment.recaptchaSiteKey;

  private baseUrl = environment.baseUrl;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private restService: RestService) {}

  // Method to get the token
  executeRecaptcha(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(this.siteKey, { action: action })
          .then((token: string) => {
            resolve(token);
          });
        //   .catch((error: any) => {
        //     reject(error);
        //   });
      });
    });
  }

  public async verifyCaptcha(token: string): Promise<boolean> {
    try {
      const restInstance = this.restService.getRestInstance();
      const response = await restInstance.post<RecaptchaResponse>(
        'recaptcha/verify',
        { token }
      );
      return response.data.success;
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
      return false;
    }
  }
}
