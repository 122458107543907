import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { ApiResponse } from '../models/api-response';
import { RestService } from './rest.service';
import { CancelledPractice, Practice } from '../models/practices/practices.model';
import { BehaviorSubject } from 'rxjs';
import { PracticeSeries } from '../models/practices/practices.model';

@Injectable({
  providedIn: 'root',
})
export class PracticeService {
  practiceList: BehaviorSubject<Practice[]> = new BehaviorSubject<Practice[]>([]);

  constructor(private restService: RestService, private auth: AuthService) {}

  public async savePractice(data: Record<string, string | number | boolean | unknown>): Promise<ApiResponse<string> | unknown> {
    try {
      const apiUrl = '/practices';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.post<ApiResponse<string>>(apiUrl, {
        clubId: data['clubId'],
        sessionName: data['sessionName'],
        groundId: data['groundId'],
        timeDuration: data['timeDuration'],
        startDate: data['startDate'],
        endDate: data['endDate'],
        startTime: data['startTime'],
        endTime: data['endTime'],
        isRecurring: data['isRecurring'],
        recurringDaysInWeek: data['recurringDaysInWeek'],
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async getPractices(clubId: string): Promise<Array<Practice> | null> {
    try {
      const apiUrl = 'clubs/practices';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Array<Practice>>>(
        `${apiUrl}/${clubId}`
      );
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      return null;
    }
  }

  public async cancelPracticeSeries(practiceSeriesId: string, data?: Record<string, string>): Promise<unknown> {
    try {
      const apiUrl = '/practices/cancelPracticeSeries';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const url = `${apiUrl}/${practiceSeriesId}`;
      const response = await restInstance.put<ApiResponse<unknown>>(url, {
        cancellationReason: data ? data['cancellationReason'] : ''
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  }

  public async updatePractice(practiceId: string, data: Record<string, string | number | boolean | unknown>): Promise<ApiResponse<string> | unknown> {
    try {
      const apiUrl = `/practices/updatePractice/${practiceId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<string>>(apiUrl, {
        groundId: data['groundId'],
        goals: data['goals'],
        startTime: data['startTime'],
        endTime: data['endTime'],
        isCancelled: data['isCancelled'],
        notes: data['notes'],
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async getPractice(practiceId: string): Promise<Practice | null>{
    try {
      const apiUrl = `/practices/${practiceId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Practice>>(apiUrl);
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      console.error('Error fetching practice series:', error);
      return null;
    }
  }

  public async getPracticeSeries(seriesId: string): Promise<PracticeSeries | null> {
    try {
      const apiUrl = `/practices/series/${seriesId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<PracticeSeries>>(apiUrl);
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      console.error('Error fetching practice series:', error);
      return null;
    }
  }

  public async updatePracticeSeries(seriesId: string, data: Record<string, string | number | boolean | unknown>): Promise<ApiResponse<string> | unknown> {
    try {
      const apiUrl = `/practices/updateSeries/${seriesId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<string>>(apiUrl, {
        groundId: data['groundId'],
        endDate: data['endDate'],
        startTime: data['startTime'],
        timeDuration: data['timeDuration'],
        isCancelled: data['isCancelled'],
        recurringDaysInWeek: data['recurringDaysInWeek'],
        notes: data['notes'],
        goals: data['goals']
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }

  groupAndAdjustPractices(practices: Practice[]): CancelledPractice[] {
    const recurringPractices = practices.filter(practice => practice.isRecurring);
    const nonRecurringPractices = practices.filter(practice => !practice.isRecurring);
    const groupedPractices = new Map<string, any>();

    recurringPractices.forEach(practice => {
      const seriesId = practice.practiceSeriesId;
      if (!groupedPractices.has(seriesId)) {
        groupedPractices.set(seriesId, {
          isRecurring: true,
          practiceSeriesId: seriesId,
          startDateTime: practice.startDateTime,
          endDateTime: practice.endDateTime,
          sessions: []
        });
      }

      const group = groupedPractices.get(seriesId);
      group.startDateTime = this.getSmallestDate(group.startDateTime, practice.startDateTime);
      group.endDateTime = this.getLargestDate(group.endDateTime, practice.endDateTime);
      group.sessions.push({
        practiceId: practice.practiceId,
        isRecurring: practice.isRecurring,
        startDateTime: practice.startDateTime,
        endDateTime: practice.endDateTime,
        ground: practice.ground,
        sessionName: practice.sessionName,
        isCancelled: practice.isCancelled,
        playersAttending: practice.playersAttending,
        playersNotAttending: practice.playersNotAttending,
        playersMayBe: practice.playersMayBe,
        myResponse: practice.myResponse
      });
    });

    const groupedRecurringPractices = Array.from(groupedPractices.values()).map(group => {
      return {
        isRecurring: group.isRecurring,
        practiceSeriesId: group.practiceSeriesId,
        practiceId: group.sessions[0].practiceId,
        startDateTime: group.startDateTime,
        endDateTime: group.endDateTime,
        ground: group.sessions[0].ground,
        sessionName: group.sessions[0].sessionName,
        isCancelled: group.sessions[0].isCancelled,
        playersAttending: group.sessions.reduce(
          (sum: number, session: Practice) => sum + session.playersAttending,
          0
        ),
        playersNotAttending: group.sessions.reduce(
          (sum: number, session: Practice) => sum + session.playersNotAttending,
          0
        ),
        playersMayBe: group.sessions.reduce(
          (sum: number, session: Practice) => sum + session.playersMayBe,
          0
        ),
        myResponse: group.sessions[0].myResponse,
      };
    });

    const nonRecurringPracticesAdjusted = nonRecurringPractices.map(practice => ({
      isRecurring: practice.isRecurring,
      practiceId: practice.practiceId,
      startDateTime: practice.startDateTime,
      endDateTime: practice.endDateTime,
      ground: practice.ground,
      sessionName: practice.sessionName,
      isCancelled: practice.isCancelled,
      playersAttending: practice.playersAttending,
      playersNotAttending: practice.playersNotAttending,
      playersMayBe: practice.playersMayBe,
      myResponse: practice.myResponse
    }));

    return [...groupedRecurringPractices, ...nonRecurringPracticesAdjusted];
  }

  private getSmallestDate(date1: string, date2: string): string {
    return new Date(date1) < new Date(date2) ? date1 : date2;
  }

  private getLargestDate(date1: string, date2: string): string {
    return new Date(date1) > new Date(date2) ? date1 : date2;
  }
}
