import { Component, OnInit, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateService } from '../../services/state.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'cricteams-state-filter',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, OutsideClickDirective],
  templateUrl: './state-filter.component.html',
  styleUrl: './state-filter.component.scss',
})
export class StateFilterComponent implements OnInit {
  public stateList: string[] = [];
  public isClubDropdownOpen = false;
  public stateListBackup: string[] = [];
  public stateSearchControl: FormControl = new FormControl('');
  public selectedState!: string;
  onSelectState = output<string>();
  onResetState = output<string>();

  constructor(private stateService: StateService) {
    this.stateSearchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        this.filterStates(value);
      });
  }

  ngOnInit() {
    this.getStates();
  }

  filterStates(value: string) {
    if (value) {
      this.stateList = this.stateList.filter((state: string) => {
        return state.toLocaleLowerCase().includes(value.toLocaleLowerCase());
      });
    } else {
      this.stateList = this.stateListBackup;
    }
  }

  getStates() {
    this.stateList = this.stateService
      .getStates()
      .filter((state) => state !== 'Select One');
    this.stateListBackup = this.stateList;
  }

  toggleClubDropdown() {
    this.isClubDropdownOpen = !this.isClubDropdownOpen;
  }

  selectState(state: string) {
    this.selectedState = state;
    this.isClubDropdownOpen = !this.isClubDropdownOpen;
    this.onSelectState.emit(this.selectedState);
  }

  resetStateSelection() {
    this.stateSearchControl.setValue('');
    this.selectedState = '';
    this.isClubDropdownOpen = false;
    this.onResetState.emit(this.selectedState);
  }
}
