<div class="flex items-center gap-2 md:gap-3">
  <label for="" class="text-xs text-light-slate font-nunito">Show by :</label>
  <div class="relative">
    <button type="button"
      class="border border-light-blue rounded cursor-pointer h-[36px] sm:h-[38px] min-w-[100px] sm:min-w-[120px] grid-cols-1 bg-white sm:py-1.5 sm:pl-3 px-2 text-left text-black font-semibold text-xs"
      id="menu-button" aria-expanded="false" aria-haspopup="true" (click)="toggleClubDropdown()">
      <span class="col-start-1 row-start-1 flex items-center justify-between gap-1 sm:gap-3">
        <span class="block truncate text-light-grey">Filter by state</span>
        <div class="flex items-center gap-1">
          <svg *ngIf="!isClubDropdownOpen"
            class="col-start-1 row-start-1 self-center justify-self-end text-gray-500 size-3 sm:size-4" width="13"
            height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 10.9639L0.687821 0.463868L12.8122 0.463867L6.75 10.9639Z" fill="#ACC8E5" />
          </svg>

          <svg *ngIf="isClubDropdownOpen"
            class="col-start-1 row-start-1 self-center justify-self-end text-gray-500 size-3 sm:size-4" width="13"
            height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.25 0.963867L0.187821 11.4639L12.3122 11.4639L6.25 0.963867Z" fill="#ACC8E5" />
          </svg>

        </div>
      </span>
    </button>
    <ul [cricteamsOutsideClick]="isClubDropdownOpen" (outSideClick)="isClubDropdownOpen = false"
      class="w-[200px] sm:w-[330px] max-h-[300px] overflow-auto absolute z-10 mt-1 rounded-md bg-white p-3 sm:p-5 text-sm sm:text-base shadow-lg ring-1 ring-black/5 focus:outline-none "
      tabindex="-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"
      *ngIf="isClubDropdownOpen">
      <li class="search-input w-full">
        <input type="text" placeholder="Search state" [formControl]="stateSearchControl"
          class="text-xs text-black py-[4px] h-[34px] pr-3 w-full pl-7 sm:pl-10 border border-light-blue outline-none rounded">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path
              d="M7.33333 13.0002C10.2789 13.0002 12.6667 10.6123 12.6667 7.66683C12.6667 4.72131 10.2789 2.3335 7.33333 2.3335C4.38781 2.3335 2 4.72131 2 7.66683C2 10.6123 4.38781 13.0002 7.33333 13.0002Z"
              stroke="#161C2D" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.9995 14.3335L11.1328 11.4668" stroke="#161C2D" stroke-width="1.33333" stroke-linecap="round"
              stroke-linejoin="round" />
          </g>
        </svg>
      </li>
      <li *ngFor="let state of stateList" (click)="selectState(state)"
        class="cursor-pointer py-3 text-black text-xs border-b border-light-blue" id="listbox-option-0" role="option">
        {{ state }}
      </li>
      <li *ngIf="stateList.length === 0" class="cursor-pointer py-3 text-black text-xs">
        No state found!
      </li>
    </ul>
  </div>
  @if (selectedState) {
  <span class="flex items-center jsutify-center text-base px-3 py-1 rounded-lg text-[#4f46e5] bg-[#f0f7ff]">{{
    selectedState }}
    <svg class="size-4 cursor-pointer" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="resetStateSelection()">
      <path class="fill-[#4f46e5]"
        d="M7.46683 22.1666L5.8335 20.5333L12.3668 14L5.8335 7.46665L7.46683 5.83331L14.0002 12.3666L20.5335 5.83331L22.1668 7.46665L15.6335 14L22.1668 20.5333L20.5335 22.1666L14.0002 15.6333L7.46683 22.1666Z"
        fill="#1D1B20" />
    </svg>
  </span>
  }
</div>
