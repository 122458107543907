<div class="page-wrapper" [ngClass]="{ 'toggled': activeSidebar }">

  <cricteams-sidebar (sidebarToggle)="toggleClass()" />

  <main class="page-content bg-gray-50 dark:bg-slate-800">

    <cricteams-header (toggleClass)="toggleClass()" />

      <div class="container-fluid relative px-3">
        <div class="layout-specing">
          <div class="grid gap-6 font-montserrat grid-cols-3 random" >
            <div class="col-span-3 relative" >
              @if (isSectionLoading) {
                <cricteams-section-loading [ngStyle]="{'background-color' : currentUrl ? '#ffffff' : 'transparent'}"></cricteams-section-loading>
              }
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
     <cricteams-app-footer/>

  </main>

</div>
