import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ErrorComponent } from '../../miscellaneous/error/error.component';
import { OnboardingService } from '../../../services/onboarding.service';

@Component({
  selector: 'cricteams-handle-invite',
  standalone: true,
  imports: [CommonModule, ErrorComponent, RouterLink],
  templateUrl: './handle-invite.component.html',
  styleUrl: './handle-invite.component.scss',
})
export class HandleInviteComponent {
  isErrorVisible = false;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private onboardinService: OnboardingService
  ) {}

  async ngOnInit() {
    const inviteToken = this.route.snapshot.queryParamMap.get('id');
    console.log(inviteToken);
    if (inviteToken == null) {
      this.isErrorVisible = true;
    } else {
      const isValid = await this.onboardinService.validateInvitationAsync(inviteToken);
      if (isValid) {
        this.openAuth0SignUp(inviteToken);
      } else {
        this.isErrorVisible = true;
      }
    }
  }

  openAuth0SignUp(inviteToken: string): void {
    this.auth.loginWithRedirect({
      appState: { target: '/auth-callback', inviteToken: inviteToken },
    });
  }
}
