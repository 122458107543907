@if (isModal) {
<div
  class="relative z-[9999] font-montserrat"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500/75 transition-opacity"
    aria-hidden="true"
  ></div>

  <div
    class="p-4 fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center"
  >
    <ng-container [ngTemplateOutlet]="playerForm"></ng-container>
  </div>
</div>
} @else {
<ng-container [ngTemplateOutlet]="playerForm"></ng-container>
}

<ng-template #playerForm>
  <div
    class="bg-white h-auto p-4 xl:p-5 rounded w-full"
    [ngClass]="{ 'w-full max-w-[800px] h-fit': isModal }"
  >
    <h4 class="font-semibold text-black text-sm md:text-base">
      {{ isEdit ? 'Edit' : 'Add' }} Player
    </h4>
    <div class="mt-4 xl:mt-6">
      <form
        [formGroup]="dyForm"
        #form="ngForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="grid lg:grid-cols-2 gap-0 sm:gap-5">
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="firstName"
              [group]="dyForm"
            ></cricteams-dy-input>
          </div>
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="lastName"
              [group]="dyForm"
            ></cricteams-dy-input>
          </div>

        </div>

        <div class="grid lg:grid-cols-2 lg:gap-5">

          <div class="md:col-span-1 mb-5 lg:mb-6 error-message">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="email"
              [group]="dyForm"
            ></cricteams-dy-input>
          </div>
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="phone"
              [group]="dyForm"
            ></cricteams-dy-input>
          </div>
        </div>

        <div class="text-right flex justify-end gap-2">
          <cricteams-dy-button
            (click)="handleCancel()"
            text="Cancel"
            [class]="cancelBtnClass"
          ></cricteams-dy-button>
          <cricteams-dy-button
            type="submit"
            text="Save"
            [disabled]="dyForm.invalid"
            [class]="saveBtnClass"
            [conditionalClass]="{
              'bg-light-blue': dyForm.invalid,
              'bg-dark-blue cursor-pointer': dyForm.valid
            }"
          ></cricteams-dy-button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
