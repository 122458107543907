import { PlayerEventResponse, PlayerInfo, PlayerResponse } from './../models/player/player-request.model';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { ApiResponse } from '../models/api-response';
import { RestService } from './rest.service';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  loggedinPlayer: BehaviorSubject<PlayerInfo | null> = new BehaviorSubject<PlayerInfo | null>(null);

  constructor(private restService: RestService, private auth: AuthService) {}

  public async getPlayer(): Promise<PlayerInfo | null> {
    try {
      const apiUrl = `/players/info`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<PlayerInfo>>(apiUrl);
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      return null;
    }
  }

  public async getAllPlayers(practiceId: string): Promise<PlayerResponse[] | null> {
    try {
      const apiUrl = `/practices/${practiceId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<PlayerResponse[]>>(apiUrl);
      if (response.data.isSuccess && response.data.data && response.data.data) {
        return response.data.data;
      }
      return null;
    } catch (error) {
      console.error('Error fetching players:', error);
      return null;
    }
  }

  public async updatePlayerEvent(playerId: string, eventData: PlayerEventResponse): Promise<ApiResponse<unknown> | null> {
    try {
      const apiUrl = `/players/events/${playerId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<ApiResponse<unknown>>>(
        apiUrl,
        eventData
      );
      return response.data.isSuccess ? response.data ?? null : null;
    } catch (error) {
      return null;
    }
  }

  public async updatePlayerBasicDetails(playerId: string,data: FormData): Promise<ApiResponse<string> | null> {
    try {
      const apiUrl = `/players/${playerId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<string>>(apiUrl,data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      return response.data.isSuccess ? response.data : null;
    } catch (error) {
      console.error('Error updating player details', error);
      return null;
    }
  }

  public async updatePlayerEmergencyContact(playerId: string, data: Record<string, string | number | unknown>):
   Promise<ApiResponse<string> | null> {
    try{
      const apiUrl = `/players/emergencyContacts/${playerId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<string>>(
        apiUrl,
        [{
          id: data['id'],
          name: data['name'],
          relationship: data['relationship'],
          phoneNumber: data['phoneNumber'],
          email: data['email'],
          address: data['address'],
        }]
      );
      return response.data.isSuccess ? response.data ?? null : null;

    }catch(error){
      return null;
    }
  }

  public async updatePlayerProfileInfo(playerId: string, data: Record<string, string | number | unknown>): Promise<ApiResponse<string> | null> {
    try{
      const apiUrl = `/players/cricketProfile/${playerId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<string>>(apiUrl, {
        battingStyle: data['battingStyle'],
        bowlingStyle: data['bowlingStyle'],
        battingOrder: data['battingOrder'],
        bowlingOrder: data['bowlingOrder'],
        playingRole: data['playingRole'],
        isWicketKeeper: data['isWicketKeeper'],
      });
      return response.data.isSuccess ? response.data ?? null : null;

    }catch(error){
      return null;
    }
  }

  public async updatePlayerPaymentDetails(playerId: string, data: Record<string, string | number | unknown>): Promise<ApiResponse<string> | null> {
    try{
      const apiUrl = `/players/payments/${playerId}`;
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.put<ApiResponse<string>>(apiUrl, {
        userId: data['userId'],
        playerClubPaymentId: data['playerClubPaymentId'],
        amountPaid: data['amountPaid'],
        paymentNotes: data['paymentNotes'],
        paymentDate: data['paymentDate']
      });
      return response.data.isSuccess ? response.data ?? null : null;
    }catch(error){
      return null;
    }
  }
}
