import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../../pages/miscellaneous/error/error.component';
import { ClubService } from '../../services/club.service';
import { first, last } from 'rxjs';

@Component({
  selector: 'cricteams-auth-callback',
  standalone: true,
  imports: [CommonModule, ErrorComponent],
  templateUrl: './auth-callback.component.html',
  styleUrl: './auth-callback.component.scss',
})
export class AuthCallbackComponent {
  isErrorVisible = false;
  userEmail = '';
  firstName = '';
  lastName = '';
  invitationId = '';
  constructor(private auth: AuthService, private clubService: ClubService) {}
  ngOnInit(): void {
    // Wait for Auth0 to handle the callback and then navigate to the home page
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.auth.idTokenClaims$.subscribe((claims) => {
          console.log('Claims:', claims);
        });
        this.auth.user$.subscribe((user) => {
          console.log('User:', user);
        });
        this.auth.getAccessTokenSilently().subscribe((token) => {
          console.log('Access token:', token);
          localStorage.setItem('access_token', token);
        });
      }
    });

    this.auth.appState$.subscribe((state) => {
      if (state && state['inviteToken']) {
        console.log('Invite token:', state['inviteToken']);
        // Handle the invite token as needed
        this.invitationId = state['inviteToken'];
        if (this.invitationId != '') {
          this.auth.user$.subscribe(async (user) => {
            if (user == null) return;
            this.userEmail = user.email!;
            this.firstName = user.given_name!;
            this.lastName = user.family_name!;
            // TODO: Check the type of invitation to complete club registration vs player registration
            await this.completeClubRegistrationAsync();
            this.invitationId = '';
            delete state['inviteToken'];
            window.location.href = '/dashboard';
          });
        }
      }
    });
  }

  private async completeClubRegistrationAsync(): Promise<boolean> {
    try {
      const response = await this.clubService.completeClubRegistrationAsync(
        this.userEmail,
        this.firstName,
        this.lastName,
        this.invitationId
      );
      console.log(response);
      return response;
    } catch (error) {
      console.error('Error submitting contact form:', error);
      return false;
    }
  }
}
