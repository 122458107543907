<label [class]="labelClass" class="font-montserrat">
  {{ field.label }} <span *ngIf="field.required" class="text-red-500">*</span>
  <button *ngIf="isToolTip" type="button" class="btn btn-primary" tooltip="End date must be
  after start date.">
    <svg class="ml-2" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.65234" cy="9.75" r="9" fill="#ACC8E5" />
      <path
        d="M9.12019 11.5141L8.86819 4.80811H10.4502L10.1842 11.5141H9.12019ZM9.66619 14.6921C9.13419 14.6921 8.74219 14.2861 8.74219 13.7961C8.74219 13.2921 9.13419 12.9001 9.66619 12.9001C10.1842 12.9001 10.5622 13.2921 10.5622 13.7961C10.5622 14.2861 10.1842 14.6921 9.66619 14.6921Z"
        fill="white" />
    </svg></button>
</label>

<div [formGroup]="group" class="font-montserrat" [ngClass]="{
    'i-invalid':
      group.get(field.name)?.touched && group.get(field.name)?.invalid
  }">
  <input [type]="field.componentType" [class]="inputClass" [ngClass]="{
      '!bg-gray-200':
        group.get(field.name)?.touched && group.get(field.name)?.invalid
    }" [formControlName]="field.name" [placeholder]="field.placeholder ? field.placeholder : ''"
    [bsConfig]="{showWeekNumbers: false, minDate : minDate, maxDate:maxDate}" #dp="bsDatepicker" bsDatepicker
    [ngClass]="{ 'disabled-input': isDisabled, 'readonly-input': isReadOnly }" [readOnly]="isReadOnly"
    (bsValueChange)="handleChange()" />
</div>

<ng-container *ngFor="let validation of field.validations">
  <p *ngIf="
  (group.get(field.name)?.hasError(validation.validationType) &&
    (group.get(field.name)?.touched || form.submitted))" class="text-red-500 text-xs font-montserrat absolute">
    {{ validation.message }}
  </p>
</ng-container>
