import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ApiResponse } from '../models/api-response';

@Injectable({
    providedIn: 'root'
})
export class ClubService {

    constructor(private restService: RestService) { }

    public async completeClubRegistrationAsync(email:string, firstName:string,lastName:string,invitationId:string): Promise<boolean>{
        try {
            const apiUrl = '/clubs/complete-registration';
            const restInstance = this.restService.getRestInstance();
            const token = localStorage.getItem('access_token');
            restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await restInstance.post<ApiResponse<boolean>>(apiUrl, {email,firstName,lastName,invitationId});
            console.log(response.data);          
            return response.data.isSuccess?response.data.data??false:false;
        } catch (error) {
            console.error('Error submitting contact form:', error);
            return false;
        }
    }
}