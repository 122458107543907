import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  standalone: true,
})
export class SearchPipe implements PipeTransform {
  transform<T>(items: T[], searchTerm: string, ...keys: string[]): T[] {
    if (!items || !searchTerm) return items;

    const searchWords = searchTerm.toLowerCase().trim().split(/\s+/);

    return items.filter((item) =>
      searchWords.every((word) =>
        keys.some((key) => this.getValueByKey(item, key).includes(word))
      )
    );
  }

  private getValueByKey(item: any, key: string): string {
    const value = key
      .split('.')
      .reduce((obj, keyPart) => obj?.[keyPart] ?? '', item);

    return value ? value.toString().toLowerCase(): '';
  }
}
