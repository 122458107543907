import { CommonModule } from '@angular/common';
import { Component, OnInit, output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {
  ComponentTypes,
  eValidatorRegEx,
  eValidators,
  FieldConfig,
} from '../../interfaces/dynamic.interface';
import {
  EmergencyContacts,
  PlayerInfo,
} from '../../models/player/player-request.model';
import { AlertService } from '../../services/alert.service';
import { DynamicService } from '../../services/dynamic.service';
import { PlayerService } from '../../services/player.service';
import { EmergencyContactFormComponent } from '../emergency-contact-form/emergency-contact-form.component';
import { DyButtonComponent } from '../form-fields/dy-button/dy-button.component';
import { DyDatePickerComponent } from '../form-fields/dy-date-picker/dy-date-picker.component';
import { DyInputComponent } from '../form-fields/dy-input/dy-input.component';
import { DySelectComponent } from '../form-fields/dy-select/dy-select.component';
import { UserService } from '../../services/user.service';
import { UserBaseInfo } from '../../models/user/user-base-info.model';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'cricteams-account',
  standalone: true,
  imports: [
    CommonModule,
    TabsModule,
    DyButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    DyInputComponent,
    DySelectComponent,
    DyDatePickerComponent,
    EmergencyContactFormComponent,
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
  providers: [BsModalService],
})
export class AccountComponent implements OnInit {
  public editBtnClass =
    'border-[1px] border-light-blue h-[34px] sm:h-[40px] px-5 text-dark-blue text-xs';
  public saveAndCloseBtnClass =
    'max-w-fit text-white text-xs h-[34px] font-semibold bg-dark-blue ease-in-out px-5 w-full';
  public saveBtnClass =
    'max-w-fit text-white text-xs h-[34px] sm:h-[40px] font-semibold bg-dark-blue transition duration-700 ease-in-out px-5 w-full';
  public labelClass =
    'font-montserrat flex font-medium items-center text-light-grey text-xs';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full p-2 md:h-[40px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public selectlLabelClass =
    'hidden mb-2.5 text-sm text-light-grey font-medium';
  public inputAccountClass =
    'border-[1px] border-light-blue text-xs text-black px-3 w-full rounded outline-none h-[34px]';
  public okayBtnClass =
  'max-w-fit text-white text-xs h-[34px] sm:h-[40px] font-semibold transition duration-700 ease-in-out px-5 w-full bg-dark-blue cursor-pointer';
  public aboutForm!: FormGroup;
  public playerProfileInfo!: FormGroup;
  public notificationForm!: FormGroup;
  public isPracticeBoth = false;
  public isMatchBoth = false;
  public isRosterBoth = false;

  profileFile: File | null = null;
  onCloseModal = output<boolean>();
  changeEvent = output<boolean>();
  modalRef!: BsModalRef;
  emergencyDetails!: EmergencyContacts | null;
  isFormEdited = false;
  playerProfileInfoEdited = false;
  userInfo!: UserBaseInfo;
  @ViewChild('form') form!: NgForm;

  playerInfo: PlayerInfo = {
    id: '',
    isMVP: false,
    profilePicture: '',
    emergencyContacts: [],
    user: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      id: '',
      fullName: '',
      isActive: false,
      updatedAt: '',
      createdAt: '',
    },
    gender: '',
    dateOfBirth: null,
    phoneNumber: '',
    sasUri: '',
    playerClubPayments: [],
    playerEventResponse: []
  };
  public isEdit = false;
  public paymentNotes!: string;

  public firstName: FieldConfig = {
    label: 'First name',
    name: 'firstName',
    componentType: ComponentTypes.Text,
    placeholder: 'First Name',
    required: true,
    validations: [
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Text,
        message: 'Please enter a valid first name',
      },
    ],
  };

  public lastName: FieldConfig = {
    label: 'Last name',
    name: 'lastName',
    componentType: ComponentTypes.Text,
    placeholder: 'Last Name',
    required: true,
    validations: [
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Text,
        message: 'Please enter a valid last name',
      },
    ],
  };

  public gender: FieldConfig = {
    label: 'Gender',
    name: 'gender',
    componentType: ComponentTypes.Select,
    value: '',
    options: [
      {
        key: 'male',
        value: 'Male',
      },
      {
        key: 'female',
        value: 'Female',
      },
    ],
    placeholder: 'Select Gender',
    required: true,
  };

  public dateOfBirth: FieldConfig = {
    label: 'Date of Birth',
    name: 'dateOfBirth',
    componentType: ComponentTypes.Text,
    placeholder: 'MM/DD/YYYY',
    value: '',
    required: true,
  };

  public phoneNumber: FieldConfig = {
    label: 'Mobile number',
    name: 'phoneNumber',
    componentType: ComponentTypes.Text,
    placeholder: 'Phone Number',
    required: true,
    validations: [
      { validationType: eValidators.Phone },
      {
        validationType: eValidators.Pattern,
        validationPattern: eValidatorRegEx.Phone,
        message: 'Please enter a valid phone Number',
      },
    ],
  };

  public profilePicture: FieldConfig = {
    label: 'Profile Picture',
    name: 'profilePicture',
    componentType: ComponentTypes.Text,
    placeholder: 'Profile Picture',
  };

  public bowlingStyle: FieldConfig = {
    label: '',
    name: 'bowlingStyle',
    componentType: ComponentTypes.Select,
    value: '',
    placeholder: 'Select bowling style',
    options: [
      { key: 2, value: 'Right Arm Medium' },
      { key: 1, value: 'Right Arm Fast' },
      { key: 3, value: 'Right Arm Off Spin' },
      { key: 4, value: 'Right Arm Leg Spin' },
      { key: 6, value: 'Left Arm Medium' },
      { key: 5, value: 'Left Arm Fast' },
      { key: 7, value: 'Left Arm Off Spin' },
      { key: 8, value: 'Left Arm Leg Spin' },
    ],
  };
  public playingRole: FieldConfig = {
    label: '',
    name: 'playingRole',
    componentType: ComponentTypes.Select,
    value: '',
    placeholder: 'Select playing role',
    options: [
      { key: 1, value: 'Batter' },
      { key: 2, value: 'Bowler' },
      { key: 3, value: 'All Rounder' },
    ],
  };
  public battingStyle: FieldConfig = {
    label: '',
    name: 'battingStyle',
    componentType: ComponentTypes.Select,
    value: '',
    placeholder: 'Select batting style',
    options: [
      { key: 1, value: 'Right Handed Batsman' },
      { key: 2, value: 'Left Handed Batsman' },
    ],
  };

  public practiceRemainderOption = {
    type: 2,
    options: [
      {
        key: 'Email',
        value: 1,
      },
      {
        key: 'Mobile',
        value: 2,
      },
      {
        key: 'Both',
        value: 4,
      },
    ],
  };

  public matchRemainderOption = {
    type: 1,
    options: [
      {
        key: 'Email',
        value: 1,
      },
      {
        key: 'Mobile',
        value: 2,
      },
      {
        key: 'Both',
        value: 4,
      },
    ],
  };

  public rosterRemainderOption = {
    type: 3,
    options: [
      {
        key: 'Email',
        value: 1,
      },
      {
        key: 'Mobile',
        value: 3,
      },
      {
        key: 'Both',
        value: 4,
      },
    ],
  };

  constructor(
    private dynamicService: DynamicService,
    private playerService: PlayerService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private userService: UserService,
    private fb: FormBuilder,
    protected commonService: CommonService
  ) {
    this.createAboutYouForm();
    this.createCricketProfileForm();
    this.createNotificationForm();
  }

  createAboutYouForm() {
    this.aboutForm = this.dynamicService.createFormControl([
      this.firstName,
      this.lastName,
      this.gender,
      this.dateOfBirth,
      this.phoneNumber,
      this.profilePicture,
    ]);
  }

  createCricketProfileForm() {
    this.playerProfileInfo = this.dynamicService.createFormControl([
      this.bowlingStyle,
      this.playingRole,
      this.battingStyle,
    ]);
    this.playerProfileInfo.addControl('wicketKeeper', new FormControl());
  }

  createNotificationForm(){
    this.notificationForm = this.fb.group({
      practiceEmail: this.createNotificationInnerForm(2),
      practiceMobile: this.createNotificationInnerForm(2),
      matchEmail: this.createNotificationInnerForm(1),
      matchMobile: this.createNotificationInnerForm(1),
      rosterEmail: this.createNotificationInnerForm(3),
      rosterMobile: this.createNotificationInnerForm(3)
    });
  }

  createNotificationInnerForm(type: number){
    return this.fb.group({
      id: '',
      notificationType: type,
      notificationChannelType: 0,
      isEnabled: false,
      isChecked: false
    });
  }

  ngOnInit(): void {
    this.getPlayerDetails();
    this.getNotificationDetails();
  }

  public handleClose() {
    this.onCloseModal.emit(true);
  }

  public onEditClick(): void {
    this.isEdit = !this.isEdit;
  }

  public async onSubmit() {
    const formData = new FormData();
    formData.append('FirstName', this.aboutForm.value.firstName);
    formData.append('LastName', this.aboutForm.value.lastName);
    formData.append('PhoneNumber', this.aboutForm.value.phoneNumber);
    formData.append('Gender', this.aboutForm.value.gender);
    formData.append('DateOfBirth', this.aboutForm.value.dateOfBirth.toISOString());
    formData.append('ProfilePicture', this.aboutForm.value.profilePicture);

    if (this.profileFile) {
      formData.append('profileImage', this.profileFile, this.profileFile.name);
    }
    if(this.aboutForm.valid){
      try {
        const response = await this.playerService.updatePlayerBasicDetails(this.playerInfo.id ?? '',formData);
        const message = response?.isSuccess? 'Details updated sucessfully!': 'Something went wrong';
        if (response?.isSuccess) {
          this.userService.profileURIChanged.next(true);
          this.aboutForm.setErrors(null);
          this.aboutForm.markAsPristine();
          this.aboutForm.markAsUntouched();
          this.alertService.showSuccess(message);
          this.onCloseModal.emit(true);
        }
      } catch (error) {
        this.alertService.showError('Error updating details.');
      }
    }else{
      this.alertService.showError("Please enter all required fields or required fields.");
    }
  }

  handleContactClose(event: boolean) {
    if (event) {
      this.modalRef.hide();
      this.getPlayerDetails();
    }
  }

  handleModalClose() {
    this.modalService.hide();
  }

  openEmergencyPopup(
    template: TemplateRef<HTMLElement>,
    emergencyDetails?: EmergencyContacts
  ) {
    this.modalRef = this.modalService.show(template);
    if (emergencyDetails) {
      this.emergencyDetails = emergencyDetails;
    } else {
      this.emergencyDetails = null;
    }
  }

  getPlayerDetails() {
    this.playerService.getPlayer().then((playerInfo: PlayerInfo | null) => {
      if (playerInfo) {
        this.playerInfo = playerInfo;
        this.aboutForm.patchValue({
          firstName: playerInfo.user.firstName,
          lastName: playerInfo.user.lastName,
          gender: playerInfo.gender,
          dateOfBirth: playerInfo.dateOfBirth
            ? new Date(playerInfo.dateOfBirth)
            : null,
          email: playerInfo.user.email,
          phoneNumber: playerInfo.user.phoneNumber,
          profilePicture: playerInfo.sasUri,
        });

        if (this.aboutForm.touched) {
          this.isFormEdited = true;
        } else {
          this.isFormEdited = false;
        }

        this.patchData();
      }
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input && input.files) {
      const file: File = input.files[0];
      const MAX_SIZE = 5 * 1024 * 1024;
      if (file.size > MAX_SIZE) {
        this.alertService.showError('File is too large. Please upload a file smaller than 5MB.');
        input.value = '';
        return;
      }
        const reader = new FileReader();

        reader.onload = () => {
          const base64File = reader.result as string;
          this.aboutForm.patchValue({
            profilePicture: base64File,
          });
          this.playerInfo.profilePicture = base64File;
        };

        reader.readAsDataURL(file);
      this.profileFile = file;
    }
  }

  handleChange(value: string) {
    if (value == 'aboutForm') {
      this.isFormEdited = true;
    }
    if (value == 'playerProfileInfo') {
      this.playerProfileInfoEdited = true;
    }
  }

  handleDateChange() {
    if (this.aboutForm.touched) {
      this.isFormEdited = true;
    }
  }

  patchData() {
    this.playerProfileInfo.patchValue({
      battingStyle: this.playerInfo.cricketProfile?.battingStyle ?? '',
      bowlingStyle: this.playerInfo.cricketProfile?.bowlingStyle ?? '',
      playingRole: this.playerInfo.cricketProfile?.playingRole ?? '',
      wicketKeeper: this.playerInfo.cricketProfile?.isWicketKeeper ?? false,
    });
  }

  public async onPlayerProfileSubmit() {
    try {
      const payload = {
        battingStyle:
          Number(this.playerProfileInfo.get('battingStyle')?.value) ?? 0,
        bowlingStyle:
          Number(this.playerProfileInfo.get('bowlingStyle')?.value) ?? 0,
        battingOrder: 0,
        bowlingOrder: 0,
        playingRole:
          Number(this.playerProfileInfo.get('playingRole')?.value) ?? 0,
        isWicketKeeper:
          this.playerProfileInfo.get('wicketKeeper')?.value ?? false,
      };
      const response = await this.playerService.updatePlayerProfileInfo(
        this.playerInfo.id ?? '',
        payload
      );
      const message = response?.isSuccess
        ? 'Player Cricket Profile updated successfully.'
        : 'Something went wrong';
      if (response?.isSuccess) {
        this.alertService.showSuccess(message);
        this.aboutForm.reset();
        this.onCloseModal.emit(true);
      }
    } catch (error) {
      this.alertService.showError('Error updating details.');
    }
  }

  public async getNotificationDetails() {
    try {
      const userInfo = await this.userService.getUserInfo();
      if (userInfo) {
        this.userInfo = userInfo;
        if (userInfo.notificationPreferences?.length) {
          this.notificationForm.reset();
          const notificationDetails = this.userInfo.notificationPreferences;

          this.updateNotificationForm(notificationDetails, 2, 'practiceEmail', 'practiceMobile');
          this.updateNotificationForm(notificationDetails, 1, 'matchEmail', 'matchMobile');
          this.updateNotificationForm(notificationDetails, 3, 'rosterEmail', 'rosterMobile');
        }
      }
    } catch (error) {
      console.error('Error fetching notification details:', error);
    }
  }

  private updateNotificationForm(notificationDetails: any[], type: number, emailControl: string, mobileControl: string) {
    const notifications = notificationDetails.filter(item => item.notificationType === type);
    let emailEnabled = false;
    let mobileEnabled = false;

    notifications.forEach(item => {
      if (item.notificationChannelType === 1) {
        this.notificationForm.get(emailControl)?.patchValue({
          id: item.id,
          notificationChannelType: item.notificationChannelType,
          notificationType: type,
          isEnabled: item.isEnabled,
          isChecked: item.isEnabled
        });
        emailEnabled = item.isEnabled;
      } else if (item.notificationChannelType === 3) {
        this.notificationForm.get(mobileControl)?.patchValue({
          id: item.id,
          notificationChannelType: item.notificationChannelType,
          notificationType: type,
          isEnabled: item.isEnabled,
          isChecked: item.isEnabled
        });
        mobileEnabled = item.isEnabled;
      }
    });

    const isBothEnabled = emailEnabled && mobileEnabled;
    if (isBothEnabled) {
      this.notificationForm.get(emailControl)?.patchValue({ isChecked: false });
      this.notificationForm.get(mobileControl)?.patchValue({ isChecked: false });
    }

    switch (type) {
      case 2:
        this.isPracticeBoth = isBothEnabled;
        break;
      case 1:
        this.isMatchBoth = isBothEnabled;
        break;
      case 3:
        this.isRosterBoth = isBothEnabled;
        break;
    }
  }

  public getNotificationType(controlName: string) {
    switch (controlName) {
      case 'practiceRemainder':
        return this.practiceRemainderOption.type;

      case 'matchRemainder':
        return this.matchRemainderOption.type;

      case 'rosterRemainder':
        return this.rosterRemainderOption.type;

      default:
        return 0;
    }
  }

  public async setDefaultNotificationPreference() {
    const payloadData = [
      { notificationType: 1, notificationChannelType: 4, isEnabled: true },
      { notificationType: 2, notificationChannelType: 4, isEnabled: true },
      { notificationType: 3, notificationChannelType: 4, isEnabled: true },
    ];

    const updatePromises = payloadData.map((payload) => {
      return this.userService
        .postNotificationDetails(payload)
        .then((response: any) => {
          if (!response?.isSuccess) {
            throw new Error(
              response.error?.code === 400
                ? response.error.description
                : response.message
            );
          }
        });
    });

    try {
      await Promise.all(updatePromises).then(() => {
        this.getNotificationDetails();
      });
    } catch (error) {
      console.error('Error updating notification preferences:', error);
    }
  }

  async handleNotificationChange(groupName: string){
    let emailControl, mobileControl;

    switch (groupName) {
      case 'practiceEmail':
      case 'practiceMobile':
      emailControl = 'practiceEmail';
      mobileControl = 'practiceMobile';
      break;
      case 'matchEmail':
      case 'matchMobile':
      emailControl = 'matchEmail';
      mobileControl = 'matchMobile';
      break;
      case 'rosterEmail':
      case 'rosterMobile':
      emailControl = 'rosterEmail';
      mobileControl = 'rosterMobile';
      break;
      default:
      return;
    }

    if (groupName === emailControl) {
      this.notificationForm.get(emailControl)?.patchValue({ isChecked: true, isEnabled: true });
      this.notificationForm.get(mobileControl)?.patchValue({ isChecked: false, isEnabled: false });
    } else {
      this.notificationForm.get(emailControl)?.patchValue({ isChecked: false, isEnabled: false });
      this.notificationForm.get(mobileControl)?.patchValue({ isChecked: true, isEnabled: true });
    }

    const formValue = [
      { ...this.notificationForm.get(emailControl)?.value },
      { ...this.notificationForm.get(mobileControl)?.value }
    ];

    const payload = {
      notificationPreferences: formValue.map(item => ({
        id: item.id,
        notificationType: item.notificationType,
        notificationChannelType: item.notificationChannelType,
        isEnabled: item.isEnabled,
      }))
    }

    if(payload){
      try {
        await this.userService
          .updateNotificationDetails(payload)
          .then((response: any) => {
            if (response?.isSuccess) {
              let msg;

              switch (response.data.notificationChannelType) {
                case 1:
                  msg = "Notification preference updated to 'Email' only.";
                  break;
                case 2:
                  msg = "Notification preference updated to 'Mobile' only.";
                  break;
                case 3:
                  msg = "Notification preference updated to 'Push' only.";
                  break;
                case 4:
                  msg = "Notification preference updated to 'Both'." ;
                  break;
                default:
                  msg = response.message;
                  break;
              }

              this.alertService.showSuccess(msg);
              this.getNotificationDetails();
            } else {
              if (response.error?.code === 400) {
                this.alertService.showError(response.error.description);
              } else {
                this.alertService.showError(response.message);
              }
            }
          })
          .catch(() => {
            this.alertService.showError('An unexpected error occurred');
          });
      } catch (error) {
        this.alertService.showError('Error updating response.');
      }
    }
  }

  async handleBoth(type: 'practice' | 'match' | 'roster'){
    const formControls = {
      practice: ['practiceEmail', 'practiceMobile'],
      match: ['matchEmail', 'matchMobile'],
      roster: ['rosterEmail', 'rosterMobile'],
    };

    const controls = formControls[type];
    if (!controls) return;

    const key = `is${type.charAt(0).toUpperCase() + type.slice(1)}Both` as 'isPracticeBoth' | 'isMatchBoth' | 'isRosterBoth';
    this[key] = true;
    controls.forEach(control => {
      this.notificationForm.get(control)?.patchValue({ isChecked: false, isEnabled: true });
    });

    const formValue = controls.map(control => ({ ...this.notificationForm.get(control)?.value }));

    const payload = {
      notificationPreferences: formValue.map(item => ({
        id: item.id,
        notificationType: item.notificationType,
        notificationChannelType: item.notificationChannelType,
        isEnabled: item.isEnabled,
      }))
    };

    if(payload){
      try {
        await this.userService
          .updateNotificationDetails(payload)
          .then((response: any) => {
            if (response?.isSuccess) {
              let msg;

              switch (response.data.notificationChannelType) {
                case 1:
                  msg = "Notification preference updated to 'Email' only.";
                  break;
                case 2:
                  msg = "Notification preference updated to 'Mobile' only.";
                  break;
                case 3:
                  msg = "Notification preference updated to 'Push' only.";
                  break;
                case 4:
                  msg = "Notification preference updated to 'Both'." ;
                  break;
                default:
                  msg = response.message;
                  break;
              }

              this.alertService.showSuccess(msg);
              this.getNotificationDetails();
            } else {
              if (response.error?.code === 400) {
                this.alertService.showError(response.error.description);
              } else {
                this.alertService.showError(response.message);
              }
            }
          })
          .catch(() => {
            this.alertService.showError('An unexpected error occurred');
          });
      } catch (error) {
        this.alertService.showError('Error updating response.');
      }
    }
  }

  mapFeeType(feeType: number): string {
    switch (feeType) {
      case 0:
        return 'Club Member Ship';
      case 1:
        return 'Tournament Fee';
      case 2:
        return 'One Time Fee';
      default:
        return '';
    }
  }

  openModal(template: TemplateRef<HTMLElement>, paymentNotes?: string) {
    this.modalRef = this.modalService.show(template);
    this.paymentNotes = paymentNotes ?? '';
  }
}
