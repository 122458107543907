import { CommonModule } from '@angular/common';
import { Component, OnInit, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ApiResponse } from '../../../models/api-response';
import { Ground } from '../../../models/ground/ground.model';
import { CommonService } from '../../../services/common.service';
import { GroundService } from '../../../services/ground.service';
import { StateFilterComponent } from '../../../components/state-filter/state-filter.component';

@Component({
  selector: 'cricteams-ground-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, StateFilterComponent],
  templateUrl: './ground-modal.component.html',
  styleUrl: './ground-modal.component.scss',
})
export class GroundModalComponent implements OnInit {
  public isAddDropdownOpen = false;
  public allGrounds: Ground[] = [];
  public allGroundsBackup: Ground[] = [];
  public myGrounds: Ground[] = [];
  public onGroundClose = output<boolean>();
  public groundSearchControl: FormControl = new FormControl('');
  public setGroundModalValue = output<Ground>();

  constructor(
    private groundService: GroundService,
    protected commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getGrounds();

    this.groundSearchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        this.filterGrounds(value);
      });
  }

  setAllGrounds() {
    if (this.myGrounds && this.allGrounds) {
      const remainingGrounds = this.allGrounds.filter(
        (ground) =>
          !this.myGrounds.some((myGround) => myGround.id === ground.id)
      );
      this.allGrounds = remainingGrounds;
      this.allGroundsBackup = remainingGrounds;
    }
  }

  filterGrounds(value: string) {
    if (value) {
      this.allGrounds = (
        value ? this.allGrounds : this.allGroundsBackup
      ).filter((ground: Ground) => {
        return (
          ground.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          ground.city.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          ground.state.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        );
      });
    } else {
      if (value) {
        this.selectState(value);
      } else {
        this.allGrounds = this.allGroundsBackup;
      }
    }
  }

  selectState(state: string) {
    this.allGrounds = this.allGroundsBackup.filter(
      (ground: Ground) => ground.state === state
    );
  }

  getGrounds() {
    this.groundService
      .getGrounds()
      .then((response: ApiResponse<Ground[]> | unknown) => {
        const apiResponse = response as ApiResponse<Ground[]>;
        if (apiResponse.data?.length) {
          this.allGrounds = apiResponse.data;
          this.allGroundsBackup = this.allGrounds;
        }
      });
  }

  onSelectGround(ground: Ground) {
    this.setGroundModalValue.emit(ground);
    this.handleClose();
  }

  resetStateSelection() {
    this.allGrounds = this.allGroundsBackup;
  }

  toggleAddDropdown() {
    this.isAddDropdownOpen = !this.isAddDropdownOpen;
  }

  handleClose() {
    this.onGroundClose.emit(true);
  }
}
