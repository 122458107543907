import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { RestResponse } from '../models/rest-response';

@Injectable({
    providedIn: 'root'
})
export class LandingService {

    private apiUrl = '/landing/submit-contact-form';

    constructor(private restService: RestService) { }

    public async submitContactForm(formData: any): Promise<RestResponse> {

        try {
            const restInstance = this.restService.getRestInstance();
            const response = await restInstance.post<RestResponse>(this.apiUrl, formData);
            return response.data;
        } catch (error) {
            console.error('Error submitting contact form:', error);
            throw error;
        }
    }
}