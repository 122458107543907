<footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">    
    <div class="py-[30px] px-0 border-t border-slate-800">
        <div class="container relative grid grid-cols-2 gap-2 text-center logo-text-container">
            <div class="col-span-1 flex justify-end items-right">
                <a class="logo" routerLink="/">
                    <img src="assets/images/logo-dark.png" class="inline-block" alt="">
                </a>
            </div>
            <div class="col-span-1 flex flex-col justify-left items-start ml-0">
                <p>© {{date}} CrickTeams. Design & Develop by <a href="https://nextgnsolutions.com" class="text-reset" target="_blank" style="text-decoration: underline;">NextGen Solutions & Services</a>.</p>
                <p>
                    <a href="/terms" class="text-reset" style="text-decoration: underline; margin-left: 10px;">CrickTeams Terms</a>
                    <a href="/privacy-policy" class="text-reset" style="text-decoration: underline; margin-left: 10px;">Privacy Policy</a>
                    <a href="/cookie-policy" class="text-reset" style="text-decoration: underline; margin-left: 10px;">Cookie Policy</a>
                </p>
            </div>            
        </div><!--end container-->
    </div>
</footer>
