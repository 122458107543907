import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  eValidators,
  FieldConfig,
  Validator,
} from '../interfaces/dynamic.interface';

@Injectable({
  providedIn: 'root',
})
export class DynamicService {
  constructor(private formBuilder: FormBuilder) {}

  createFormControl(fields: FieldConfig[]): FormGroup {
    const group: FormGroup = this.formBuilder.group({});
    fields.forEach((field) => {
      field = this.bindPropertyAttributes(field);
      group.addControl(
        field.name,
        this.formBuilder.control(
          field.value,
          this.bindValidations(field.validations || [])
        )
      );
    });
    return group;
  }

  bindValidations(validations: Validator[]) {
    if (validations && validations.length > 0) {
      const validList: any[] = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return [];
  }

  bindPropertyAttributes(fieldConfig: FieldConfig): FieldConfig {
    if (fieldConfig.required) {
      fieldConfig.validations = fieldConfig.validations
        ? fieldConfig.validations
        : [];
      if (
        !fieldConfig.validations.find(
          (d) => d.validationType == eValidators.Required
        )
      ) {
        fieldConfig.validations?.push({ validationType: eValidators.Required });
      }
    }
    if (fieldConfig.validations && fieldConfig.validations.length > 0) {
      fieldConfig.validations.forEach((valid) => {
        const value = valid.value ? valid.value : '';
        switch (valid.validationType) {
          case eValidators.Required: {
            fieldConfig.required = true;
            valid.validator = Validators.required;
            valid.message = valid.message
              ? valid.message
              : `${fieldConfig.label} is required`;
            break;
          }
          case eValidators.Pattern: {
            valid.validator = Validators.pattern(valid.validationPattern as RegExp);
            valid.message = valid.message ? valid.message : '';
            break;
          }
          case eValidators.MinLength: {
            fieldConfig.minLength = +value;
            valid.validator = Validators.minLength(+value);
            valid.message = valid.message
              ? valid.message
              : `Enter minimum ${value} characters`;
            break;
          }
          case eValidators.MaxLength: {
            fieldConfig.maxLength = +value;
            valid.validator = Validators.maxLength(+value);
            valid.message = valid.message
              ? valid.message
              : `Enter maximum ${value} characters`;
            break;
          }
          case eValidators.Min: {
            fieldConfig.min = +value;
            valid.validator = Validators.min(+value);
            valid.message = valid.message
              ? valid.message
              : `Value must be grater than ${value}`;
            break;
          }
          case eValidators.Max: {
            fieldConfig.max = +value;
            valid.validator = Validators.max(+value);
            valid.message = valid.message
              ? valid.message
              : `Value must be less than ${value}`;
            break;
          }
        }
      });
    }
    return fieldConfig;
  }
}
