export class FieldConfig {
  id?: string;
  name!: string;
  label!: string;
  componentType!: string;
  validations?: Validator[];
  options?: KeyValuePair[];
  value?: string | number | boolean | unknown;
  required?: boolean = false;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
  placeholder?: string;
  checked?: boolean;
}

export class Validator {
  validationType!: string;
  validationPattern?: eValidatorRegEx;
  validator?: any;
  message?: string;
  value?: string | number = '';
}

export class KeyValuePair {
  key!: string | number;
  value!: string;
  isActive?: boolean
}

export enum ComponentTypes {
  Text = 'text',
  Select = 'select',
  Number = 'number',
  Email = 'email',
  Url = 'url',
  Phone = 'phone',
  Checkbox = 'checkbox',
  Time = 'time',
  Textarea = 'textarea'
}

export enum eValidators {
  Required = 'required',
  Email = 'email',
  Phone = 'phone',
  Text = 'text',
  Pattern = 'pattern',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Min = 'min',
  Max = 'max',
}

export enum eValidatorRegEx{
  Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
  Phone = '^(\\+?[0-9]{1,3})?[0-9]{10}$',
  Url = '^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(/S*)?$',
  Number = '^[0-9]*$',
  Text = '^[a-zA-Z ]*$',
  ZipCode = '^[0-9]{5}(?:-[0-9]{4})?$',
  AllowRegex = '^(?=.*[a-zA-Z])[a-zA-Z0-9 @#$%^&*()-_+=!]*$',
  AlphaNumericWithSpaces = '^(?=.*[a-zA-Z])[a-zA-Z0-9 ]*$'
}
