import { CommonModule,  } from '@angular/common';
import { Component } from '@angular/core';
import { DarkSidebarComponent } from '../../components/sidebar/dark-sidebar/dark-sidebar.component';
import { TopHeaderComponent } from '../../components/top-header/top-header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cricteams-settings-index',
  standalone: true,
  imports: [CommonModule, DarkSidebarComponent, TopHeaderComponent,FooterComponent, RouterLink],
  templateUrl: './settings-index.component.html',
  styleUrls: ['./settings-index.component.scss']
})
export class SettingsIndexComponent  {
  
  activeSidebar = true
  

  toggleClass() {
    this.activeSidebar = !this.activeSidebar;
  }

}


