@if (isModal) {
<div
  class="relative z-[9999] font-montserrat"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500/75 transition-opacity"
    aria-hidden="true"
  ></div>

  <div
    class="p-4 fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center"
  >
    <ng-container [ngTemplateOutlet]="matchForm"></ng-container>
  </div>
</div>
} @else {
<ng-container [ngTemplateOutlet]="matchForm"></ng-container>
}

<ng-template #matchForm>
  <div
    class="bg-white h-auto p-4 xl:p-5 rounded w-full font-montserrat"
    [ngClass]="{ 'w-full max-w-[800px] h-fit': isModal }">
    <h4 class="font-semibold text-black text-sm md:text-base">
      {{ isEdit ? 'Edit' : 'Add' }} Match
    </h4>
    <div class="mt-4 xl:mt-6">
      <form (ngSubmit)="onSubmit()" [formGroup]="dyForm" #form="ngForm">
        <div class="grid lg:grid-cols-2 lg:gap-5">
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-input
              (click)="openGroundSelectionModal(groundSelectionModal)"
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="groundId"
              [group]="dyForm"
              [isReadOnly]="true"
            ></cricteams-dy-input>
          </div>
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-input
            (click)="openTournamentSelectionModal(tournamentSelectionModal)"
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="tournamentId"
              [group]="dyForm"
              [isReadOnly]="true"
            ></cricteams-dy-input>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 lg:gap-5 items-center date-picker">
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-date-picker
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="startDate"
              [group]="dyForm"
              [minDate]="minDate"
            >
            </cricteams-dy-date-picker>
          </div>
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-time-picker
             [labelClass]="labelClass"
             [form]="form"
             [field]="startTime"
             [group]="dyForm"
             [isReadOnly]="false"
             [selectedDate]="this.dyForm.get('startDate')?.value"
             ></cricteams-dy-time-picker>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 lg:gap-5 items-end">
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="scorecardUrl"
              [group]="dyForm"
            ></cricteams-dy-input>
          </div>
          <div class="md:col-span-1 mb-5 lg:mb-6 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="opponent"
              [group]="dyForm"
            ></cricteams-dy-input>
          </div>
        </div>

        <div class="text-right flex justify-end gap-2">
          <cricteams-dy-button
            text="Cancel"
            [class]="cancelBtnClass"
            (click)="handleCancel()"
          ></cricteams-dy-button>
          <cricteams-dy-button
            type="submit"
            text="{{ isEdit ? 'Update' : 'Save' }}"
            [disabled]="dyForm.invalid"
            [class]="saveBtnClass"
            [conditionalClass]="{
              'bg-light-blue': dyForm.invalid,
              'bg-dark-blue cursor-pointer': dyForm.valid
            }"
          ></cricteams-dy-button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #groundSelectionModal>
  <cricteams-ground-modal
    (onGroundClose)="handleClose($event)" (setGroundModalValue)="setGroundValue($event)"
  ></cricteams-ground-modal>
</ng-template>

<ng-template #tournamentSelectionModal>
  <cricteams-tournament-modal
    (onTournamentClose)="handleTournamentClose($event)" (setTournamentValue)="setTournamentValue($event)"
  ></cricteams-tournament-modal>
</ng-template>
