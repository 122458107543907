import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { FooterComponent } from "../components/footer/footer-dashboard/footer.component";
import { HeaderComponent } from "../components/header/header.component";
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { LoadingService } from '../services/loading.service';
import { SectionLoadingComponent } from '../components/section-loading/section-loading.component';
import { routeUrls } from '../constants/constants';

@Component({
  selector: 'cricteams-layout',
  standalone: true,
  imports: [CommonModule,RouterModule, SidebarComponent, FooterComponent, HeaderComponent, SectionLoadingComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit {
  public activeSidebar = true;
  public isSectionLoading = false;
  currentUrl = false;

  constructor(private loadingService: LoadingService, private router: Router){
    this.loadingService.sectionLoading$.subscribe((sectionLoading) => {
      this.isSectionLoading = sectionLoading;
    });
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.checkRoute(event.url);
      }
    });
  }
  toggleClass() {
    this.activeSidebar = !this.activeSidebar;
  }

  checkRoute(url:string) {
    switch (url) {
      case `/${routeUrls.ROOT}`:
      case `/${routeUrls.DASHBOARD}`:
      case `/${routeUrls.TOURNAMENTS}`:
      case `/${routeUrls.PLAYERS}`:
      case `/${routeUrls.PRACTICES}`:
      case `/${routeUrls.MATCHES}`:
      case `/${routeUrls.SETTINGS}`:
        return true;
      default:
        return false;
    }
  }
}
