import { Route } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AuthGuard } from './utilities/auth.gaurd';
import { routeUrls } from './constants/constants';

const constants = routeUrls;

export const appRoutes: Route[] = [
  { path: constants.HOME, component: LandingComponent },
  { path: constants.HANDLE_INVITE, loadComponent: () => import('./pages/onboarding/handle-invite/handle-invite.component').then(m => m.HandleInviteComponent) },
  { path: constants.AUTH_CALLBACK, loadComponent: () => import('./auth/auth-callback/auth-callback.component').then(m => m.AuthCallbackComponent) },
  { path: constants.TERMS, loadComponent: () => import('./pages/miscellaneous/terms-of-use/terms-of-use.component').then(m => m.TermsOfUseComponent) },
  { path: constants.PRIVACY_POLICY, loadComponent: () => import('./pages/miscellaneous/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent) },
  { path: constants.COOKIE_POLICY, loadComponent: () => import('./pages/miscellaneous/cookie-policy/cookie-policy.component').then(m => m.CookiePolicyComponent) },
  { path: constants.CONFIRMATION, loadComponent: () => import('./pages/onboarding/mail-confirmation/mail-confirmation.component').then(m => m.MailConfirmationComponent) },
  { path: constants.INVALID_LINK, loadComponent: () => import('./pages/onboarding/invalid-llink/invalid-link.component').then(m => m.InvalidLinkComponent) },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: constants.DASHBOARD,
        pathMatch: 'full'
      },
      {
        path: constants.DASHBOARD,
        loadComponent: () => import('./pages/dashboard/index/index.component').then(m => m.IndexComponent)
      },
      {
        path: constants.TOURNAMENTS,
        loadComponent: () => import('./pages/tournaments/tournaments.component').then(m => m.TournamentsComponent),
      },
      {
        path: constants.ADD_TOURNAMENTS,
        loadComponent: ()=> import('./pages/tournaments/add-tournament/add-tournament.component').then(m => m.AddTournamentComponent)
      },
      {
        path: constants.LIST_TOURNAMENTS,
        loadComponent: ()=> import('./pages/tournaments/tournaments-list/tournaments-list.component').then(m => m.TournamentsListComponent)
      },
      {
        path: constants.LEAGUES,
        loadComponent: () => import('./pages/leagues/league-index.component').then(m => m.LeagueIndexComponent)
      },
      {
        path: constants.GROUNDS,
        loadComponent: () => import('./pages/grounds/ground-index.component').then(m => m.GroundIndexComponent)
      },
      {
        path: constants.PRACTICES,
        loadComponent: () => import('./pages/practices/practices-index.component').then(m => m.PracticesIndexComponent)
      },
      {
        path: constants.ADD_PRACTICES,
        loadComponent: ()=> import('./pages/practices/add-practice/add-practice.component').then(m => m.AddPracticeComponent)
      },
      {
        path: constants.LIST_PRACTICES,
        loadComponent: () => import('./pages/practices/practice-list/practice-list.component').then(m => m.PracticeListComponent)
      },
      {
        path: constants.MATCHES,
        loadComponent: () => import('./pages/matches/match-index.component').then(m => m.MatchIndexComponent)
      },
      {
        path: constants.ADD_MATCHES,
        loadComponent: () => import('./pages/matches/add-match/add-match.component').then(m => m.AddMatchComponent)
      },
      {
        path: constants.LIST_MATCHES,
        loadComponent: () => import('./pages/matches/match-list/match-list.component').then(m => m.MatchListComponent)
      },
      {
        path: constants.PLAYERS,
        loadComponent: () => import('./pages/players/player-index.component').then(m => m.PlayerIndexComponent)
      },
      {
        path: constants.ADD_PLAYERS,
        loadComponent: ()=> import('./pages/players/add-player/add-player.component').then(m => m.AddPLayerComponent)
      },
      {
        path: constants.LIST_PlAYERS,
        loadComponent: ()=> import('./pages/players/player-list/player-list.component').then(m => m.PlayerListComponent)
      },
      {
        path: `${constants.NOTICEBOARD}/${constants.NB_INDEX}`,
        loadComponent: () => import('./pages/noticeboard/nb-index/nb-index.component').then(m => m.NbIndexComponent)
      },
      {
        path: `${constants.NOTICEBOARD}/${constants.GALLERY}`,
        loadComponent: () => import('./pages/noticeboard/gallery/gallery.component').then(m => m.GalleryComponent)
      },
      {
        path: constants.SETTINGS,
        loadComponent: () => import('./pages/settings/settings-index.component').then(m => m.SettingsIndexComponent)
      },
      {
        path: `${constants.USER}/${constants.PROFILE}`,
        loadComponent: () => import('./pages/user/user-profile.component').then(m => m.UserProfileComponent)
      },
      {
        path: '**',
        redirectTo: constants.DASHBOARD,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: constants.HOME,
    pathMatch: 'full'
  }
];
