<label [class]="labelClass" class="font-montserrat">{{ field.label }} <span *ngIf="field.required" class="text-red-500">*</span></label>
<div [formGroup]="group" class="font-montserrat" [ngClass]="{ 'i-invalid' : group.get(field.name)?.touched && group.get(field.name)?.invalid }">
  <select [class]="selectClass" [formControlName]="field.name">
    <option *ngFor="let item of field.options" value="{{ item.key }}">{{ item.value }}</option>
  </select>
</div>
<ng-container *ngFor="let validation of field.validations">
    <p *ngIf="(group.get(field.name)?.hasError(validation.validationType) && group.get(field.name)?.touched) || (form.submitted && form.touched)"
    class="text-red-500 text-xs font-montserrat">
        {{ validation.message }}
    </p>
</ng-container>
