import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { registerLicense } from '@syncfusion/ej2-base';
import { environment } from './environments/environment';

// ✅ Import Firebase modules
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';

// Register Syncfusion License
registerLicense(environment.syncFusionKey);

// ✅ Extend appConfig to include Firebase providers
bootstrapApplication(AppComponent, {
  ...appConfig,
  providers: [
    ...appConfig.providers!,
    provideFirebaseApp(() => initializeApp(environment.firebase)), // Initialize Firebase
    provideAnalytics(() => getAnalytics()), // Enable Analytics
  ],
}).catch((err) => console.error(err));
