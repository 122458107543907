import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { UserService } from './user.service';
import { Ground } from '../models/ground/ground.model';
import { PracticeService } from './practice.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  selectedMenuValue: BehaviorSubject<string> = new BehaviorSubject('general');

  constructor( private userService: UserService, private practiceService: PracticeService
  ) {}

  generateBadge(name: string): string {
    const words = name.split(' ');
    let badge = '';
    if (words.length === 1) {
      const word = words[0];
      badge += word.substring(0, 2).toUpperCase();
    } else {
      for (let i = 0; i < 2; i++) {
        badge += words[i].charAt(0).toUpperCase();
      }
    }
    return badge;
  }

  getFormattedDate(date: Date): string {
    const padZero = (num: number) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  calculateDuration(startDateTime: Date, endDateTime: Date){
    const startHour = startDateTime.getHours();
    const endHour = endDateTime.getHours();
    const duration = endHour - startHour;
    return duration > 0 ? duration : 0;
  }

  calculateDeuAmount(amountPerMember: number, amountPaid: number): number {
    return Math.round((amountPerMember - amountPaid) * 100) / 100;
  }

  public getPlayerStyle(
    battingStyle: number | undefined,
    bowlingStyle: number | undefined,
    isWicketKeeper: boolean | undefined
  ) {
    let playerStyle = '';

    if (battingStyle && this.getPlayerBattingStyle(battingStyle)) {
      playerStyle += `${this.getPlayerBattingStyle(battingStyle)}, `;
    }

    if (bowlingStyle && this.getPlayerBowlingStyle(bowlingStyle)) {
      playerStyle += `${this.getPlayerBowlingStyle(bowlingStyle)}, `;
    }

    if (isWicketKeeper) {
      playerStyle += 'wicket keeper';
    }

    return playerStyle.trim();
  }
  getPlayerBattingStyle(battingStyle: number) {
    switch (battingStyle) {
      case 1:
        return 'Right Handed Batsman';
      case 2:
        return 'Left Handed Batsman';
      default:
        return '';
    }
  }
  getPlayerBowlingStyle(bowlingStyle: number) {
    switch (bowlingStyle) {
      case 1:
        return 'Right Arm Fast';
      case 2:
        return 'Right Arm Medium';
      case 3:
        return 'Right Arm Off Spin';
      case 4:
        return 'Right Arm Leg Spin';
      case 5:
        return 'Left Arm Fast';
      case 6:
        return 'Left Arm Medium';
      case 7:
        return 'Left Arm Off Spin';
      case 8:
        return 'Left Arm Leg Spin';
      default:
        return '';
    }
  }

  async isManager(){
    const user = await this.userService.getUserInfoFromLocalStorage();
    if(user){
      const currentClubId = localStorage.getItem('currentClubId');
      const club = user.clubs.find((c) => c.id === currentClubId);
      const isManager = club?.currentUserRole === 0 ;
      return isManager;
    }
    return false;
  }

  public getAge(dob: string): number | string {
    if (!dob) {
        return "";
    }

    const birthDate = new Date(dob);
    if (isNaN(birthDate.getTime())) {
        return "";
    }

    const today = new Date();
    if (birthDate > today) {
        return "";
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }
    if(age === 0) {
        return "";
    }

    return age;
  }

  getAddress(groundData: Ground | null) {
    if (!groundData) return '';

    return [
      groundData.addressLine1 ?? '',
      groundData.addressLine2 ?? '',
      groundData.city ?? '',
      groundData.state,
    ]
      .filter(Boolean)
      .join(', ');
  }

  async updatePracticeList(clubId: string) {
    const practices = await this.practiceService.getPractices(clubId ?? '');
    this.practiceService.practiceList.next(practices || []);
  }
}
