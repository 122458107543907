import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { routeUrls } from '../../constants/constants';
import { UserService } from '../../services/user.service';
import { SearchPipe } from '../../pipes/search.pipe';
import { FormsModule } from '@angular/forms';
import { Club } from '../../models/club/club-info.model';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AccountComponent } from '../account/account.component';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { AddMatchComponent } from '../../pages/matches/add-match/add-match.component';
import { AddPracticeComponent } from '../../pages/practices/add-practice/add-practice.component';
import { AddPLayerComponent } from "../../pages/players/add-player/add-player.component";
import { AddTournamentComponent } from "../../pages/tournaments/add-tournament/add-tournament.component";
import { PlayerService } from '../../services/player.service';
import { PlayerInfo } from '../../models/player/player-request.model';
import { CommonService } from '../../services/common.service';
import { ClubService } from '../../services/club.service';
import { UserBaseInfo } from '../../models/user/user-base-info.model';
import { Tournament } from '../../models/club/club-tournament.model';
import { TournamentService } from '../../services/tournament.service';

@Component({
  selector: 'cricteams-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SearchPipe,
    FormsModule,
    TabsModule,
    AccountComponent, OutsideClickDirective,
    AddMatchComponent,
    AddPracticeComponent,
    AddPLayerComponent,
    AddTournamentComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [BsModalService],
})
export class HeaderComponent implements OnInit {
  modalRef!: BsModalRef;
  protected constants = routeUrls;
  isClubDropdownOpen = false;
  isAddDropdownOpen = false;
  clubName = '';
  fullName = '';
  displayClubDropdown = false;
  isManagerOrCaptain!: boolean;
  playerDetails!: PlayerInfo | null;
  public clubsList: Club[] = [];
  public activeClub: Club | null = null;
  public clubSearchKeyword = '';
  @Output() toggleClass = new EventEmitter<void>();
  public clubImgUri = '';
  public userBaseInfo!: UserBaseInfo;
  public tournament: Tournament[] = [];
  public clubId!: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private modalService: BsModalService,
    private playerService: PlayerService,
    protected commonService: CommonService,
    private clubService: ClubService,
    private tournamentService: TournamentService
  ) {
    this.setUserInfo();
    this.getProfileURI();
  }

  ngOnInit(): void {
    this.userService.isUserManagerOrCaptain().then((isManagerOrCaptain) => {
      this.isManagerOrCaptain = isManagerOrCaptain;
    });
    this.getClubs();
    if(!this.playerDetails){
      this.playerService.getPlayer().then((res: PlayerInfo | null) => {
        this.playerDetails = res;
        this.playerService.loggedinPlayer.next(this.playerDetails);
      });
    }
    this.getTournamentPlayer();
  }

  private async setUserInfo() {
    const user = await this.userService.getUserInfoFromLocalStorage();
    if (user) {
      let storedClubId = localStorage.getItem('currentClubId');
      const clubId = storedClubId ?? user.clubs.find((club:Club) => club.currentUserRole === 0)?.id
                     ?? user.clubs[0].id;
      localStorage.setItem('currentClubId', clubId);
      this.displayClubDropdown = user.clubs.length > 1;
      this.fullName = user.user.fullName;
    }
  }

  emitToggleClassEvent() {
    this.toggleClass.emit();
  }

  isNotification = false;

  notificationManu() {
    this.isNotification = !this.isNotification;
  }

  isProfileDropDownOpen = false;
  userDropdown() {
    this.isProfileDropDownOpen = !this.isProfileDropDownOpen;
  }

  isMyAccount = false;
  myAccountDropdown(template: TemplateRef<HTMLElement>) {
    this.modalRef = this.modalService.show(template);
  }

  isNotificationDropdown = false;
  NotificationDropdown() {
    this.isNotificationDropdown = !this.isNotificationDropdown;
  }

  logout() {
    this.authService.logout();
    localStorage.removeItem('access_token');
    localStorage.clear();
    this.router.navigateByUrl('home');
  }

  toggleClubDropdown() {
    this.isClubDropdownOpen = !this.isClubDropdownOpen;
  }

  toggleAddDropdown() {
    this.isAddDropdownOpen = !this.isAddDropdownOpen;
  }

  private async getClubs() {
    const clubId = localStorage.getItem('currentClubId');
    const userInfo = localStorage.getItem('userBaseInfo');
    if (userInfo) {
      const clubData = JSON.parse(userInfo);
      this.clubsList = clubData.clubs.filter((club: Club) => club.id !== clubId);
      const selectedClub = clubData.clubs.find((club: Club) => club.id === clubId);
      this.clubName = selectedClub?.name ?? '';
      this.clubImgUri = selectedClub?.sasUri ?? '';
    }
  }

  onClubSelect(selectedClub: Club) {
    this.activeClub = selectedClub;
    localStorage.setItem('currentClubId', this.activeClub.id);
    this.clubName = this.activeClub.name;
    this.clubImgUri = this.activeClub.sasUri ?? '';
    this.clubService.isClubSwitch.next(true);
    this.getClubs();
    this.toggleClubDropdown();
    this.userService.isUserManagerOrCaptain().then((isManagerOrCaptain: boolean) => {
      this.isManagerOrCaptain = isManagerOrCaptain;
    });
    this.getTournamentPlayer();
    window.location.reload();
  }


  handleClose(event: boolean) {
    if (event) {
      this.modalRef.hide();
    }
  }

  public openModal(template: TemplateRef<HTMLElement>){
    this.modalRef = this.modalService.show(template);
  }

  async getProfileURI() {
    this.userService.profileURIChanged.subscribe(async (isChanged) => {
      if (isChanged) {
        this.playerDetails = await this.playerService.getPlayer();
        this.fullName = `${this.playerDetails?.user.firstName ?? ''} ${this.playerDetails?.user.lastName ?? ''}`.trim();
      }
    });
    this.clubService.isClubUpdated.subscribe((isUpdated)=>{
      if(isUpdated){
        this.getClubs();
      }
    });
  }

  getTournamentPlayer(){
    this.userBaseInfo = JSON.parse(localStorage.getItem('userBaseInfo') || '{}');
    this.clubId = localStorage.getItem('currentClubId') ?? '';
    this.tournamentService.getTournamentsByUserId(this.clubId, this.userBaseInfo.user.id).then((res) => {
      if(res){
        this.tournament = res;
        this.tournamentService.isShowTournamentButton.next(this.tournament.length > 0);
      }
    });
  }
}
