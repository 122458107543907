@if (isModal) {
<div
  class="relative z-[9999] font-montserrat"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500/75 transition-opacity"
    aria-hidden="true"
  ></div>

  <div class="p-4 fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center">
    <ng-container [ngTemplateOutlet]="tournamentForm"></ng-container>
  </div>
</div>
} @else {
    <ng-container [ngTemplateOutlet]="tournamentForm"></ng-container>
}

<ng-template #tournamentForm>
  <div
    class="bg-white rounded-md border-[1px] border-light-grey p-4 md:p-5 font-montserrat"
    [ngClass]="{ 'w-full h-fit max-w-[400px] sm:max-w-[800px]': isModal }"
  >
    <h4 class="font-semibold text-black text-sm md:text-base">
      Add Tournament
    </h4>
    <div class="mt-4 xl:mt-6">
      <form
        [formGroup]="dyForm"
        #form="ngForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="grid lg:grid-cols-2 gap-0 lg:gap-5 items-center">
          <div class="lg:col-span-1 mb-5 sm:mb-7 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="tournamentName"
              [group]="dyForm"
            ></cricteams-dy-input>
          </div>
          <div class="lg:col-span-1 mb-5 sm:mb-7 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="leagueName"
              [group]="dyForm"
              (click)="openLeagueSelectionModal(leagueSelectionModal)"
              [isReadOnly]="true"
            ></cricteams-dy-input>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 gap-0 lg:gap-5 items-center">
          <div class="lg:col-span-1 mb-5 sm:mb-7 relative">
            <cricteams-dy-date-picker
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="startDate"
              [group]="dyForm"
              [isToolTip]="false"
              [minDate]="currentDate"
            >
            </cricteams-dy-date-picker>
          </div>
          <div class="lg:col-span-1 mb-5 sm:mb-7 relative">
            <cricteams-dy-date-picker
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="endDate"
              [group]="dyForm"
              [isToolTip]="true"
              [minDate]="currentDate"
            >
            </cricteams-dy-date-picker>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 gap-0 lg:gap-5 items-center">
          <div class="lg:col-span-1 relative">
            <cricteams-dy-select [isDisabled]="playerOptions.length < 2" (onSelectChange)="handleCaptainChange($event)" [labelClass]="labelClass" [selectClass]="inputClass" [field]="captainSelection" [form]="form"
              [group]="dyForm">
            </cricteams-dy-select>
          </div>
          <div class="lg:col-span-1 relative">
            <cricteams-dy-select [isDisabled]="playerOptions.length < 2" (onSelectChange)="handleVcChange($event)" [labelClass]="labelClass" [selectClass]="inputClass" [field]="viceCaptainSelection"
              [form]="form" [group]="dyForm" >
            </cricteams-dy-select>
          </div>
          @if (playerOptions.length < 2) {
            <p class="text-red-500 text-xs font-montserrat">
              No players available. Please add players to the team.
            </p>
          }
        </div>

        <div class="text-right flex mt-5 sm:mt-7 justify-end gap-2">
          <cricteams-dy-button
            (click)="handleCancel()"
            text="Cancel"
            [class]="cancelBtnClass"
          ></cricteams-dy-button>
          <cricteams-dy-button
            type="submit"
            text="Save"
            [disabled]="dyForm.invalid"
            [class]="saveBtnClass"
            [conditionalClass]="{
              'bg-light-blue': dyForm.invalid,
              'bg-dark-blue cursor-pointer': dyForm.valid
            }"
          ></cricteams-dy-button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #leagueSelectionModal>
  <cricteams-league-modal (onLeagueModalClose)="handleClose($event)" (getLeagueValue)="setLeagueValue($event)"></cricteams-league-modal>
</ng-template>
