import { Component, Input, OnInit, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DySelectComponent } from '../dy-select/dy-select.component';
import { DynamicService } from '../../../services/dynamic.service';
import { ComponentTypes, FieldConfig } from '../../../interfaces/dynamic.interface';

@Component({
  selector: 'cricteams-dy-availability',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DySelectComponent],
  templateUrl: './dy-availability.component.html',
  styleUrl: './dy-availability.component.scss',
})
export class DyAvailabilityComponent implements OnInit {
  public selectlLabelClass = 'hidden mb-2.5 text-sm text-light-grey font-medium';
  public labelClass = 'block text-xs text-light-grey mb-2 whitespace-nowrap text-ellipsis overflow-hidden';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full px-3 h-[34px] sm:h-[40px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public availableForm!: FormGroup;

  @Input() selectedValue!: number;
  @Input() isDisabled = false;

  onSelect = output<string>();

  public available: FieldConfig = {
    label: 'Select Availability',
    name: 'available',
    componentType: ComponentTypes.Select,
    value: '',
    placeholder: 'Select Availability',
    options: [
      { key: 'yes', value: 'Yes' },
      { key: 'no', value: 'No' },
      { key: 'mayBe', value: 'May Be' },
    ],
  };

  constructor(private dynamicService: DynamicService){}

  ngOnInit(): void {
    if(this.selectedValue){
      const value = this.selectedValue === 1 ? 'yes' : this.selectedValue === 2 ? 'no' : this.selectedValue === 3 ? 'mayBe' : '';
      this.available.value = value;
    }

    this.availableForm = this.dynamicService.createFormControl([
      this.available,
    ]);
  }

  handleSelect(event: Event){
    const target = event.target as HTMLInputElement;
    this.onSelect.emit(target.value);
  }
}
