<label [class]="labelClass" class="font-montserrat">{{ field.label }} <span *ngIf="field.required"
    class="text-red-500">*</span></label>
<div [formGroup]="group">
  <ejs-timepicker  [openOnFocus]="true" [showClearButton]="true" (keydown)="preventTyping($event)"
    [formControlName]="field.name" [placeholder]="field.placeholder" [min]="minTime"
    [readonly]="isReadOnly" [ngClass]="{ 'readonly-input': isReadOnly }"></ejs-timepicker>
</div>
<ng-container *ngFor="let validation of field.validations">
  <p *ngIf="
    (group.get(field.name)?.hasError(validation.validationType) && group.get(field.name)?.touched)
    " class="text-red-500 text-xs font-montserrat absolute">
    {{ validation.message }}
  </p>
</ng-container>
